import { Spinner } from '@chakra-ui/react'
import { StyledButtonContainer } from 'common/Buttons/SingleButton/styled'

export const SingleButton = ({
  text, isLoading, click, customStyled, dataLink, name, className
}) => (
  <StyledButtonContainer data-link={dataLink} name={name} style={{ ...customStyled }} className={className} onClick={click} type="submit">
    {isLoading ? <Spinner size="md" /> : text}
  </StyledButtonContainer>
)
