/* eslint-disable */

import { configureStore } from '@reduxjs/toolkit'
import auth from 'store/auth/slice'
import { chatReducer } from 'store/chat/slice'
import markers from 'store/markers/slice'
import notificationMessage from 'store/notificationMessage/slice'
import users from 'store/users/slice'

export const store = configureStore({
  reducer: {
    markers,
    auth,
    users,
    notificationMessage,
    chat: chatReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})
