import { __ } from 'i18n';
import { _converse } from '@converse/headless/core';
import { html } from "lit";

export default (o) => {
    if (o.model.attributes.image_bak && o.model.attributes.image_type === _converse.DEFAULT_IMAGE_TYPE) {
        o.model.attributes.image = o.model.attributes.image_bak;
        o.model.attributes.image_type= o.model.attributes.image_type_bak;
    } else if (o.model.attributes.image === "") {
        o.model.attributes.image = _converse.DEFAULT_IMAGE;
        o.model.attributes.image_type= _converse.DEFAULT_IMAGE_TYPE;
    }
    const avatar = html`<converse-avatar data-nickname="${o.model.attributes.nickname}" .data="${o.model.attributes}" nonce="${o.model.vcard?.get('vcard_updated')}" height="60" width="60"></converse-avatar>`;
    const display_name = o.model.getDisplayName();
    const showFriendMarker = (jid) => {
        const event = new CustomEvent('custom-chat-show_on_map', {
           detail: {
              jid: jid,
           }});
        window.dispatchEvent(event);
    }
    const onClick = (ev) => {
        ev.stopPropagation();
        showFriendMarker(o.model.attributes.jid);
    }

    return html`<div class="chatbox-title ${ o.status ? '' :  "chatbox-title--no-desc"}"><div class="chatbox-title--row"><a class="show-msg-author-modal" @click="${onClick}">${ avatar }</a><p class="user show-msg-author-modal show-msg-display-name" @click="${onClick}">${ display_name }</p></div></div>`;
}
