import { Input, Stack, Text } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'

export const SingleInput = ({
  placeholder, register, error, value, fixValue, name, _placeholder, onChange, maxlength, display
}) => {
  const hasOnChange = () => {
    if (onChange === undefined) {
      return (
        <Input
          autoComplete="off"
          _focus={{
            boxShadow: error ? `0 0 0 1px ${COLORS.red}` : 'unset',
            border: `1px solid ${error ? COLORS.red : COLORS.gray}`
          }}
          color={error && COLORS.red}
          errorBorderColor={COLORS.red}
          defaultValue={value}
          bgColor="white"
          value={fixValue}
          isInvalid={error}
          maxLength={maxlength}
          {...register}
          placeholder={placeholder}
          _placeholder={_placeholder}
          display={display}
        />
      )
    }

    return (
      <Input
        autoComplete="off"
        _focus={{
          boxShadow: error ? `0 0 0 1px ${COLORS.red}` : 'unset',
          border: `1px solid ${error ? COLORS.red : COLORS.gray}`
        }}
        color={error && COLORS.red}
        errorBorderColor={COLORS.red}
        defaultValue={value}
        bgColor="white"
        value={fixValue}
        isInvalid={error}
        maxLength={maxlength}
        {...register}
        placeholder={placeholder}
        _placeholder={_placeholder}
        onChange={onChange}
        display={display}
      />
    )
  }
  return (
    <Stack spacing="10px" name={name}>
      {hasOnChange()}
      <Text fontSize="14px" color={COLORS.red}>
        {error && error.message}
      </Text>
    </Stack>
  )
}
