import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Image, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { SingleInput } from 'common/Inputs'
import { forgotPassword, handleModalTitle } from 'store'
import { EMAIL, VERIFY_PAGE } from 'utils/constants'
import { ForgotPasswordValidationScheme } from 'validation'

export const ForgotPassword = ({ isLoading }) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(ForgotPasswordValidationScheme)
  })

  const onSubmit = (data) => {
    const body = {
      email: data.email
    }
    dispatch(forgotPassword(body))
  }

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.ForgotPassword))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing="32px">
        <Image
          boxSize="72px"
          objectFit="cover"
          src={ImagesPng.Logo}
          alt="Logo"
          borderRadius="full"
          margin="auto"
        />

        <Text textAlign="center" fontSize="16px">
          Enter your email address, and we’ll send you a verification code to
          reset your password.
        </Text>

        <SingleInput
          placeholder="Email Address"
          error={errors.email}
          register={register(EMAIL)}
          name="email"
        />

        <SingleButton isLoading={isLoading} name="send-email" text="Send Email" />
      </Stack>
    </form>
  )
}
