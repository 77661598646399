/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box, Stack, Text, Textarea
} from '@chakra-ui/react'
import { SingleInput } from 'common/Inputs'
import {
  getNotificationMessage,
  setIsPictureUploaded,
  setCropFor,
  setCroppedPicture
} from 'store'
import { REJECTED } from 'utils/constants'
import { resizeImage } from 'utils/helpers'
import { ProfileAvatar } from './ProfileAvatar'
import { ProfileCoverImage } from './ProfileCoverImage'

export const UserInformation = ({
  setProfilePicture,
  profilePicture,
  error,
  value,
  register,
  setIsDeleteImage2,
  quote,
  setQuote,
  coverPicture,
  setCoverPicture,
  setNameChanges,
  croppedPicture
}) => {
  const [from, setFrom] = useState('')

  const inputFileRef = useRef(null)
  const inputFileRef2 = useRef(null)

  const dispatch = useDispatch()

  const checkPicture = (reader, files, useOn) => {
    reader.onload = (e2) => {
      const imageElement = document.createElement('img')
      imageElement.src = e2.target.result

      imageElement.onload = () => {
        const aspectRatio = imageElement.width / imageElement.height

        if (
          useOn === 'pp'
          && aspectRatio === 1
        ) {
          if (files[0].size > 1000000) {
            resizeImage(imageElement, useOn, (resizedImage) => {
              if (resizedImage === null) {
                inputFileRef.current.value = ''
              } else {
                dispatch(setCroppedPicture(resizedImage))
              }
            })
          } else {
            setProfilePicture({
              image: files[0],
              imagePath: URL.createObjectURL(files[0])
            })
            inputFileRef.current.value = ''

            setIsDeleteImage2(false)
          }
        } else if (
          useOn === 'cp'
          && aspectRatio <= 4.05
          && aspectRatio >= 3.95
        ) {
          if (files[0].size > 2000000) {
            resizeImage(imageElement, useOn, (resizedImage) => {
              if (resizedImage === null) {
                inputFileRef2.current.value = ''
              } else {
                dispatch(setCroppedPicture(resizedImage))
              }
            })
          } else {
            setCoverPicture({
              image: files[0],
              imagePath: URL.createObjectURL(files[0])
            })
            inputFileRef2.current.value = ''

            setIsDeleteImage2(false)
          }
        } else {
          if (from === 'pp') {
            inputFileRef.current.value = ''
          } else if (from === 'cp') {
            inputFileRef2.current.value = ''
          }

          dispatch(setIsPictureUploaded(e2.target.result))
        }
      }
    }
  }

  const handleUploadFile = (e) => {
    const { files } = e.target
    const reader = new FileReader()
    reader.readAsDataURL(files[0])

    if (files[0].size > 10000000) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'The profile picture must not exceed 10MB.',
          isEditProfile: true
        })
      )
    } else {
      setFrom('pp')
      dispatch(setCropFor('pp'))
      checkPicture(reader, files, 'pp')
    }
  }

  const handleUploadFile2 = (e) => {
    const { files } = e.target
    const reader = new FileReader()
    reader.readAsDataURL(files[0])

    if (files[0].size > 10000000) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'The cover picture must not exceed 10MB.',
          isEditProfile: true
        })
      )
    } else {
      setFrom('cp')
      dispatch(setCropFor('cp'))
      checkPicture(reader, files, 'cp')
    }
  }

  useEffect(() => {
    if (croppedPicture !== '') {
      // eslint-disable-next-line no-undef
      const isAndroid = (process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION' && cordova?.platformId === 'android')
      if (isAndroid) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setProfilePicture({ image: croppedPicture, imagePath: reader.result })
        }
        reader.readAsDataURL(croppedPicture)
        return
      }
      const syntheticEvent = {
        target: {
          files: [new File([croppedPicture], 'cropped-image.png', { type: 'image/png' })]
        }
      }

      if (from === 'pp') {
        handleUploadFile(syntheticEvent)
      } else if (from === 'cp') {
        handleUploadFile2(syntheticEvent)
      }
    }
  }, [croppedPicture])

  const UploadBckgrndClick = (event) => {
    /* eslint-disable no-undef */
    if (process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION' && cordova?.platformId === 'android') {
      event.stopPropagation()
      callImageSourceSelectionDialog('cp')
    } else {
      inputFileRef2.current.click()
    }
  }

  return (
    <>
      <Stack
        width="100%"
        position="relative"
        alignItems="center"
        direction="row"
        spacing="14px"
      >
        <Box
          // backgroundColor="gray"
          backgroundImage={coverPicture?.imagePath || null}
          backgroundColor={null || 'gray.700'}
          bgPosition="100%"
          bgRepeat="no-repeat"
          bgSize="455px 110px"
          zIndex={-1}
          position="absolute"
          width="146%"
          height="95px"
          padding="20px"
          top="-24px"
          left={-20}
          // right={-20}
          sx={{
            '@media (max-width: 470px)': {
              left: '-20',
              width: '100vw'
            }
          }}
        >
          <Box />
          {/* <Box /> */}
        </Box>
        <Box
          position="absolute"
          width="165%"
          height="7em"
          padding="20px"
          opacity={0}
          onClick={UploadBckgrndClick}
        />

        <Box cursor="pointer">
          <ProfileAvatar
            inputFileRef={inputFileRef}
            handleUploadFile={handleUploadFile}
            profilePicture={profilePicture}
          />
        </Box>

        <Box width="65%" top="-5px" position="relative">
          <SingleInput value={value} register={register} placeholder="Profile Name" onChange={(e) => setNameChanges(e.target.value)} name="profile-name" maxlength="31" minlength="3" _placeholder={{ opacity: 1, color: 'red' }} />
        </Box>

        <Box cursor="pointer">
          <ProfileCoverImage
            inputFileRef={inputFileRef2}
            handleUploadFile={handleUploadFile2}
          />
        </Box>

      </Stack>

      {/* This is used for the error messages to not disrupt the design. Task #1094 */}
      <Box marginTop="-10px">
        <SingleInput
          error={error}
          display="none"
        />
      </Box>

      <Stack direction="column" spacing="10px" sx={{ marginTop: '5px !important' }}>
        <Text fontSize="20px" fontWeight={700}>
          Personal Quote
        </Text>
        <Textarea
          placeholder="Tell the world who you are or what you are looking for!"
          width="100%"
          height="5em"
          maxLength={80}
          value={quote ?? ''}
          name="personal-quote"
          onChange={(e) => setQuote(e.target.value)}
        />
      </Stack>
    </>
  )
}
