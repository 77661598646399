import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import {
  getNotificationMessage,
  authSelectors,
  getAdminNotifications,
  getFriendsNotifications,
  getMarkers,
  getProfileItem,
  getSearchParams,
  getUser,
  setFilters,
  showCurrentChatFriendMarkerCoordinate,
  usersSelectors,
  store,
  setIsSignIn,
  setAskForLocationServices,
  getGuestNotification
} from 'store'
import { chatCredentialsSelectors } from 'store/chat/selectors'
import { ConnectToChat, getChatCredentials } from 'store/chat/services'
import {
  REJECTED,
  SUCCEEDED,
  LOCATION_TYPE,
  ERR_MSG_GEO_LOC
} from 'utils/constants'
import { isAuth } from 'utils/helpers'

import { Content, Layout } from 'components'
import { AboutUs, ContactUs } from 'components/ComingSoon'
import { EmailVerifyPage } from 'components/EmailVerifyPage'
import { FAQ } from 'components/FAQ'
import { NotFound } from 'components/NotFound'
import { PrivacyPolicy } from 'components/PrivacyPolicy'
import { ResetPasswordPage } from 'components/ResetPasswordPage'
import { TermsOfService } from 'components/TermsOfService'

import { changeNumUnread, setMeXmppProblem, setXmppLiveLocation } from 'store/chat/slice'
import { useGeoLocation } from 'hooks/useGeoLocation'
import loadCordovaScript from 'utils/cordova_helpers'

// eslint-disable-next-line import/no-extraneous-dependencies, global-require
const Favico = require('favico.js-slevomat')

const customTheme = {
  fonts: {
    body: 'Ubuntu, Roboto, sans-serif',
    heading: 'Ubuntu, Roboto, sans-serif',
    mono: 'Ubuntu, Roboto, sans-serif'
  }
}

const theme = extendTheme(customTheme)

if (localStorage.getItem('recentSearches') === null) {
  localStorage.setItem('recentSearches', '[]')
}

// IMPORTANT!: this is to get converse object fully loaded.
// For details please see 'node_modules\converse.js\src\entry.js'
if (!window.converseLoadedListener) {
  window.addEventListener('converse-loaded', () => {
    if (typeof converse === 'object') {
      /* eslint-disable no-undef */
      converse.initialize()
      /* eslint-enable no-undef */
    }
  })
  window.converseLoadedListener = true
}

loadCordovaScript()

export const App = () => {
  const { activeProfile, profileItemId, userProfile } = useSelector(usersSelectors)
  const { signInStatus } = useSelector(authSelectors)
  const { chatCredentials } = useSelector(chatCredentialsSelectors)
  const [stopMarkerOpen, setStopMarkerOpen] = useState(true)
  const [getLocationServices, setGetLocationServices] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  useGeoLocation()

  if (!window.converseEventsListeners) {
    const favico = new Favico({
      animation: 'none'
    })
    window.addEventListener('custom-chat-num_unread', (e) => {
      dispatch(changeNumUnread(e.detail.num_unread))
      favico.badge(e.detail.num_unread)
    })

    window.addEventListener('custom-chat-show_on_map', (e) => {
      const { jid } = e.detail
      if (!jid) {
        return
      }
      const currentState = store.getState()
      const friendUserProfileId = Number(jid.split('-')[0])
      // first check my own profile
      const profile = currentState.users.userProfile
        .find((item) => item.id === friendUserProfileId)
      if (profile === undefined) {
        // if id is not in my profiles, check friends
        const friendMarker = currentState.markers
          .markers.find((marker) => marker.id === friendUserProfileId)

        if (friendMarker === undefined) {
          console.error(`Showing on map error: friend id ${friendUserProfileId} not found`)
          return
        }

        const isLiveLocation = friendMarker.isLive ?? false
        let coord = {
          coord: friendMarker.coordinates,
          isStatic: !isLiveLocation
        }
        dispatch(showCurrentChatFriendMarkerCoordinate(coord))
        return
      }
      const activeLocType = profile?.location_type
      const isLiveLocation = (
        activeLocType?.[LOCATION_TYPE.LiveLocation] === 0
        || activeLocType?.[LOCATION_TYPE.LiveApproximate] === 1
      )
      let coord = {
        coord: profile.coordinates,
        isStatic: !isLiveLocation
      }
      dispatch(showCurrentChatFriendMarkerCoordinate(coord))
    })

    window.addEventListener('custom-chat-xmpp_account_problem', (e) => {
      dispatch(setMeXmppProblem(e.detail))
      dispatch(getNotificationMessage({
        type: REJECTED,
        message: e.detail.message,
        isEditProfile: e.detail.message.includes('password is incorrect') ?? false
      }))
    })

    window.converseEventsListeners = true
  }

  useEffect(() => {
    if (activeProfile?.id) {
      dispatch(getChatCredentials())
      dispatch(getMarkers({ isAuth: true }))
      dispatch(getAdminNotifications())
      dispatch(getFriendsNotifications())
      setStopMarkerOpen(false)
    } else if (activeProfile === undefined) {
      setStopMarkerOpen(false)
    }
  }, [activeProfile?.id])

  useEffect(() => {
    // This is for accounts without a profile to be able to open a marker
    if (activeProfile?.id || activeProfile === undefined) {
      setStopMarkerOpen(false)
    }
  }, [activeProfile])

  useEffect(() => {
    if (chatCredentials?.username) {
      dispatch(ConnectToChat())
    }
  }, [chatCredentials])

  useEffect(() => {
    if (profileItemId && !stopMarkerOpen) {
      dispatch(getProfileItem(profileItemId))
    } else if (profileItemId && stopMarkerOpen) {
      dispatch(getProfileItem.pending())
    }
  }, [profileItemId, stopMarkerOpen])

  const checkIfGeoLocIsEnabled = () => {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (permissionStatus?.state === 'granted') {
        localStorage.setItem('isLocDisabled', false)

        navigator.geolocation.getCurrentPosition(
          (res) => {
            localStorage.setItem('isLocDisabled', false)

            if (
              !Number.isNaN(parseFloat(res?.coords?.latitude))
              && !Number.isNaN(parseFloat(res?.coords?.longitude))
            ) {
              dispatch(setXmppLiveLocation(res.coords))
            } else {
              dispatch(
                getNotificationMessage({
                  type: REJECTED,
                  message: ERR_MSG_GEO_LOC[1],
                  isEditProfile: true
                })
              )
            }
          },
          (err) => {
            dispatch(
              getNotificationMessage({
                type: REJECTED,
                message: ERR_MSG_GEO_LOC[1],
                isEditProfile: true
              })
            )

            localStorage.setItem('isLocDisabled', true)
          }
        )
      } else {
        localStorage.setItem('isLocDisabled', true)
      }
    })
  }

  // This will only run after a successful act of logging in
  useEffect(() => {
    if (signInStatus === SUCCEEDED) {
      localStorage.setItem('recentSearches', '[]')
      dispatch(setFilters([]))
      dispatch(getUser())
      dispatch(getMarkers({ isAuth: true }))
      dispatch(setIsSignIn(true))
      navigate('/')
      dispatch(getSearchParams(''))
      setGetLocationServices(true)
      checkIfGeoLocIsEnabled()
    }
  }, [signInStatus])

  // This will only run if the account is already authenticated
  useEffect(() => {
    if (isAuth()) {
      dispatch(getUser())
      dispatch(getMarkers({ isAuth: true }))
      dispatch(getSearchParams(''))
      setGetLocationServices(true)
      checkIfGeoLocIsEnabled()
    } else {
      dispatch(getMarkers({ isAuth: false }))
      dispatch(getGuestNotification())
    }
  }, [isAuth])

  useEffect(() => {
    if (
      process.env.REACT_APP_NODE_ENV !== 'CORDOVA_APPLICATION'
      && getLocationServices
      && (
        userProfile !== null
        && userProfile.length
      )
    ) {
      const usesLiveLoc = userProfile.some((profile) => {
        const locationType = profile.location_type ? Object.values(profile.location_type)[0] : null
        return locationType === 0 || locationType === 1
      })

      const hasUsedProfile = userProfile.some((profile) => profile.full_name !== null)

      if (usesLiveLoc || !hasUsedProfile) {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          if (
            permissionStatus?.state === 'granted'
            || permissionStatus?.state === 'prompt'
          ) {
            navigator.geolocation.getCurrentPosition(
              (res) => {
                localStorage.setItem('isLocDisabled', false)

                if (
                  !Number.isNaN(parseFloat(res?.coords?.latitude))
                  && !Number.isNaN(parseFloat(res?.coords?.longitude))
                ) {
                  dispatch(setXmppLiveLocation(res.coords))
                } else {
                  dispatch(
                    getNotificationMessage({
                      type: REJECTED,
                      message: ERR_MSG_GEO_LOC[1],
                      isEditProfile: true
                    })
                  )
                }
              },
              (err) => {
                dispatch(
                  getNotificationMessage({
                    type: REJECTED,
                    message: usesLiveLoc ? ERR_MSG_GEO_LOC[2] : ERR_MSG_GEO_LOC[0],
                    isEditProfile: true
                  })
                )

                localStorage.setItem('isLocDisabled', true)
              }
            )
          } else {
            if (usesLiveLoc) {
              dispatch(
                getNotificationMessage({
                  type: REJECTED,
                  message: 'Kindly allow Location Services as you are using Live Location',
                  isEditProfile: true
                })
              )
            }

            localStorage.setItem('isLocDisabled', true)
          }
        })

        dispatch(setAskForLocationServices(false))
      }

      setGetLocationServices(false)
    }
  }, [userProfile, getLocationServices])

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route
          path="/contact-us"
          element={(
            <ContactUs />
          )}
        />
        <Route
          path="/about-us"
          element={(
            <AboutUs />
          )}
        />
        <Route
          path="/terms-of-service"
          element={(
            <TermsOfService />
          )}
        />
        <Route
          path="/FAQ"
          element={(
            <FAQ />
          )}
        />
        <Route
          path="/privacy-policy"
          element={(
            <PrivacyPolicy />
          )}
        />
        <Route path="/email/verify" element={<EmailVerifyPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/404" element={<NotFound />} />
        <Route
          path="*"
          element={(
            <Layout>
              <Content />
            </Layout>
          )}
        />
      </Routes>
    </ChakraProvider>
  )
}
