import { createSlice } from '@reduxjs/toolkit'
import { getChatCredentials } from 'store/chat/services'

const initialState = {
  connectionApi: null,
  chatCredentials: null,
  connectedProfile: null,
  meXmppIsOnline: false,
  meXmppProblem: null,
  xmppPushNotification: null,
  numUnread: 0,
  isXmppModalSpinnerHidden: false,
  isMobileImagePickerState: false,
  isMobileImageTransferInprogress: false,
  xmppChatPanelOpened: false,
  forceCloseChatPanel: false,
  xmppLiveLocation: {
    lat: NaN,
    lng: NaN
  }
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setXmppLiveLocation: (state, { payload }) => {
      if (!payload?.latitude || !payload?.longitude) {
        return
      }
      state.xmppLiveLocation.lat = payload.latitude
      state.xmppLiveLocation.lng = payload.longitude
    },
    setXmppPushNotification: (state, { payload }) => {
      state.xmppPushNotification = payload
    },
    clearXmppPushNotification: (state) => {
      state.xmppPushNotification = null
    },
    setXmppChatPanelOpened: (state, { payload }) => {
      state.xmppChatPanelOpened = payload
    },
    setForceCloseChatPanel: (state, { payload }) => {
      state.forceCloseChatPanel = payload
    },
    hideModalSpinner: (state) => {
      state.isXmppModalSpinnerHidden = true
    },
    showModalSpinner: (state) => {
      state.isXmppModalSpinnerHidden = false
    },
    beginMobileImagePicker: (state) => {
      state.isMobileImagePickerState = true
    },
    endMobileImagePicker: (state) => {
      state.isMobileImagePickerState = false
    },
    beginMobileImageTransfer: (state) => {
      state.isMobileImageTransferInprogress = true
    },
    endMobileImageTransfer: (state) => {
      state.isMobileImageTransferInprogress = false
    },
    setMeXmppInitialState: (state) => {
      state.meXmppProblem = null
      state.meXmppIsOnline = false
    },
    setMeXmppProblem: (state, { payload }) => {
      state.meXmppProblem = payload
      console.log(`TODO: implement audit log: ${state.meXmppProblem.status}, ${state.meXmppProblem.message}`)
    },
    setMeXmppOnlineStatus: (state, { payload }) => {
      state.meXmppIsOnline = payload
    },
    openConnectedProfileChat: (state, { payload }) => {
      state.connectedProfile = payload
      const event = new CustomEvent('custom-chat-open_chat_with_friend', {
        detail: {
          recipient: payload
        }
      })
      window.dispatchEvent(event)
    },
    clearConnectedProfileChat: (state) => {
      state.connectedProfile = null
    },
    changeNumUnread: (state, { payload }) => {
      state.numUnread = payload
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(getChatCredentials.fulfilled, (state, { payload }) => {
      state.chatCredentials = payload
    })
  }
})

export const {
  setXmppLiveLocation,
  setXmppPushNotification,
  clearXmppPushNotification,
  setMeXmppInitialState,
  setMeXmppProblem,
  setMeXmppOnlineStatus,
  openConnectedProfileChat,
  clearConnectedProfileChat,
  changeNumUnread,
  hideModalSpinner,
  showModalSpinner,
  beginMobileImagePicker,
  endMobileImagePicker,
  beginMobileImageTransfer,
  endMobileImageTransfer,
  setXmppChatPanelOpened,
  setForceCloseChatPanel
} = chatSlice.actions

export const chatReducer = chatSlice.reducer
