// for https://cordova.apache.org/docs/en/12.x/reference/cordova-plugin-geolocation/
// Checks if app is able to access device location.
const isLocationAvailableAsync = async () => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-undef
  cordova.plugins.diagnostic.isLocationAvailable((_) => {
    resolve(true)
  }, (error) => {
    reject(error)
  })
})

// Returns true if the device setting for location is on
const isLocationEnabledAsync = async () => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-undef
  cordova.plugins.diagnostic.isLocationEnabled((_) => {
    resolve(true)
  }, (error) => {
    reject(error)
  })
})

const verifyAndSetLocationStatus = async () => {
  try {
    await isLocationAvailableAsync()
    await isLocationEnabledAsync()
    localStorage.setItem('isLocDisabled', false)
  } catch (error) {
    console.error('location is not available: ' + error)
    localStorage.setItem('isLocDisabled', true)
  }
}

const onError = (error) => {
  // eslint-disable-next-line no-undef
  console.error('The following error occurred: ' + error)
}

///-----------------
const evaluateAuthorizationStatus = (status) => {
  /* eslint-disable */
  switch (status) {
    case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
      // cordova.plugins.diagnostic.requestLocationAuthorization(evaluateAuthorizationStatus, onError)
      break
    case cordova.plugins.diagnostic.permissionStatus.DENIED:
      navigator.notification.alert('permission LOCATION denied', () => { })
      break
    case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
      // navigator.notification.alert(message, alertCallback, [title], [buttonName])
      navigator.notification.confirm(
        'This app has been denied access to your location and it really needs it function properly. Would you like to switch to the app settings page to allow access?',
        (i) => {
          if (i === 1) {
            cordova.plugins.diagnostic.switchToSettings()
          }
        }, 'Location access denied', ['Yes', 'No'])
      break
    case cordova.plugins.diagnostic.permissionStatus.GRANTED:
      verifyAndSetLocationStatus()
      break
    default:
      break
  }
  /* eslint-enable */
}

/* eslint-disable */
const mobileRequestPermission = () => cordova.plugins.diagnostic.requestRuntimePermissions((statuses) => {
  for (var permission in statuses) {
    switch (statuses[permission]) {
      case cordova.plugins.diagnostic.permissionStatus.GRANTED:
        verifyAndSetLocationStatus()
        break
      case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
        console.error('Permission to use ' + permission + ' has not been requested yet')
        break
      case cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
        navigator.notification.alert(`Permission denied to use  ${permission}`, () => { })
        break
      case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
        navigator.notification.alert(`Permission permanently denied to use ${permission}`, () => { })
        break
    }
  }
}, (error) => {
  console.error('The following error occurred: ' + error)
}, [
  cordova.plugins.diagnostic.permission.ACCESS_FINE_LOCATION,
  cordova.plugins.diagnostic.permission.ACCESS_COARSE_LOCATION
])

const mobileGetPermissionStatus = () => cordova.plugins.diagnostic.getPermissionsAuthorizationStatus((statuses) => {
  for (var permission in statuses) {
    switch (statuses[permission]) {
      case cordova.plugins.diagnostic.permissionStatus.GRANTED:
        verifyAndSetLocationStatus()
        break
      case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
        mobileRequestPermission()
        break
      case cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
        navigator.notification.alert(`Permission denied to use  ${permission}`, () => { })
        break
      case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
        navigator.notification.alert(`Permission permanently denied to use ${permission}`, () => { })
        break
    }
  }
}, (error) => {
  console.error('The following error occurred: ' + error)
}, [
  cordova.plugins.diagnostic.permission.ACCESS_FINE_LOCATION,
  cordova.plugins.diagnostic.permission.ACCESS_COARSE_LOCATION
])
/* eslint-enable */

const loadCordovaScript = () => {
  if (process.env.REACT_APP_NODE_ENV !== 'CORDOVA_APPLICATION') {
    return
  }

  localStorage.setItem('isLocDisabled', true)

  document.addEventListener('deviceready', async () => {
    // eslint-disable-next-line no-undef
    if (cordova.plugins !== undefined) {
      mobileGetPermissionStatus()
    }

    if (!window.cordovaAppEventListeners) {
      document.addEventListener('pause', () => {
        console.log('on application pause')
      }, false)
      document.addEventListener('resume', () => {
        console.log('on application resume')
      }, false)
    }
    window.cordovaAppEventListeners = true
  })

  const script = document.createElement('script')
  script.src = 'cordova.js'
  script.async = true
  document.body.appendChild(script)
}

export default loadCordovaScript
