import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Image, Stack } from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { handleCloseModal } from 'store'

export const ContactUs = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCloseModal())
  }, [])

  return (
    <Box position="relative" width="100%" height="calc(100vh - 100px)">
      <Stack
        justifyContent="center"
        position="absolute"
        alignItems="center"
        spacing="28px"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Image
          boxSize="308px"
          objectFit="cover"
          src={ImagesPng.ContactUs}
          alt="Logo"
          borderRadius="full"
        />
        <Box fontWeight="700" fontSize="32px">
          Contact Us
        </Box>
        <Box fontWeight="500" fontSize="65px">
          Coming soon!
        </Box>
        <SingleButton
          click={() => navigate('/')}
          customStyled={{ width: '100%', paddingBlock: '8px' }}
          text="Go Home"
        />
      </Stack>
    </Box>
  )
}
