import AboutUs from './png/AboutUs.png'
import BlackMarker from './png/BlackMarker.png'
import ContactUs from './png/ContactUs.png'
import DefaultIcon from './png/DefaultIcon.png'
import DefaultImage from './png/DefaultImage.png'
import FloteIcon from './png/FloteIcon.png'
import GoldMarker from './png/GoldMarker.png'
import GreenMarker from './png/GreenMarker.png'
import Image404 from './png/Image404.png'
import Logo from './png/Logo.png'
import LogoHeader from './png/LogoHeader.png'
import Map from './png/Map.png'
import OdyseeIcon from './png/OdyseeIcon.png'
import PrimaryMarker from './png/PrimaryMarker.png'
import RedMarker from './png/RedMarker.png'
import UserImage from './png/UserImage.png'
import InstructionLinkImage from './png/InstructionLinkImage.png'

export const ImagesPng = {
  LogoHeader,
  DefaultImage,
  Map,
  GoldMarker,
  PrimaryMarker,
  BlackMarker,
  GreenMarker,
  RedMarker,
  UserImage,
  DefaultIcon,
  OdyseeIcon,
  FloteIcon,
  Image404,
  Logo,
  ContactUs,
  AboutUs,
  InstructionLinkImage
}
