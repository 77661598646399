import 'converse.js'
import 'converse.js/dist/converse.min.css'
/* eslint-disable import/extensions */
import 'converse.js/dist/emojis.js'
/* eslint-enable import/extensions */

import CustomizationPlugin from './customization-plugin'
import debugLogAllEventsPlugin from './log-all-events-plugin'
import './styles/connectorapp-theme.scss'

const InitChat = async (username, password, discover_connection_methods, bosh_url, ws_url) => {
  /* eslint-disable no-undef */
  if (!window.converse.once) {
    converse.once = true
    converse.plugins.add('CustomizationPlugin', CustomizationPlugin)
    converse.plugins.add('debugLogAllEventsPlugin', debugLogAllEventsPlugin)
  }

  return converse.initialize({
    /* eslint-enable no-undef */
    // see 'src\headless\log.js:L3' in converse.js '10.1.6' source code
    // ****
    // debug output is available only in Firefox browser. It does not work in Chrome
    debug: false,
    loglevel: 'error', // available modes are : debug, info, warn, error fatal
    // ****
    enable_smacks: true, // support for XEP-0198: Stream Management, required!
    // if 'true' the message history is cleared when the XMPP reconnection.
    // previously sent or received messages cannot be recovered on that device
    clear_messages_on_reconnection: false,
    mam_request_all_pages: false,
    // https://conversejs.org/docs/html/configuration.html?highlight=mam_request_all_pages
    archived_messages_page_size: 50,

    discover_connection_methods,
    // NOTE: we never use BOSH, to disable protocol switching we remove the setting below
    // bosh_service_url: bosh_url,
    websocket_url: ws_url,
    jid: username,
    password,

    // do not create extra <converse-root/> which will be added child to 'document.body'
    auto_insert: false,
    view_mode: 'embedded', // <converse-root/> insertion point will be used
    theme: 'connectorapp',
    notification_icon: 'chat/conversejs-filled.svg',
    sounds_path: 'chat/sounds/',
    stanza_timeout: 40000, // 20000ms is default value
    ping_interval: 0, // in seconds, 60 is default
    allow_non_roster_messaging: true,
    priority: 50, // presence priority,
    persistent_store: 'sessionStorage',
    allow_bookmarks: false,
    allow_public_bookmarks: false,
    clear_cache_on_logout: true, // https://conversejs.org/docs/html/configuration.html?highlight=persistent_store#clear-cache-on-logout

    allow_logout: false,
    allow_contact_removal: false,
    allow_contact_requests: false,
    allow_registration: false,
    allow_url_history_change: false,
    auto_focus: false,
    strict_plugin_dependencies: true,
    allow_dragresize: false,
    visible_toolbar_buttons: {
      call: false,
      clear: false,
      emoji: false,
      spoiler: false
    },
    show_message_avatar: true,
    blacklisted_plugins: ['converse-dragresize', 'converse-minimize'],
    whitelisted_plugins: [
      // 'debugLogAllEventsPlugin',
      'CustomizationPlugin'
    ]
  })
}

export default InitChat
