import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Center,
  Flex,
  IconButton,
  Stack,
  Text
} from '@chakra-ui/react'
import { ArrowIcon } from 'assets/icons'
import { motion, useAnimationControls } from 'framer-motion'
import { setProfileItemId, usersSelectors, deleteProfileItem } from 'store'
import { PENDING, SUCCEEDED, REJECTED } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { RequestContent } from './RequestContent'

// For blocked profiles
export const ConnectRequestModal = ({
  handleNotificationButton,
  selectId,
  requestId,
  getProfileItemStatus,
  friendRequestID
}) => {
  const {
    profileItem, profileItemId, acceptFriendStatus, denyFriendStatus
  } = useSelector(usersSelectors)
  const dispatch = useDispatch()
  const headerRef = useRef(null)
  const controls = useAnimationControls()
  // let isBlocked = localStorage.getItem('isBlockedNotification')

  const handleCloseRequestModal = () => {
    localStorage.setItem('isRequestModalOpen', 'false')
    dispatch(setProfileItemId(null))
    localStorage.removeItem('id')
    setTimeout(() => document.getElementById(requestId).scrollIntoView(), 50)
    setTimeout(() => localStorage.setItem('isBlockedNotification', 'false'), 500)
  }

  window.addEventListener('beforeunload', () => {
    if (localStorage.getItem('isNotifOpen') === 'true') {
      dispatch(setProfileItemId(null))
      localStorage.removeItem('id')
      localStorage.setItem('isRequestModalOpen', 'false')
      localStorage.setItem('isBlockedNotification', 'false')
      localStorage.setItem('is-notif-modal-open', 'false')
    }
  })

  // Profile Popup Animation
  useEffect(() => {
    let header = document.getElementById('head-con-request')
    if (profileItem
        && profileItemId
        && getProfileItemStatus === SUCCEEDED
        && requestId === profileItemId
        // && localStorage.getItem('isRequestModalOpen') !== 'true'
    ) {
      if (headerRef?.current) {
        controls.start('visible')
        localStorage.setItem('isRequestModalOpen', 'true')
        // needed instead of scrollIntoView since the
        // latter will just stop the animation from working
        document.querySelector('.notif-container').scroll({
          top: header.offsetTop
        })
      }
    }
  }, [])

  const scrollVariants = {
    hidden: {
      x: '100%'
    },
    visible: {
      x: 0,
      transition: {
        duration: 0.2
      }
    }
  }

  return (
    <Box
      key={uuid()}
      as={motion.div}
      animate={controls}
      display={requestId === profileItemId ? 'auto' : 'none'}
      variants={scrollVariants}
      initial="hidden"
      top="-924px"
      left={0}
      zIndex={5}
      height="1148px"
      // minHeight="700px"
      // maxHeight="1148px"
      width="100%"
      position="absolute"
      backgroundColor="white"
      gap={5}
    >
      <Stack id="head-con-request" ref={headerRef} width="100%" height="32px" background={COLORS.goldGradientLeftToRight} position="sticky">
        <Flex>
          <Box width="95%">
            <Center>
              <Text paddingY={1} fontWeight={600} paddingLeft="50px">Connection Request</Text>
            </Center>
          </Box>
          <Box>
            <IconButton
              _hover={{ background: COLORS.gold_hover }}
              background="unset"
              transform="rotate(180deg)"
              icon={<ArrowIcon />}
              onClick={() => handleCloseRequestModal()}
              id="back-button"
              height="32px"
            />
          </Box>
        </Flex>
      </Stack>

      <RequestContent
        getProfileItemStatus={getProfileItemStatus}
        profileItem={profileItem}
        handleNotificationButton={handleNotificationButton}
        requestId={requestId}
        friendRequestID={friendRequestID}
        isAcceptLoading={acceptFriendStatus === PENDING}
        isRejectLoading={denyFriendStatus === PENDING}
      />

    </Box>
  )
}
