import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Card, CardBody, CardHeader,
  Stack
} from '@chakra-ui/react'
import { LoadingSpinier } from 'common/Loading'
import {
  getAdminNotifications,
  getBlockedRequest,
  getFriendsNotifications,
  setAdminNotifRequest,
  usersSelectors,
  chatCredentialsSelectors
} from 'store'
import { NOTIFICATIONS_TITLE } from 'utils/constants'
import { createValidFormatData } from 'utils/helpers'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { ContentItem } from './ContentItem'
import { FriendsNotifications } from './FrindsNotifications'
import { BlockedRequest } from './BlockedRequest'

export const Notifications = () => {
  const {
    adminNotifications,
    adminNotifRequest,
    getAdminNotificationsStatus,
    getFriendsNotificationsStatus,
    isNotifViewing,
    getBlockedRequestStatus,
    activeProfile
  } = useSelector(usersSelectors)

  const {
    meXmppIsOnline,
    meXmppProblem
  } = useSelector(chatCredentialsSelectors)

  const dispatch = useDispatch()

  const cardRef = useRef()

  const [showNotifications, setShowNotifications] = useState(false)

  window.addEventListener('beforeunload', () => {
    if (localStorage.getItem('isNotifOpen') === 'true') {
      setShowNotifications(false)
    }
  })

  useEffect(() => {
    // This should run every time the user opens the notification modal
    // Also, only after XMPP's 'host-meta' request is finished
    if (meXmppIsOnline || meXmppProblem != null) {
      dispatch(setAdminNotifRequest(null))

      if (getAdminNotificationsStatus !== 'PENDING') {
        dispatch(getAdminNotifications()).then(() => setShowNotifications(true))
      }

      if (getFriendsNotificationsStatus !== 'PENDING') {
        dispatch(getFriendsNotifications())
      }

      if (getBlockedRequestStatus !== 'PENDING') {
        dispatch(getBlockedRequest(activeProfile?.id))
      }
    }
  }, [meXmppIsOnline, meXmppProblem])

  useEffect(() => {
    if (
      getAdminNotificationsStatus === 'SUCCEEDED'
      && getFriendsNotificationsStatus === 'SUCCEEDED'
      && getBlockedRequestStatus === 'PENDING'
    ) {
      setShowNotifications(true)
    }
  }, [getAdminNotificationsStatus, getFriendsNotificationsStatus])

  useEffect(() => {
    if (getFriendsNotificationsStatus !== 'PENDING' && getBlockedRequestStatus !== 'PENDING') {
      localStorage.setItem('notif-request-loaded', true)
    } else {
      localStorage.setItem('notif-request-loaded', false)
    }
  }, [getFriendsNotificationsStatus, getBlockedRequestStatus])

  const notificationsTitle = (title) => {
    switch (title) {
    case NOTIFICATIONS_TITLE.Connector:
      return 'Connector'
    case NOTIFICATIONS_TITLE.Proximity:
      return 'Proximity'
    case NOTIFICATIONS_TITLE.NewUsers:
      return 'New Users'
    case NOTIFICATIONS_TITLE.Concerts:
      return 'Concerts and Events'
    case NOTIFICATIONS_TITLE.Advertisers:
      return 'Advertisers'
    default:
      return ''
    }
  }

  // todo remove and move FriendsNotifications part
  return adminNotifRequest === null || (getAdminNotificationsStatus === 'PENDING' && !isNotifViewing) || !showNotifications ? (
    <LoadingSpinier />
  ) : (
    <Stack
      margin="0px"
      // overflowY={profileItemId === null ? 'auto' : 'hidden'}
      overflowY="auto"
      overflowX="hidden"
      height="100%"
      gap="0 !important"
      className="notif-container"
    >
      <FriendsNotifications
        getFriendsNotificationsStatus={getFriendsNotificationsStatus}
        getBlockedRequestStatus={getBlockedRequestStatus}
      />
      {createValidFormatData(adminNotifications).map((item) => {
        const title = notificationsTitle(item.id)

        if (title === '') {
          return ''
        }

        const isViewedNotification = item?.value?.filter(
          (n) => n?.is_viewed === 0
        ).length

        return (
          <Card key={uuid()} ref={cardRef} boxShadow="none" marginTop="0px !important" background="#c4c3c7">
            <CardHeader display="flex" alignItems="center" padding="0 25px" background="#feeb88">
              <Box textAlign="left" paddingRight="10px">
                {title}
              </Box>
              {isViewedNotification > 0 && (
                <Stack direction="row" alignItems="center">
                  <Box
                    textAlign="center"
                    background={COLORS.gold}
                    width="17px"
                    height="17px"
                    borderRadius="full"
                    position="relative"
                  >
                    <Box
                      fontSize="12px"
                      fontWeight={500}
                      transform="translate(-50%, -50%)"
                      top="50%"
                      left="50%"
                      position="absolute"
                    >
                      {isViewedNotification > 99 ? '99+' : isViewedNotification}
                    </Box>
                  </Box>
                </Stack>
              )}
            </CardHeader>

            <CardBody padding="5px" minHeight="130px">
              <Box
                // paddingLeft="6px"
                marginLeft="5px"
                marginRight="5px"
                sx={{
                  overflowX: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0px'
                }}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                    height: '10px',
                    display: 'block'
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '24px',
                    background: 'rgba(255, 215, 0, 1)'
                  }
                }}
              >
                <ContentItem items={item?.value} title={item?.id} />
              </Box>
            </CardBody>
          </Card>
        )
      })}
      <BlockedRequest
        getFriendsNotificationsStatus={getFriendsNotificationsStatus}
        getBlockedRequestStatus={getBlockedRequestStatus}
      />
    </Stack>
  )
}
