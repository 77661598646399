import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box, Center, Grid, GridItem, Img, Stack
} from '@chakra-ui/react'
import { DownArrowIcon } from 'assets/icons'
import { setSocMedDisplay, setSocMedDisplayStatus } from 'store'

export const SocialProfileSelect = ({
  socialLinkList,
  setSocialLinkSelected,
  socialListDisplay,
  setSocialLinkDisplay,
  socialLinksArray,
  searchSocialLinksCategories,
  setIsSocialLinkSelected
}) => {
  const [isHeightLonger, setIsHeightLonger] = useState(false)

  const socialProfileSelectRef = useRef(null)
  const dispatch = useDispatch()

  const handleSelectSocialProfile = (e) => {
    if (!socialLinksArray.map((item) => item.id).includes(e.id)) {
      setSocialLinkSelected(e)
      setSocialLinkDisplay('none')
      dispatch(setSocMedDisplay(false))
      dispatch(setSocMedDisplayStatus('none'))
      setIsSocialLinkSelected(true)
    }
  }

  useEffect(() => {
    if (socialProfileSelectRef.current && socialListDisplay === 'inline-block'
      && (socialProfileSelectRef.current.scrollHeight
        > socialProfileSelectRef.current.offsetHeight)) {
      setIsHeightLonger(true)
    } else {
      setIsHeightLonger(false)
    }
  }, [socialListDisplay])

  return (
    <>
      <Stack
        position="absolute"
        bgColor="#F6D318"
        width="100%"
        height="100%"
        top={8}
        left={0}
        zIndex={3}
        ref={socialProfileSelectRef}
        overflowY="scroll"
        direction="column"
        sx={{
          '::MsOverflowStyle': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        display={socialListDisplay}
      >
        {/* Social Links Icons */}
        <Stack
          zIndex={1}
          marginTop="20px !important"
          width="100%"
          alignItems="center"
          spacing="10px 10px 0px 10px"
        >
          {searchSocialLinksCategories.filter((item) => item.socialLinksCount > 0)
            .map((item, index) => ((
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} style={{ width: 'inherit', paddingLeft: '20px' }}>
                <Box
                  key={item.id}
                  height=""
                  width="95%"
                  bgGradient="linear(to-b, #fff, #F4E69B)"
                  borderRadius="5px"
                  data-name={item.name}
                >
                  <Grid
                    w="100%"
                    position="relative"
                    templateColumns="repeat(2, 1fr)"
                    gap="70px"
                    padding="70px 18.5% 70px 18.5%"
                    sx={{
                      '@media (max-width: 480px)': {
                        padding: '40px 14% 50px 14%',
                        gap: '40px'
                      }
                    }}
                  >
                    {socialLinkList.map((items) => (
                      items.idCategory === item.id
                        ? (
                          <GridItem key={items.id} w="100px" h="100px" borderRadius="20px" alignItems="center" onClick={() => handleSelectSocialProfile(items)}>
                            <Center h="100px">
                              <Img src={items.icon} borderRadius="20px" w="100%" h="100%" data-name={items.name} data-title="socialLink" />
                            </Center>
                          </GridItem>
                        )
                        : null
                    ))}
                  </Grid>
                </Box>
                <Box width="100%" h="15px" bgColor="#f6d318" />
              </div>
            )))}
        </Stack>
        <Box h="20px" marginTop="20px !important" w="100%" />
      </Stack>
      {isHeightLonger === true
        ? (
          <Stack w="100%" alignItems="center" position="absolute" bottom={0} left={0} zIndex={3}>
            <DownArrowIcon />
          </Stack>
        ) : null }
    </>
  )
}
