import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, AlertIcon } from '@chakra-ui/react'
import { getNotificationMessage, notificationMessageSelector } from 'store'
import { REJECTED, SUCCEEDED } from 'utils/constants'

import { StyledNotification, StyledNotificationEditProfile } from 'components/NotificationMessage/styled'

export const NotificationMessage = () => {
  const { type, message, isEditProfile } = useSelector(notificationMessageSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (type && message) {
      const clearSuccess = setTimeout(() => {
        dispatch(getNotificationMessage({ type: null, message: null, isEditProfile: false }))
      }, 3000)

      return () => {
        clearTimeout(clearSuccess)
      }
    }
    return undefined
  }, [type, message])

  if (type === SUCCEEDED) {
    if (isEditProfile === true) {
      return (
        <StyledNotificationEditProfile>
          <Alert status="success">
            <AlertIcon />
            {message}
          </Alert>
        </StyledNotificationEditProfile>
      )
    }
    return (
      <StyledNotification>
        <Alert status="success">
          <AlertIcon />
          {message}
        </Alert>
      </StyledNotification>
    )
  }
  if (type === REJECTED) {
    if (isEditProfile === true) {
      return (
        <StyledNotificationEditProfile>
          <Alert status="error">
            <AlertIcon />
            {message}
          </Alert>
        </StyledNotificationEditProfile>
      )
    }
    return (
      <StyledNotification>
        <Alert status="error">
          <AlertIcon />
          {message}
        </Alert>
      </StyledNotification>
    )
  }
  return null
}
