import styled from 'styled-components/macro'

export const StyledFiltersContainer = styled.div`
  height: auto;
  gap: 12px;
  position: absolute;
  right: ${({ isOpenRightModal }) => (isOpenRightModal ? '460px' : '14px')};
  top: 40px;
  transition: 0.3s;
  z-index: 999;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  // @media (max-width: 720px) {
  //   top: 40px;
  // }
`
