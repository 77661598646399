import { html } from "lit";
import { _converse } from '@converse/headless/core';
// to prevent input fields zooming in Safari browser in iPhone 
// https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action
export default (o) => {
    const cover = o.model.get('cover_image');
    const cover_type = o.model.get('cover_image_type');
    const mimetype = (!cover_type) ? "image/png" : cover_type;
    const backround = (!cover) ? "" : `background-image: url(data:${mimetype};base64,${cover});background-size: cover;`;
    return html`<div class="box-flyout" style="position:absolute;top:32px;right:0;height:auto"><converse-chat-heading jid="${o.jid}" class="chat-head chat-head-chatbox row no-gutters" style="height:64px;${backround}"></converse-chat-heading><div class="chat-body"><div class="chat-content" aria-live="polite"><converse-chat-content class="chat-content__messages" jid="${o.jid}"></converse-chat-content></div><converse-chat-bottom-panel class="bottom-panel" jid="${o.jid}" style="flex-grow:1;touch-action:manipulation"></div></div>`};
