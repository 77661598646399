import { Footer } from 'components/Footer'
// eslint-disable-next-line import/no-cycle
import { Header } from 'components/Header'
import { NotificationMessage } from 'components/NotificationMessage'

export const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <NotificationMessage />
    <Footer />
  </>
)
