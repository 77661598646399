import 'shared/components/icons.js';
import { __ } from 'i18n';
import { _converse, converse } from "@converse/headless/core";
import { html } from "lit";
import { isUniView } from '@converse/headless/utils/core.js';
import { repeat } from 'lit/directives/repeat.js';

const { u } = converse.env;

const initialFetch = Math.ceil(window.innerHeight / 80)
let willFetch = initialFetch
let currentFetched = 0
let loggedInUser = ''
let stopScroll = false

function renderContact (contact) {
    const jid = contact.get('jid');
    const extra_classes = [];
    if (isUniView()) {
        const chatbox = _converse.chatboxes.get(jid);
        if (chatbox && !chatbox.get('hidden')) {
            extra_classes.push('open');
        }
    }
    const ask = contact.get('ask');
    const requesting  = contact.get('requesting');
    const subscription = contact.get('subscription');
    if ((ask === 'subscribe') || (subscription === 'from')) {
        /* ask === 'subscribe'
         *      Means we have asked to subscribe to them.
         *
         * subscription === 'from'
         *      They are subscribed to us, but not vice versa.
         *      We assume that there is a pending subscription
         *      from us to them (otherwise we're in a state not
         *      supported by converse.js).
         *
         *  So in both cases the user is a "pending" contact.
         */
        extra_classes.push('pending-xmpp-contact');
    } else if (requesting === true) {
        extra_classes.push('requesting-xmpp-contact');
    } else if (subscription === 'both' || subscription === 'to' || u.isSameBareJID(jid, _converse.connection.jid)) {
        extra_classes.push('current-xmpp-contact');
        extra_classes.push(subscription);
        extra_classes.push(contact.presence.get('show'));
    }
    return html`<li class="list-item d-flex controlbox-padded ${extra_classes.join(' ')}" data-status="${contact.presence.get('show')}"><converse-roster-contact .model="${contact}"></converse-roster-contact></li>`;
}

const fetchContacts = (o) => {
    if (currentFetched === willFetch) {
        willFetch = willFetch + initialFetch > o.contacts.length ? o.contacts.length : willFetch + initialFetch
    }

    if (currentFetched < willFetch) {
        const contacts = o.contacts.slice(currentFetched, willFetch)

        currentFetched = willFetch
        willFetch += initialFetch

        window.dispatchEvent(new CustomEvent('fetch-profile-image', {
            detail: {
                roster: contacts
            }
        }))

        setTimeout(() => {
            stopScroll = false
        }, 500)
    }
}

export default (o) => {
    if (o.name === 'Friends') {
        let firstLoad = currentFetched > o.contacts.length ? o.contacts : o.contacts.slice(0, currentFetched)
        let tempLoggedInUser = _converse.api.user.jid()

        if (loggedInUser === '' || loggedInUser !== tempLoggedInUser) {
            loggedInUser = tempLoggedInUser
            currentFetched = 0
            willFetch = initialFetch
            fetchContacts(o)
            firstLoad = []
            stopScroll = false
        }

        document.querySelector('#controlbox-panes').addEventListener('scroll', () => {
            const scrollContainer = document.querySelector('#controlbox-panes')

            if (
                scrollContainer
                && scrollContainer?.scrollTop + scrollContainer?.clientHeight >= scrollContainer?.scrollHeight - 100
                && currentFetched < o.contacts.length
                && !stopScroll
            ) {
                fetchContacts(o)
                stopScroll = true
            }
        })

        return html`<div class="roster-group" data-group="${o.name}">${ repeat(firstLoad, (c) => c.get('jid'), renderContact) }`
    }
}
