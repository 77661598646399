
import * as yup from 'yup'

export const SettingsPasswordValidationScheme = yup.object().shape({
  currentPassword: yup
    .string()
    .required('The field is required')
    .min(8, 'Current password requires minimum 8 characters'),
  password: yup
    .string()
    .required('The field is required')
    .min(8, 'Password requires minimum 8 characters')
    .max(16, 'Password requires maximum 16 characters'),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .when('password', {
      is: (password) => (!!(password && password.length > 0)),
      then: yup.string().oneOf([yup.ref('password')], 'Passwords do not match')
    })
})
