import { useEffect, useRef, useState } from 'react'
import {
  Box, Flex, Input, Stack, Text
} from '@chakra-ui/react'
import { CloseIcon } from 'assets/icons'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

export const Affinities = ({
  affinitiesArray,
  setAffinitiesArray,
  incomplete,
  affinityList
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [affinitiesInput, setAffinitiesInput] = useState('')

  const affinitiesListRef = useRef(null)
  const inputRef = useRef(null)

  const handleDeleteCodeWord = (id) => {
    const newArray = affinitiesArray.filter((item) => item.id !== id)
    setAffinitiesArray(newArray)
  }

  const handleAddAffinities = (e) => {
    if (!affinitiesArray.map((item) => item.title).includes(e.title)) {
      setAffinitiesArray([...affinitiesArray, { id: e.id, title: e.title }])
      setIsPopupOpen(false)
      setAffinitiesInput('')
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        affinitiesListRef.current
        && !affinitiesListRef.current.contains(e.target)
        && !inputRef.current.contains(e.target)
      ) {
        setIsPopupOpen(false)
      }
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#once
    // If {once: true}, the listener would be automatically removed when invoked.
    document.body.addEventListener('mousedown', handleOutsideClick, { once: true })
  }, [isPopupOpen])

  const filterAffinityList = (affinities = affinityList) => affinities.slice(0, 15).map((item) => (
    <Box
      key={uuid()}
      onClick={() => handleAddAffinities(item)}
      mr="5px !important"
      p="6px 18px"
      cursor="pointer"
      borderRadius="full"
      bg={COLORS.lightGray}
      width="max-content"
      alignItems="center"
      name={item.title}
    >
      {item.title}
    </Box>
  ))

  const [filteredAffinityList, setFilteredAffinityList] = useState(filterAffinityList())

  useEffect(() => {
    let affinityListTemp = affinityList
    const tempAffinitiesInput = affinitiesInput.toLowerCase()

    if (affinitiesInput !== '') {
      affinityListTemp = affinityListTemp.filter((item) => {
        const title = item.title.toLowerCase()

        return tempAffinitiesInput === title.substring(0, tempAffinitiesInput.length)
      })
    }

    setFilteredAffinityList(filterAffinityList(affinityListTemp))
  }, [affinitiesInput, affinitiesArray])

  return (
    <Stack direction="column" spacing="10px">
      <Flex gap={2}>
        {affinitiesArray.length <= 0 && incomplete === true
          ? (
            <Text fontSize="20px" fontWeight={700} color="red">
              Affinities
            </Text>
          )
          : (
            <Text fontSize="20px" fontWeight={700}>
              Affinities
            </Text>
          )}
      </Flex>

      <Stack
        rowGap="10px"
        spacing="unset"
        wrap="wrap"
        direction="row"
        display={affinitiesArray.length > 0 ? 'flex' : 'none'}
      >
        {affinitiesArray.map((item) => (
          <Stack
            key={uuid()}
            direction="row"
            spacing="10px"
            mr="5px !important"
            p="6px 18px"
            borderRadius="full"
            bg={COLORS.gold}
            width="max-content"
            alignItems="center"
          >
            <Text data-name="affinity-title">{item.title}</Text>
            <Box onClick={() => handleDeleteCodeWord(item.id)} name="delete-affinity" cursor="pointer">
              <CloseIcon />
            </Box>
          </Stack>
        ))}
      </Stack>

      <Box>
        <Input
          ref={inputRef}
          placeholder="Select Affinities"
          value={affinitiesInput}
          onChange={(e) => setAffinitiesInput(e.target.value)}
          onFocus={() => setIsPopupOpen(true)}
          name="profile_affinities"
        />
      </Box>

      {isPopupOpen && (
        <Stack
          ref={affinitiesListRef}
          gap="10px"
          spacing="0px"
          direction="row"
          flexWrap="wrap"
          data-name="affinityList"
          maxHeight="14em"
          overflowY="scroll"
        >
          { filteredAffinityList }
        </Stack>
      )}
    </Stack>
  )
}
