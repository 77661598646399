import { Box, Stack, Text } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

export const Affinities = ({ affinities }) => (
  <Stack direction="column" spacing="14px">
    <Text fontSize="20px" fontWeight={700}>
      Affinities
    </Text>

    {affinities?.length > 0 ? (
      <Stack direction="row" spacing="14px">
        <Stack gap="12px" spacing="unset" flexWrap="wrap" direction="row">
          {affinities.map((item) => (
            <Box
              key={uuid()}
              borderRadius="full"
              // bg={COLORS.lightGray}
              bg={COLORS.gold}
              p="6px 10px"
              data-name="affinities"
            >
              {item.title}
            </Box>
          ))}
        </Stack>
      </Stack>
    ) : (
      <Text color={COLORS.gray}>No data available</Text>
    )}
  </Stack>
)
