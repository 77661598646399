import { createAsyncThunk } from '@reduxjs/toolkit'

export const getNotificationMessage = createAsyncThunk(
  'getNotificationMessage',
  async (data, { rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getEditProfileMessage = createAsyncThunk(
  'getEditProfileMessage',
  async (data, { rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
