import { createSlice } from '@reduxjs/toolkit'
import { getEditProfileMessage, getNotificationMessage } from 'store'
import { PENDING, REJECTED, SUCCEEDED } from 'utils/constants'

const initialState = {
  type: null,
  message: null,
  isEditProfile: false,
  editProfileSocialLink: [],
  editProfileCodeWord: [],
  notificationStatus: PENDING | SUCCEEDED | REJECTED,
  editProfileMessageStatus: PENDING | SUCCEEDED | REJECTED
}

const notificationMessage = createSlice({
  name: 'notificationMessage',
  initialState,
  reducers: {},
  extraReducers: {
    // Notification Message
    [getNotificationMessage.pending]: (state) => {
      state.notificationStatus = PENDING
    },
    [getNotificationMessage.fulfilled]: (state, action) => {
      state.notificationStatus = SUCCEEDED
      state.type = action.payload?.type
      state.message = action.payload?.message
      state.isEditProfile = action.payload?.isEditProfile
    },
    [getNotificationMessage.rejected]: (state) => {
      state.notificationStatus = REJECTED
    },
    // Edit Profile Message
    [getEditProfileMessage.pending]: (state) => {
      state.editProfileMessageStatus = PENDING
    },
    [getEditProfileMessage.fulfilled]: (state, action) => {
      state.editProfileMessageStatus = SUCCEEDED
      state.editProfileCodeWord = action.payload?.message1
      state.editProfileSocialLink = action.payload?.message2
    },
    [getEditProfileMessage.rejected]: (state) => {
      state.editProfileMessageStatus = REJECTED
    }
  }
})

export default notificationMessage.reducer
