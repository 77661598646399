import React from 'react'

export const ArrowIcon = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   fill="none"
  //   viewBox="0 0 24 24"
  // >
  //   <path
  //     fill="#060606"
  //     d="M3.216 12.534l6.75 6.75a.768.
  // 768 0 001.068 0 .76.76 0 000-1.068L5.56 12.75H20.
  // 25a.75.75 0 100-1.5H5.56l5.474-5.466a.76.76 0 00-1.068-1.068l-6.75 6.75a.76.76 0 000 1.068z"
  //   />
  // </svg>

  // OLD ARROW
  // <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-name="arrowLeft">
  //   <path d="M15 6L9 12L15 18" stroke="#000" strokeWidth="2" />
  // </svg>

  <svg
    viewBox="-4.5 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    width="20"
    height="20"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="2" />
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dribbble-Light-Preview" transform="translate(-345.000000, -6679.000000)" fill="#000000">
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path d="M299.633777,6519.29231 L299.633777,6519.29231 C299.228878,6518.90256 298.573377,6518.90256 298.169513,6519.29231 L289.606572,6527.55587 C288.797809,6528.33636 288.797809,6529.60253 289.606572,6530.38301 L298.231646,6538.70754 C298.632403,6539.09329 299.27962,6539.09828 299.685554,6538.71753 L299.685554,6538.71753 C300.100809,6538.32879 300.104951,6537.68821 299.696945,6537.29347 L291.802968,6529.67648 C291.398069,6529.28574 291.398069,6528.65315 291.802968,6528.26241 L299.633777,6520.70538 C300.038676,6520.31563 300.038676,6519.68305 299.633777,6519.29231" id="arrow_left-[#335]" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
