import {
  Box, Button, Flex, Grid, GridItem, Spacer, Stack, Text
} from '@chakra-ui/react'
import { PlusSignIcon } from 'assets/icons'

export const EventSection = ({
  isPending
}) => {
  const comingSoon = (showPrompt) => (
    <Text fontSize="14px">
      { showPrompt ? 'Coming soon' : '' }
    </Text>
  )

  return (
    <Stack direction="column" spacing="12px" bgColor="white">
      <Box
        bgColor="white"
        height="5px"
        sx={{
          display: 'none',
          '@media (max-width: 480px)': {
            display: 'inline'
          }
        }}
      />
      <Stack
        spacing="48%"
        alignItems="center"
        direction="row"
        w="100%"
        sx={{
          pointerEvents: 'none',
          opacity: 0.5,
          gap: '48%',
          '@media (max-width: 480px)': {
            gap: '52%'
          }
        }}
      >
        {/* TODO client asked to comment */}
        {/* <Box>{icon}</Box> */}
        <Text fontWeight={700} fontSize="20px">
          Events
        </Text>
        <Stack direction="row">
          <Text
            fontWeight={700}
            fontSize="20px"
            right={10}
            width="max-content !important"
            sx={{
              '@media (max-width: 480px)': {
                marginLeft: '-48px'
              }
            }}
          />
          { comingSoon(isPending) }
        </Stack>
      </Stack>

      <Button
        bgGradient="linear(to-t, #fff, #F4E69B)"
        _hover={{
          bgGradient: 'linear(to-t, #fff, #F4E69B)'
        }}
        sx={{ opacity: 0.5 }}
        h="40px"
      >
        <Flex width="100%">
          <Box />
          <Spacer />
          <Text fontSize="16px">
            I want to attend...
          </Text>
          <Spacer />
          <PlusSignIcon />
        </Flex>
      </Button>
      <Grid
        templateColumns="repeat(2, 1fr)"
        height="100px"
        // overflowX="scroll"
        border="1px solid black"
        p="3px"
        gap={0}
        opacity={0.5}
        fontSize="16px"
        h="100px"
        // sx={{
        //   '::MsOverflowStyle': 'none',
        //   'scrollbar-width': 'none',
        //   '::-webkit-scrollbar': {
        //     display: 'none'
        //   }
        // }}
      >
        {/* For Future Use */}
        <GridItem>
          Event 1
        </GridItem>
        <GridItem align="right">
          Type
        </GridItem>
      </Grid>

      {/* <Box width="10px" position="absolute"
      top={0} right={0} bgColor="#c2c3c5" h="850px" float="right" />
      <Box width="10px" position="absolute" top={0} left={0}
      bgColor="#c2c3c5" h="850px" float="right" />
      <Box width="calc(100% + 130px)" bgColor="#c2c3c5"
      sx={{ marginLeft: '-65px !important' }} h={3} /> */}
      <Box
        bgColor="white"
        width="calc(100% + 100px)"
        h="260px"
        sx={{
          marginTop: '-210px !important',
          marginLeft: '-50px !important',
          '@media (max-width: 480px)': {
            marginTop: '-240px !important'
          }
        }}
        zIndex={-1}
        borderRadius="5px"
      />

    </Stack>
  )
}
