import tplGroup from "./group.js";
import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";
import { contactsComparator, groupsComparator } from '@converse/headless/plugins/roster/utils.js';
import { html } from "lit";
import { repeat } from 'lit/directives/repeat.js';
import { shouldShowContact, shouldShowGroup, populateContactsMap } from '../utils.js';


export default (el) => {
    // getting roster from the browser cache. If roster is empty maybe it is the cache failure
    // then fetch roster againg
    const roster = _converse.roster || [];

    const contacts_map = roster.reduce((acc, contact) => populateContactsMap(acc, contact), {});
    const groupnames = Object.keys(contacts_map).filter(shouldShowGroup);
    groupnames.sort(groupsComparator);

    return roster.length === 0 ? html`<div style="display:flex;align-items:center;justify-content:center;flex-flow:row nowrap;line-height:70vh"><p>Problem? refresh</p><a class="controlbox-heading__btn sync-contacts" @click="${ev => el.syncContacts(ev)}" title="or please try to synch contacts"><converse-icon class="fa fa-sync right ${el.syncing_contacts ? 'fa-spin' : ''}" size="1em"></converse-icon></a></div>`:
    html`<div class="list-container roster-contacts"><converse-roster-filter @update="${() => el.requestUpdate()}" style="display:none"></converse-roster-filter>${ repeat(groupnames, (n) => n, (name) => {
                const contacts = contacts_map[name].filter(c => shouldShowContact(c, name));
                contacts.sort(contactsComparator);
                return contacts.length ? tplGroup({ contacts, name }) : '';
            }) }</div>`;
}
