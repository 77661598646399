import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Stack, Text } from '@chakra-ui/react'
import {
  setUserPosition,
  setRandomPos,
  usersSelectors,
  setAskForLocationServices,
  getNotificationMessage
} from 'store'
import {
  INITIAL_POSITION,
  LOCATION_TYPE,
  REJECTED,
  ERR_MSG_GEO_LOC
} from 'utils/constants'
import { chatCredentialsSelectors } from 'store/chat/selectors'
import { setXmppLiveLocation } from 'store/chat/slice'
import { MapModal } from './MapModal'

export const Location = ({
  location_type,
  setSelectLocation,
  userPosition,
  name,
  incomplete,
  full_name,
  coordinates,
  id
}) => {
  const { askForLocationServices } = useSelector(usersSelectors)
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)
  const locationVariant = location_type ? Object.values(location_type)[0] : null
  const [isStaticLocation, setIsStaticLocation] = useState(false)
  const [tempLocationData, setTempLocationData] = useState(null)
  const [isOptionDisabled, setIsOptionDisabled] = useState(
    <>
      <option value={0}>{ LOCATION_TYPE.LiveLocation }</option>
      <option value={1}>{ LOCATION_TYPE.LiveApproximate }</option>
    </>
  )
  const dispatch = useDispatch()
  const isLocDisabled = localStorage.getItem('isLocDisabled')

  // If the user still haven't asked to allow their location services,
  // ask the user to allow live location when they edit a profile
  if (process.env.REACT_APP_NODE_ENV !== 'CORDOVA_APPLICATION' && askForLocationServices) {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (
        permissionStatus?.state === 'granted'
        || permissionStatus?.state === 'prompt'
      ) {
        navigator.geolocation.getCurrentPosition(
          (res) => {
            localStorage.setItem('isLocDisabled', false)

            if (
              !Number.isNaN(parseFloat(res?.coords?.latitude))
              && !Number.isNaN(parseFloat(res?.coords?.longitude))
            ) {
              dispatch(setXmppLiveLocation(res.coords))
            } else {
              setIsStaticLocation(true)

              dispatch(
                getNotificationMessage({
                  type: REJECTED,
                  message: ERR_MSG_GEO_LOC[1],
                  isEditProfile: true
                })
              )
            }
          },
          (err) => {
            localStorage.setItem('isLocDisabled', true)
            setIsOptionDisabled(true)

            if (err.code !== 1) {
              setIsStaticLocation(true)
            }

            dispatch(
              getNotificationMessage({
                type: REJECTED,
                message: err.code === 1 ? ERR_MSG_GEO_LOC[0] : ERR_MSG_GEO_LOC[1],
                isEditProfile: true
              })
            )
          }
        )
      } else {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: ERR_MSG_GEO_LOC[0],
            isEditProfile: true
          })
        )

        localStorage.setItem('isLocDisabled', true)
      }
    })

    dispatch(setAskForLocationServices(false))
  }

  const getRandomDecimalWithPrecision = (min, max, precision) => {
    const factor = 10 ** precision
    min *= factor
    max *= factor
    return (Math.floor(Math.random() * (max - min + 1)) + min) / factor
  }

  const GetTempLocation = () => {
    let tempLocation = null

    const isLocationValid = !Number.isNaN(xmppLiveLocation.lat)
      && !Number.isNaN(xmppLiveLocation.lng)
    if (isLocationValid) {
      tempLocation = xmppLiveLocation
    } else {
      const lat = localStorage.getItem('lat')
      const lng = localStorage.getItem('lng')

      if (!Number.isNaN(parseFloat(lat)) && !Number.isNaN(parseFloat(lng))) {
        tempLocation = {
          lat,
          lng
        }
      } else {
        tempLocation = {
          lat: INITIAL_POSITION[0],
          lng: INITIAL_POSITION[1]
        }
      }
    }
    return tempLocation
  }

  const setUserPosToLiveLoc = (locType = 0) => {
    let addRadiusLat = 0
    let addRadiusLng = 0

    if (locType === 1) {
      addRadiusLat = getRandomDecimalWithPrecision(-0.001, 0.001, 4)
      addRadiusLng = getRandomDecimalWithPrecision(-0.001, 0.001, 4)
    }

    const liveLocation = GetTempLocation()
    console.log(Number(liveLocation.lat) + addRadiusLat)
    console.log(Number(liveLocation.lng) + addRadiusLng)
    dispatch(setUserPosition([
      Number(liveLocation.lat) + addRadiusLat,
      Number(liveLocation.lng) + addRadiusLng
    ]))
  }

  const handleChangeLocation = (e) => {
    setSelectLocation(e)
    if (+e === 2) {
      setIsStaticLocation(true)
      const liveLocation = GetTempLocation()
      dispatch(setUserPosition([
        liveLocation.lat,
        liveLocation.lng
      ]))
    } else {
      setIsStaticLocation(false)
      setUserPosToLiveLoc(+e)
    }
  }

  useEffect(() => {
    if (location_type) {
      if (Object.values(location_type)[0] === 2) {
        setIsStaticLocation(true)
      }
    } else {
      setIsStaticLocation(false)
      setUserPosToLiveLoc()
    }
  }, [])

  let userRandomPos = {
    lat: Number(parseFloat(userPosition?.[0]
      - getRandomDecimalWithPrecision(-0.001, 0.001, 6)).toFixed(6)),
    lng: Number(parseFloat(userPosition?.[1]
      - getRandomDecimalWithPrecision(-0.001, 0.001, 6)).toFixed(6))
  }

  useEffect(() => {
    if (full_name === null || coordinates === null) {
      if (isLocDisabled === 'false') {
        setTimeout(() => handleChangeLocation(0), 1000)
      }
    }
  }, [id])

  useEffect(() => {
    dispatch(setRandomPos(userRandomPos))
  }, [userPosition])

  // for disable/hidden location type options
  useEffect(() => {
    if (isLocDisabled === 'true') {
      setIsOptionDisabled(true)
      handleChangeLocation(2)
      setSelectLocation(2)
      if (full_name === null) {
        setIsStaticLocation(true)
      }
    } else {
      let isLiveLoc = false
      let isLiveAppr = false

      if (
        location_type !== null
        && location_type !== undefined
        && Object.values(location_type)[0] === 2
      ) {
        setIsStaticLocation(true)
      } else if (
        location_type !== null
        && location_type !== undefined
        && Object.values(location_type)[0] === 1
      ) {
        isLiveAppr = true
        handleChangeLocation(1)
        setIsStaticLocation(false)
      } else {
        isLiveLoc = true
        handleChangeLocation(0)
        setIsStaticLocation(false)
      }

      setIsOptionDisabled(
        <>
          <option value={0} selected={isLiveLoc}>{ LOCATION_TYPE.LiveLocation }</option>
          <option value={1} selected={isLiveAppr}>{ LOCATION_TYPE.LiveApproximate }</option>
        </>
      )
    }
  }, [isLocDisabled])

  useEffect(() => {
    if (isStaticLocation && !userPosition && !tempLocationData) {
      setTempLocationData(GetTempLocation())
    }
  }, [isStaticLocation])

  return (
    <Stack spacing="10px">
      {((location_type === 2 || location_type === '2') && userPosition === null) && incomplete === true
        ? (
          <Text fontSize="20px" fontWeight={700} color="red">
            Location
          </Text>
        )
        : (
          <Text fontSize="20px" fontWeight={700}>
            Location
          </Text>
        )}

      <Select
        onChange={(e) => handleChangeLocation(e.target.value)}
        defaultValue={locationVariant || (isLocDisabled === 'true' ? 2 : 0)}
        name={name}
      >
        { isOptionDisabled }
        <option value={2}>{ LOCATION_TYPE.StaticLocation }</option>
      </Select>

      <MapModal
        isStaticLocation={isStaticLocation}
        userPosition={userPosition}
        tempLocationData={tempLocationData}
        full_name={full_name}
      />
    </Stack>
  )
}
