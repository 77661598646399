import React from 'react'

export const AddSearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{ filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.3))' }}
  >
    <path
      fill="#202020"
      d="M10.5 4h3v7h7v3h-7v7h-3v-7h-7v-3h7V4z"
    />
  </svg>
)
