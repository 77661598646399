import React from 'react'

export const StatusIconGray = () => (
  <svg width="13" height="22" viewBox="0 0 18 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 10.9286C17.5 13.8255 16.0089 17.0174 14.2023 19.8049C12.4059 22.5767 10.3476 24.8735 9.31229 25.965C9.13693 26.1499 8.86307 26.1499 8.68771 25.965C7.65236 24.8735 5.59408 22.5767 3.79769 19.8049C1.99115 17.0174 0.5 13.8255 0.5 10.9286C0.5 5.07383 4.39201 0.5 9 0.5C13.608 0.5 17.5 5.07383 17.5 10.9286Z"
      fill="#797979"
      stroke="black"
    />
  </svg>
)
