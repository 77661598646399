import styled from 'styled-components/macro'
import { COLORS } from 'utils/styles'

export const StyledButtonContainer = styled.button`
  cursor: pointer;
  padding:5px;
  border-radius: 5px;
  background-color: ${COLORS.gold};
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  color: ${COLORS.black};
  
  @media (max-width: 480px) {
    &.cardButton {
      font-size: 12px;
      vertical-align: middle;
      &.share{
        width: 40px !important;
      }
    }
  }
  
`
