export const PasswordIconLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#8B8B8B"
      d="M3.79 2.433a.562.562 0 00-.83.76l1.35 1.483C1.758 6.244.66 8.662.612 8.775a.577.577 0 000 .457c.02.05.618 1.371 1.94 2.7C4.317 13.69 6.546 14.625 9 14.625a9.043 9.043 0 003.663-.76l1.547 1.702a.562.562 0 00.795.035.555.555 0 00.035-.794L3.79 2.433zM7.116 7.77l2.932 3.22a2.25 2.25 0 01-2.932-3.22zM9 13.5c-2.166 0-4.057-.787-5.618-2.341A9.02 9.02 0 011.758 9c.33-.619 1.392-2.355 3.326-3.473l1.265 1.392a3.375 3.375 0 004.472 4.922l1.034 1.139A7.882 7.882 0 019 13.5zm8.388-4.268c-.028.063-.738 1.638-2.348 3.08a.57.57 0 01-.602.092.534.534 0 01-.186-.142.562.562 0 01.042-.794A9.21 9.21 0 0016.242 9a9.158 9.158 0 00-1.624-2.166C13.057 5.287 11.166 4.5 9 4.5a7.938 7.938 0 00-1.364.112.57.57 0 01-.647-.464.563.563 0 01.464-.646A9.31 9.31 0 019 3.375c2.454 0 4.683.935 6.448 2.693 1.322 1.329 1.92 2.65 1.94 2.707a.576.576 0 010 .457zm-7.966-2.44a.565.565 0 01.21-1.11 3.396 3.396 0 012.729 3.002.563.563 0 01-.506.611h-.057a.556.556 0 01-.555-.506 2.264 2.264 0 00-1.821-1.997z"
    />
  </svg>
)
