import { Box } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'

export const UsersCount = ({ visibleMarkersCount, showMarkerCount }) => (
  <Box
    zIndex={400}
    position="absolute"
    top="5px"
    fontSize="14px"
    fontWeight="bold"
    background={COLORS.gray_op8}
    padding="4px 12px"
    borderRadius="5px"
    left="50%"
    display={showMarkerCount}
    data-name="markerCount"
    className="marker-count"
  >
    {visibleMarkersCount?.length}
  </Box>
)
