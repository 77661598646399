import {
  useEffect, useRef, useState
} from 'react'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Popup } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Center, Grid, GridItem, Image, Link, Stack, Text
} from '@chakra-ui/react'
// import { CopyIcon } from 'assets/icons'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { StyledButtonContainer } from 'common/Buttons/SingleButton/styled'
import { LoadingSpinier } from 'common/Loading'
import {
  addFriend, deleteFriend, getNotificationMessage, usersSelectors
} from 'store'
import { openConnectedProfileChat, setForceCloseChatPanel } from 'store/chat/slice'
import {
  IS_FRIEND_STATUSES,
  PENDING,
  SUCCEEDED,
  REJECTED
} from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'
import { translateFromLocalUrl } from 'utils/helpers'
import {
  // StyledConnectionsContainer,
  // StyledConnectionsContent,
  // StyledContentImage,
  // StyledContentText,
  // StyledContentTitle,
  // StyledCopiedContainer,
  // StyledCopyIcon,
  StyledFlexContainer,
  StyledModalContainer,
  // StyledUserLastAccessed,
  StyledUserProfileContainer,
  StyledWordsContent,
  StyledWordsTitle
} from './styled'

const ctaButtonTextObj = {
  connect: 'Connect',
  contact: 'Contact',
  pending: 'Pending',
  delete: 'Delete'
}

export const MarkerPopup = ({
  iconMarker, getProfileItemStatus, isMyProfile
}) => {
  const { profileItem, activeProfile } = useSelector(usersSelectors)
  const [isCopiedLink, setIsCopiedLink] = useState(false)
  const [copyTextWarning, setCopyTextWarning] = useState('Share')
  const dispatch = useDispatch()
  const [pay, setPay] = useState('Pay')
  const [ctaButtonText, setCtaButtonText] = useState(ctaButtonTextObj.connect)
  const [isAdd, setIsAdd] = useState(true)

  // let ctaButtonText = ctaButtonTextObj.connect

  const popupRef = useRef(null)

  useEffect(() => {
    // console.log('test96')
    if (profileItem?.is_friends === IS_FRIEND_STATUSES.accepted) {
      setCtaButtonText(ctaButtonTextObj.contact)
      // ctaButtonText = ctaButtonTextObj.contact
    } else if (profileItem?.is_friends === IS_FRIEND_STATUSES.pending) {
      setCtaButtonText(ctaButtonTextObj.pending)
      // ctaButtonText = ctaButtonTextObj.pending
    }
  }, [])

  const handleCtaClick = () => {
    if (profileItem.is_friends === IS_FRIEND_STATUSES.accepted) {
      dispatch(openConnectedProfileChat(profileItem))
    } else if (profileItem.is_friends === IS_FRIEND_STATUSES.no) {
      if (activeProfile?.id) {
        setIsAdd(false)
        setTimeout(() => dispatch(addFriend(profileItem?.id)), 500)
        setTimeout(() => {
          setIsAdd(true)
          setCtaButtonText(ctaButtonTextObj.pending)
        }, 2500)
      } else {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'You do not have an active profile. Please setup your profile first.'
          })
        )
      }
    }
  }

  // To be hidden until further instructions
  const handleRemoveConnection = () => {
    // todo move this fucntionality for delete
    setIsAdd(false)
    setTimeout(() => dispatch(deleteFriend(profileItem?.id)), 1000)
    setTimeout(() => setIsAdd(true), 3000)
    // close Chat pahhel if it is opened
    // to prevent user sending messages after unfriending
    dispatch(setForceCloseChatPanel(true))
  }

  const handlePay = () => {
    setPay('Coming Soon')
    setTimeout(() => setPay('Pay'), 2000)
  }

  const handleShare = () => {
    setIsCopiedLink(true)
    setCopyTextWarning('COPIED')
    navigator.clipboard.writeText(translateFromLocalUrl())
    dispatch(
      getNotificationMessage({
        type: SUCCEEDED,
        message: 'Copied'
      })
    )
  }

  useEffect(() => {
    if (isCopiedLink) {
      const clearSuccess = setTimeout(() => {
        setCopyTextWarning('Share')
        setIsCopiedLink(false)
      }, 1000)

      return () => {
        clearTimeout(clearSuccess)
      }
    }
    return undefined
  }, [isCopiedLink])

  const dataLink = translateFromLocalUrl()
  return (
    <Popup
      maxWidth={350}
      minWidth={300}
      closeButton={false}
      closeOnClick={isAdd}
      ref={popupRef}
      autoClose
    >
      {getProfileItemStatus === PENDING ? (
        <Box width="100%" height="170px">
          <LoadingSpinier />
        </Box>
      ) : (
        <StyledModalContainer>
          <StyledUserProfileContainer>
            <Box
              position="absolute"
              width="100%"
              height="95px"
              zIndex={1}
              // bgColor="gray"
              backgroundImage={profileItem?.banner || ImagesPng?.DefaultImage}
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
              borderTopRadius={10}
              left={0}
              top={0}
              paddingX={10}
            />
            <Grid
              width="100%"
              marginX={5}
              marginY={2}
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(6, 1fr)"
              zIndex={2}
              gap={2.5}
            >
              {/* <Stack gap="16px" direction="row" zIndex={2} marginX={5} marginTop={4}> */}
              <GridItem colSpan={2} rowSpan={2} width="86px">
                <Center>
                  <Image
                    boxSize="70px"
                    objectFit="cover"
                    src={profileItem?.profile_picture || ImagesPng.DefaultImage}
                    alt="image"
                    borderRadius="full"
                  />
                </Center>
              </GridItem>
              {/* </Stack> */}
              <GridItem colSpan={4} rowSpan={1}>
                {isMyProfile ? null : (
                  <Stack
                    direction="row"
                    position="relative"
                    marginLeft={profileItem?.is_friends === IS_FRIEND_STATUSES.accepted ? '0px' : '29%'}
                    height="50px"
                    sx={{
                      '@media (max-width: 480px)': {
                        marginLeft: profileItem?.is_friends === IS_FRIEND_STATUSES.accepted ? '10px' : '40%'
                      }
                    }}
                  >
                    <Stack direction="row" height="30px">
                      <SingleButton
                        text={copyTextWarning}
                        dataLink={dataLink}
                        click={handleShare}
                        name="share"
                        className="cardButton share"
                        customStyled={{
                          width: '65px'
                        }}
                      />
                      <SingleButton
                        click={handleCtaClick}
                        name="connect-status"
                        text={ctaButtonText}
                        className="cardButton"
                      />
                      {
                        profileItem?.is_friends === IS_FRIEND_STATUSES.accepted
                          ? (
                            <SingleButton
                              className="cardButton"
                              customStyled={{
                                backgroundColor: COLORS.gray
                              }}
                              click={handleRemoveConnection}
                              text={ctaButtonTextObj.delete}
                              name="delete-connect"
                            />
                          )
                          : null
                      }
                    </Stack>
                  </Stack>
                )}
              </GridItem>
              <GridItem rowSpan={1} colSpan={4}>
                <Stack direction="column" spacing="4px">
                  <Stack alignItems="center" direction="row" spacing="10px">
                    <Text
                      fontSize="16px"
                      backgroundColor="white"
                      p="5px"
                      position="absolute"
                      // bottom={-7}
                      // right="20px"
                      borderRadius={3}
                      width="200px"
                      data-name="profileCardName"
                      sx={{
                        '@media (max-width: 480px)': {
                          width: '170px',
                          'word-break': 'break-all'
                        }
                      }}
                    >
                      {profileItem?.full_name}
                    </Text>
                  </Stack>
                  {/* <StyledUserLastAccessed>
                    Last accessed:
                    {' '}
                    {profileItem?.last_accessed}
                  </StyledUserLastAccessed> */}
                </Stack>
              </GridItem>
            </Grid>
          </StyledUserProfileContainer>

          {
            profileItem?.personal_quote ? (
              <Stack direction="column" fontSize={15} marginX={20}>
                <Text
                  as="span"
                  align="center"
                  sx={{
                    '::-webkit-line-clamp': '3',
                    'line-clamp': '3'
                  }}
                >
                  {profileItem?.personal_quote}
                </Text>
              </Stack>
            ) : ''
          }

          <Stack textAlign="center" direction="column" justifyContent="space-between" marginX={10} fontSize={15}>
            <Stack justifyContent="space-between" direction="row">
              <Text as="span" fontWeight="bold">Connections</Text>
              <Text as="span">
                {profileItem?.active_connections}
              </Text>
            </Stack>
            <Stack justifyContent="space-between" direction="row">
              <Text as="span" fontWeight="bold">Disconnections</Text>
              <Text as="span">
                {profileItem?.past_connections}
                %
              </Text>
            </Stack>
            <Stack justifyContent="space-between" direction="row">
              <Text as="span" fontWeight="bold">Status</Text>
              <Text as="span">
                {iconMarker}
              </Text>
            </Stack>
          </Stack>

          <StyledWordsTitle>Affinities</StyledWordsTitle>

          <StyledFlexContainer>
            {profileItem?.affinities?.map((aff) => (
              <StyledWordsContent key={uuid()}>{aff.title}</StyledWordsContent>
            ))}
          </StyledFlexContainer>

          {profileItem?.social_links ? (
            <>
              <StyledWordsTitle>Social Profiles</StyledWordsTitle>

              <Stack direction="column" marginX={5}>
                <Stack direction="column">
                  {profileItem.social_links?.length > 0 ? (
                    <Stack direction="row" spacing="10px">
                      <Stack gap="12px" spacing="unset" flexWrap="wrap" direction="row">
                        {profileItem.social_links.map((item) => (
                          <Box
                            key={uuid()}
                            borderRadius="full"
                            bg={COLORS.goldGradient}
                            p="6px 10px"
                          >
                            <Link href={item.link} target="_blank" name={item.name} _cursor="pointer" style={{ textDecoration: 'none', color: 'black' }}>
                              {item.name}
                            </Link>
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  ) : (
                    <Text as="span" color={COLORS.gray}>No data available</Text>
                  )}
                </Stack>
              </Stack>
            </>
          ) : null}
          <StyledButtonContainer
            onClick={() => handlePay()}
            name="pay"
            style={{
              margin: '30px 20px 10px',
              padding: '7.5px 0px',
              fontWeight: '500',
              background: COLORS.goldGradient
            }}
          >
            {pay}
          </StyledButtonContainer>
        </StyledModalContainer>
      )}
    </Popup>
  )
}
