import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getNotificationMessage } from 'store'
import { REJECTED } from 'utils/constants'
import { isLiteralNumber } from 'utils/helpers'

import { FiltersData } from './FiltersData'
import { Map } from './Map'
import { StyledContentContainer } from './styled'
import { UsersCount } from './UsersCount'

export const Content = () => {
  const dispatch = useDispatch()

  const [visibleMarkersCount, setVisibleMarkersCount] = useState([])
  const [showMarkerCount, setShowMarkerCount] = useState('inline-block')

  const [searchParams] = useSearchParams()
  const [storedId, setStoredId] = useState()

  const id = searchParams.get('id')

  if (id !== null) {
    if (isLiteralNumber(id)) {
      localStorage.setItem('id', id)
    } else {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Invalid URL parameters',
          isEditProfile: true
        })
      )
    }
    // dispatch(getIdfromParam(id))
  }

  useEffect(() => {
    const idTemp = localStorage.getItem('id')
    if (localStorage.getItem('id') !== null) {
      setStoredId(idTemp)
    }
  }, [])
  return (
    <StyledContentContainer>
      <Map
        setVisibleMarkersCount={setVisibleMarkersCount}
        setShowMarkerCount={setShowMarkerCount}
        storedId={storedId}
        setStoredId={setStoredId}
        showMarkerCount={showMarkerCount}
      />
      <FiltersData showMarkerCount={showMarkerCount} />
      <UsersCount
        visibleMarkersCount={visibleMarkersCount}
        showMarkerCount={showMarkerCount}
      />
    </StyledContentContainer>
  )
}
