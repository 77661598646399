import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserProfile,
  handleLocationMyPosition,
  logOut,
  setIsProfileActive,
  setUserEdited,
  setUserSectionPage,
  usersSelectors,
  setSelectedUserProfile,
  getGuestNotification
} from 'store'
import {
  EDIT_PROFILE,
  MY_PROFILES,
  PENDING,
  SETTINGS,
  SINGLE_PROFILE,
  SUCCEEDED
} from 'utils/constants'

import { EditProfile } from 'components/UserSection/EditProfile'
import { MyProfiles } from 'components/UserSection/MyProfiles'
import { Settings } from 'components/UserSection/Settings'
import { SingleProfile } from 'components/UserSection/SingleProfile'

export const UserSection = () => {
  const {
    user,
    userSectionPage,
    userProfile,
    getUserProfileStatus,
    selectProfileStatus,
    searchBar,
    searchSocialLinks,
    searchSocialLinksCategories,
    updateUserProfileStatus,
    updateUserSettingsStatus,
    deleteProfileStatus,
    deleteUserStatus,
    userPosition,
    isSettingPage,
    userEdited,
    croppedPicture
  } = useSelector(usersSelectors)

  const [selectedUser, setSelectedUser] = useState('')

  const dispatch = useDispatch()

  const handleSelectUser = (item) => {
    setSelectedUser(item)
    if (item.full_name) {
      dispatch(setUserSectionPage(SINGLE_PROFILE))
      dispatch(setIsProfileActive(true))
    } else {
      dispatch(setUserSectionPage(EDIT_PROFILE))
      dispatch(setIsProfileActive(false))
    }
  }

  const handleSelectLocation = (item) => {
    setSelectedUser(item)
    dispatch(setSelectedUserProfile(item))
    dispatch(handleLocationMyPosition(item))
  }

  const handleLogOut = () => {
    localStorage.setItem('recentSearches', '[]')
    dispatch(logOut(true))
    dispatch(getGuestNotification())
  }

  const selectPage = () => {
    switch (userSectionPage) {
    case MY_PROFILES:
      return (
        <MyProfiles
          userProfile={userProfile}
          isLoading={getUserProfileStatus === PENDING || selectProfileStatus === PENDING}
          handleSelectUser={handleSelectUser}
          handleLogOut={handleLogOut}
        />
      )
    case SINGLE_PROFILE:
      return (
        <SingleProfile
          selectedUser={selectedUser}
          setUserSectionPage={setUserSectionPage}
          handleSelectLocation={handleSelectLocation}
        />
      )
    case EDIT_PROFILE:
      return (
        <EditProfile
          userPosition={userPosition}
          isLoading={updateUserProfileStatus === PENDING}
          deleteProfileStatus={deleteProfileStatus}
          searchSocialLinks={searchSocialLinks}
          searchSocialLinksCategories={searchSocialLinksCategories}
          selectedUser={selectedUser}
          setUserSectionPage={setUserSectionPage}
          croppedPicture={croppedPicture}
          affinityList={searchBar.affinities}
        />
      )
    case SETTINGS:
      return (
        <Settings
          isLoading={updateUserSettingsStatus === PENDING}
          user={user}
          deleteUserStatus={deleteUserStatus}
        />
      )
    default:
      return null
    }
  }

  useEffect(() => {
    if (updateUserProfileStatus === SUCCEEDED) {
      if (isSettingPage === true) {
        dispatch(setUserSectionPage(SETTINGS))
        dispatch(getUserProfile())
      } else if (userProfile) {
        dispatch(getUserProfile())
        userProfile.map((item) => {
          if (userEdited.id === item.id) {
            handleSelectUser(item)
            dispatch(setUserEdited([]))
          }
          return null
        })
      }
    }
  }, [updateUserProfileStatus])

  // useEffect(() => {
  //   dispatch(handleLocationMyPosition())
  // }, [selectProfileStatus, userProfile])

  useEffect(() => {
    setSelectedUser('')
    dispatch(setUserSectionPage(MY_PROFILES))
  }, [])

  return selectPage()
}
