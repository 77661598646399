import { useNavigate } from 'react-router-dom'
import { Box, Image, Stack } from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Box h="100vh" w="100%">
      <Stack
        spacing="32px"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}
      >
        <Image
          boxSize="618px"
          objectFit="cover"
          src={ImagesPng.Image404}
          alt="Logo"
          borderRadius="full"
          margin="auto"
        />

        <SingleButton
          customStyled={{
            width: '320px',
            margin: '40px auto 0',
            fontSize: '20px',
            fontWeight: 400
          }}
          click={() => navigate('/')}
          isLoading={false}
          text="Go to home page"
        />
      </Stack>
    </Box>
  )
}
