export const SettingsIcon = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   fill="none"
  //   viewBox="0 0 24 24"
  // >
  //   <g clipPath="url(#clip0_252_1903)">
  //     <path
  //       fill="#000"
  //       d="M11.075 19.183H3.296a.78.78 0 01-.618-.3.842.842 0 01-.163-.694c.802-3.95 4.
  // 204-6.833 8.132-6.927.065.003.13.005.196.005 2.898 0 5.256-2.415 5.256-5.384C16.099 2.
  // 915 13.74.5 10.843.5 7.946.5 5.588 2.915 5.588 5.883c0 1.762.83 3.329 2.112 4.312a9.971 9.
  // 971 0 00-3.24 1.823 10.374 10.374 0 00-3.459 5.848c-.145.715.03 1.45.478 2.015.447.563 1.11.
  // 886 1.817.886h7.78a.782.782 0 00.772-.792.782.782 0 00-.773-.792zm-3.941-13.3c0-2.095 1.
  // 664-3.8 3.71-3.8 2.045 0 3.709 1.705 3.709 3.8 0 2.066-1.617 3.751-3.622 3.8a.745.745 0 00-.
  // 088-.006c-.06 0-.12 0-.18.002-1.962-.097-3.53-1.762-3.53-3.796z"
  //     />
  //     <path
  //       stroke="#000"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeWidth="0.933"
  //       d="M17 11c.301 0 .598.022.886.065l.436 1.32a4.73 4.73 0 011.
  // 08.459l1.251-.604c.443.34.838.742 1.17 1.19l-.625 1.241c.189.34.338.
  // 705.44 1.089l1.312.457a6.06 6.06 0 01-.015 1.67l-1.
  // 32.435a4.77 4.77 0 01-.459 1.08l.604 1.251a6.04 6.04 0 01-1.19 1.17l-1.
  // 241-.625c-.343.191-.709.34-1.089.44l-.456 1.311a6.06 6.06 0 01-1.67-.014l-.
  // 436-1.32a4.773 4.773 0 01-1.08-.459l-1.251.604a6.034 6.034 0 01-1.17-1.19l.626-1.241a4.774 4.
  // 774 0 01-.44-1.089l-1.312-.457a6.054 6.054 0 01.014-1.67l1.32-.435a4.73 4.73 0 01.459-1.08l-.
  // 604-1.251c.341-.443.742-.837 1.191-1.17l1.24.626c.343-.192.71-.34 1.089-.441l.458-1.311c.26-.
  // 034.52-.051.782-.051v0z"
  //       clipRule="evenodd"
  //     />
  //     <path
  //       stroke="#000"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       d="M17 19.572a2.571 2.571 0 10-.001-5.143 2.571 2.571 0 000 5.143z"
  //     />
  //   </g>
  //   <defs>
  //     <clipPath id="clip0_252_1903">
  //       <path fill="#fff" d="M0 0H24V24H0z" />
  //     </clipPath>
  //   </defs>
  // </svg>

  <svg width="20" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-name="settingsIcon">
    <path d="M2.33337 1.66663H25.6667" stroke="black" strokeWidth="3" strokeLinecap="round" />
    <path d="M2.33337 10H25.6667" stroke="black" strokeWidth="3" strokeLinecap="round" />
    <path d="M2.33337 18.3334H25.6667" stroke="black" strokeWidth="3" strokeLinecap="round" />
  </svg>
)
