import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@chakra-ui/react'
import { deleteFilter, usersSelectors } from 'store'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { FilterItem } from './FilterItem'
import { StyledFiltersContainer } from './styled'

export const FiltersData = () => {
  const { filters, isOpenRightModal } = useSelector(usersSelectors)
  const [isShow, setIsShow] = useState(false)

  const dispatch = useDispatch()

  const deleteSearchBarFilter = (id) => {
    dispatch(deleteFilter(id))
    setIsShow(false)
  }

  return (
    <Stack className="filter-data">
      {isShow && (
        <Box
          textAlign="center"
          padding="10px 20px"
          width="200px"
          background={COLORS.gray_op8}
          zIndex={999}
          right="220px"
          top="22px"
          borderRadius="8px"
          position="absolute"
        >
          No affinity or code word found
        </Box>
      )}

      <StyledFiltersContainer isOpenRightModal={isOpenRightModal} name="affinities_list">
        {filters.map((item) => (
          <FilterItem
            setIsShow={setIsShow}
            deleteSearchBarFilter={deleteSearchBarFilter}
            item={item}
            key={uuid()}
          />
        ))}
      </StyledFiltersContainer>
    </Stack>
  )
}
