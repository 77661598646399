import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box, ListItem, Stack, Text, UnorderedList
} from '@chakra-ui/react'
import { SingleButton } from 'common/Buttons'
import { handleCloseModal } from 'store'
import { handleClickScroll } from 'utils/helpers'

export const PrivacyPolicy = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCloseModal())
  }, [])

  return (
    <Stack spacing="16px" padding="30px 65px" position="relative" width="100%">
      <Text fontWeight="700" fontSize="32px" textAlign="center">
        Connector Privacy Policy
      </Text>

      <Text fontWeight="500" fontSize="24px">
        Table of Contents
      </Text>

      <Box pl="5px">
        <UnorderedList>
          <ListItem
            _hover={{ borderBottom: '1px solid black' }}
            cursor="pointer"
            onClick={() => handleClickScroll('1.')}
            width="max-content"
          >
            1.Acceptance of Terms
          </ListItem>
          <ListItem
            _hover={{ borderBottom: '1px solid black' }}
            cursor="pointer"
            onClick={() => handleClickScroll('2.')}
            width="max-content"
          >
            2.Information That We Collect
          </ListItem>
          <Box pl="38px">
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.1.')}
              width="max-content"
            >
              2.1. Account Ownership Information
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.2.')}
              width="max-content"
            >
              2.2. Content That You Post
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.3.')}
              width="max-content"
            >
              2.3. Purchases or Transfers Using Crypto Currency
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.4.')}
              width="max-content"
            >
              2.4. Communications Among Users
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.5.')}
              width="max-content"
            >
              2.5. Advertising
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.6.')}
              width="max-content"
            >
              2.6. Data Not Collected
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.7.')}
              width="max-content"
            >
              2.7. Data Not Shared
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.8.')}
              width="max-content"
            >
              2.8. Information That Might be Automatically Collected
            </ListItem>
          </Box>
          <ListItem
            _hover={{ borderBottom: '1px solid black' }}
            cursor="pointer"
            onClick={() => handleClickScroll('3.')}
            width="max-content"
          >
            3. Law Enforcement
          </ListItem>
          <Box pl="38px">
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('3.0.1.')}
              width="max-content"
            >
              3.0.1. Log Files and IP Address
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('3.0.2.')}
              width="max-content"
            >
              3.0.2. Location
            </ListItem>
          </Box>
          <ListItem
            _hover={{ borderBottom: '1px solid black' }}
            cursor="pointer"
            onClick={() => handleClickScroll('4.')}
            width="max-content"
          >
            4. Cookies
          </ListItem>
        </UnorderedList>
      </Box>

      <Box>
        <Text id="1." fontWeight="500" fontSize="24px">
          1. Acceptance of Terms
        </Text>

        <Text>
          This Privacy Policy describes the types of data that Connector collects about users, and
          how it uses that information. This policy applies to all users of the Connector website,
          related websites, and Connector services (collectively referred to as "Services"). To use
          Connector Services, you must accept the Terms of Service and this Privacy Policy which is
          referenced in the Terms of Service.
        </Text>
      </Box>

      <Box>
        <Text id="2." fontWeight="500" fontSize="24px">
          2. Information That We Collect
        </Text>
        <Text id="2.1." fontWeight="500" fontSize="20px">
          2.1. Account Ownership Information
        </Text>
        <Text lineHeight="30px">
          We collect the email IDs of registered users so that we can verify account ownership,
          reset passwords, provide legal notices (such as updates to the Terms of Service and
          perhaps sending notices about service availability), and mitigate Spam, malicious
          behavior, and denial of service attacks. We do not share your email ID with any third
          parties.
          <br />
          Users may specify "profile names" associated with their various identities, and those
          words must not be in violation of the Terms of Service. We do not collect your name unless
          you voluntarily enter your real name either in the profile name or in some other publicly
          accessible place.
          <br />
          We keep a record of the last login date, and during an initial release period, Connector
          will offer rewards to early adopters who use Connector frequently according to the
          conditions of the offer. Connector will store data necessary to accurately distribute
          rewards to early adopters who have met the criteria specified in the offer.
          <br />
          We do not ask for your phone number or address.
          <br />
          We do not purchase or otherwise collect information about you from outside sources or use
          cookies to harvest such information.
          <br />
          We do not knowingly store information that would identify your device, although your
          request of web pages might reveal your IP address and information about your browser. Any
          information collected in that manner would not be shared with third parties.
        </Text>
        <Text id="2.2." fontWeight="500" fontSize="20px">
          2.2. Content That You Post
        </Text>
        <Text>
          When you specify your affinities (interests) and any code words on your public profile, we
          store that information and share it with other users of the web site. That information is
          accessible to users but is not associated with your email address unless you voluntarily
          disclose your email address.
        </Text>
        <Text id="2.3." fontWeight="500" fontSize="20px">
          2.3. Purchases or Transfers Using Crypto Currency
        </Text>
        <Text>
          Connector will offer enhanced Services in exchange for cryptocurrency, so we will collect
          and store information necessary to complete such transactions and provide the related
          services. We do not offer any services in exchange for credit card or fiat currency.
        </Text>
        <Text id="2.4." fontWeight="500" fontSize="20px">
          2.4. Communications Among Users
        </Text>
        <Text lineHeight="30px">
          Connector collects information about your contacts (other users with whom you have
          mutually agreed to establish a connection) so that we can facilitate communication with
          your friends and associates on Connector. That information is not shared with any third
          parties.
          <br />
          Connector would temporarily store meta-information about user-to-user communications that
          might include the origin and destination IP addresses. We strive to implement OMEMO
          encryption for all communications among users, but it is up to the user to ensure that
          they do not disable encryption or send sensitive information that is not encrypted. OMEMO
          encrypts information so that none of the Connector servers can see your communications
          with other users. Data sent with OMEMO encryption is not visible to our servers, but if
          users do send information that is not encrypted, it might be stored on Connector servers
          for up to 48 hours, or, depending solely on the discretion of Connector, we might not
          store messages sent to offline users.
        </Text>
        <Text id="2.5." fontWeight="500" fontSize="20px">
          2.5. Advertising
        </Text>
        <Text>
          Connector might display advertising that is associated with your affinities (interests)
          that you specify in your profiles. No identifying information is provided to advertisers.
        </Text>
        <Text id="2.6." fontWeight="500" fontSize="20px">
          2.6. Data Not Collected
        </Text>
        <Text>We do not collect:</Text>
        <UnorderedList>
          <Box pl="38px">
            <ListItem>
              We do not collect information from linked services or provide access to your personal
              dato to third parties.
            </ListItem>
            <ListItem>
              We do not knowingly collect your name, address, or phone number, but if you enter that
              information into your public profile, it might be available for other users to see.
            </ListItem>
          </Box>
        </UnorderedList>
        <Text id="2.7." fontWeight="500" fontSize="20px">
          2.7. Data Not Shared
        </Text>
        <Text>
          We do not sure user information with outside companies other than some advertisers might
          assume that you have a particular affinity if you respond to their advertisements.
        </Text>

        <Text id="2.8." fontWeight="500" fontSize="20px">
          2.8. Information That Might be Automatically Collected
        </Text>
      </Box>

      <Box>
        <Text id="3." fontWeight="500" fontSize="24px">
          3. Law Enforcement
        </Text>

        <Text>
          Connector may disclose information about you that is stored on our servers to comply with
          requests from law enforcement agencies. If you use OMEMO encryption with your
          communications with other users, we will have no information about the content of your
          communication with other users, but our servers might contain meta-information about the
          accounts on your friends lists and the IP addresses of people with whom you have
          communicated.
        </Text>

        <Text id="3.0.1." fontWeight="500" fontSize="20px">
          3.0.1. Log Files and IP Address
        </Text>
        <Text>
          We briefly retain IP address information for use in identifying unauthorized access,
          abuse, violation of terms of service, and denial of service attacks. Regular log files
          would be dropped within 31 days, but information pertaining to attempted unauthorized
          access, abuse, violation of terms of service, or denial of service might be retained
          indefinitely. The web pages that you access will be associated with your IP address in
          these log files.
        </Text>

        <Text id="3.0.2." fontWeight="500" fontSize="20px">
          3.0.2. Location
        </Text>
        <Text>
          Connector will store location information that you provide so that we can place your
          markers on the map. You are not required to provide access to location services in your
          web browser to use Connector services because you could choose to select an arbitrary
          location on the map to represent your approximate location. Advertisers might choose to
          display advertising to users with certain affinities (interests) within a geographic
          region, but Connector does not directly release your location to advertisers.
        </Text>
      </Box>

      <Box>
        <Text id="4." fontWeight="500" fontSize="24px">
          4. Cookies
        </Text>

        <Text>We use cookies to maintain sessions across web requests.</Text>
      </Box>

      <SingleButton
        click={() => navigate('/')}
        customStyled={{ width: 'max-content', padding: '8px 20px', margin: '30px auto 0px' }}
        text="Go Home"
      />
    </Stack>
  )
}
