import axios from 'axios'
import { API } from 'configs/api'

export const markersApi = {
  getMarkers: ({ isAuth, filter }) => axios.post(
    API.profileCoordinates,
    filter,
    { token: isAuth }
  ),
  getFriendMarkers: ({ isAuth }) => axios.post(
    API.friendMarkes,
    {},
    { token: isAuth }
  ),
  liveMarkerUpdate: ({ isAuth, northeast, southwest }) => axios.post(
    API.liveUpdate,
    {
      northeast, southwest
    },
    { token: isAuth }
  ),
  staticMarkerUpdate: ({ isAuth, northeast, southwest }) => axios.post(
    API.staticUpdate,
    {
      northeast, southwest
    },
    { token: isAuth }
  )
}
