import axios from 'axios'
import { API } from 'configs/api'
import { defaultBody } from 'configs/service'

export const authApi = {
  sigIn: (body) => axios.post(API.oauth, { ...body, ...defaultBody }),
  sigUp: (body) => axios.post(API.register, body),
  emailVerify: (body) => axios.post(API.emailVerify, body),
  forgotPassword: (body) => axios.post(API.forgotPassword, body),
  resetPassword: (body) => axios.post(API.resetPassword, body),
  guestNotification: () => axios.post(API.guestNotification, {})
}
