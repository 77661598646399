import React from 'react'

export const FriendsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="#313131"
      d="M21.095 7.093c.017 2.14-1.614 3.894-3.637 3.911h-.032a3.549 3.549 0 01-2.588-1.133 3.978 3.978 0 01-1.08-2.718c-.018-2.14 1.614-3.894 3.636-3.91h.032c2.007.002 3.65 1.721 3.669 3.85zM24 18.273v1.203H10.852v-1.203a6.581 6.581 0 016.574-6.574A6.581 6.581 0 0124 18.273z"
    />
    <path
      fill="#060606"
      d="M17.426 11.004V3.242c2.007.003 3.65 1.722 3.668 3.851.018 2.14-1.614 3.894-3.636 3.911h-.032zM24 18.273v1.203h-6.574v-7.777A6.581 6.581 0 0124 18.273z"
    />
    <path
      fill="#FFE450"
      d="M12.033 7.123c0 2.496-1.915 4.526-4.27 4.526-2.353 0-4.269-2.03-4.269-4.526 0-2.495 1.916-4.525 4.27-4.525 2.354 0 4.27 2.03 4.27 4.525zM15.528 20.098v1.304H0v-1.304c0-4.28 3.483-7.764 7.764-7.764 4.282 0 7.764 3.483 7.764 7.764z"
    />
    <path
      fill="gold"
      d="M7.765 11.649V2.598c2.354 0 4.269 2.03 4.269 4.525 0 2.496-1.915 4.526-4.27 4.526zM15.529 20.098v1.304H7.765v-9.068c4.28 0 7.764 3.483 7.764 7.764z"
    />
  </svg>
)
