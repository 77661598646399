import { html } from "lit";

const getImgHref = (image, image_type) => {
    return image.startsWith('data:') ? image : `data:${image_type};base64,${image}`;
}

export default  (o) => {
    if (o.image) {
        if (o.where === 'roster_item') {
            return html`<img class="avatar ${o.classes}" width="${o.width}" height="${o.height}" src="${getImgHref(o.image, o.image_type)}" style="object-fit:cover;${o.width !== null && o.width !== undefined ? `width: ${o.width}px !important;` : ''}${o.height !== null && o.height !== undefined ? `height: ${o.height}px !important;` : ''}${o.borderColorVar !== null && o.borderColorVar !== undefined ? `border: 2px solid var(--${o.borderColorVar});` : ''}">`;
        } else {
            return html`<svg xmlns="http://www.w3.org/2000/svg" class="avatar ${o.classes}" width="${o.width}" height="${o.height}"><image data-nickname="${o.nickname}" width="${o.width}" height="${o.height}" preserveAspectRatio="xMidYMid meet" href="${getImgHref(o.image, o.image_type)}"/></svg>`;
        }
    } else {
        return '';
    }
}
