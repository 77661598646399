const FlexRow = {
  display: 'flex',
  flexDirection: 'row'
}

const FlexColumn = {
  display: 'flex',
  flexDirection: 'column'
}

const FlexStartRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
}

const FlexStartColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}

const FlexEndRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}

const FlexEndColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end'
}

const FlexCenterRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}

const FlexCenterColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const FlexSpaceBetweenRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}

const FlexSpaceBetweenColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

const FlexSpaceAroundRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around'
}

const FlexSpaceAroundColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
}

const Unset = {
  boxShadow: 'none',
  border: 'unset',
  outline: 'unset'
}

export const FLEX_CONTAINER = {
  FlexRow,
  FlexColumn,
  FlexStartRow,
  FlexStartColumn,
  FlexEndRow,
  FlexEndColumn,
  FlexCenterRow,
  FlexCenterColumn,
  FlexSpaceBetweenRow,
  FlexSpaceBetweenColumn,
  FlexSpaceAroundRow,
  FlexSpaceAroundColumn,
  Unset
}
