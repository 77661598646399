import { useState } from 'react'
import {
  Button, Input, InputGroup, InputRightElement, Stack, Text
} from '@chakra-ui/react'
import { PasswordIconLock, PasswordIconUnlock } from 'assets/icons'
import { COLORS } from 'utils/styles'

export const PasswordInput = ({ placeholder, register, error }) => {
  const [show, setSow] = useState(false)

  return (
    <Stack spacing="10px">
      <InputGroup>
        <Input
          autoComplete="off"
          isInvalid={error}
          _focus={{
            boxShadow: error ? `0 0 0 1px ${COLORS.red}` : 'unset',
            border: `1px solid ${error ? COLORS.red : COLORS.gray}`
          }}
          color={error && COLORS.red}
          errorBorderColor={COLORS.red}
          {...register}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
        />
        <InputRightElement width="4.5rem">
          <Button
            _active="unset"
            _hover="unset"
            backgroundColor="unset"
            onClick={() => setSow(!show)}
          >
            {show ? <PasswordIconUnlock /> : <PasswordIconLock />}
          </Button>
        </InputRightElement>
      </InputGroup>

      <Text fontSize="14px" color={COLORS.red}>
        {error && error.message}
      </Text>
    </Stack>
  )
}
