import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Stack } from '@chakra-ui/react'
import { DatingIcon, FriendsIcon, ProfessionalIcon } from 'assets/icons'
import { SingleButton } from 'common/Buttons'
import { LoadingSpinier } from 'common/Loading'
import { handleModalTitle } from 'store'
import { VERIFY_PAGE } from 'utils/constants'

import { ProfilesSection } from 'components/UserSection/MyProfiles/ProfilesSection'

import { EventSection } from './EventSection'

export const MyProfiles = ({
  handleSelectUser, handleLogOut, isLoading, userProfile
}) => {
  const dispatch = useDispatch()

  let Friends = []
  let Dating = []
  let Professional = []

  if (userProfile) {
    userProfile.forEach((item) => {
      if (item.profile_type === 'friends') {
        Friends.push(item)
      }
      if (item.profile_type === 'dating') {
        Dating.push(item)
      }
      if (item.profile_type === 'professional') {
        Professional.push(item)
      }
    })
  }

  if (window.location.href.includes('/?id=')) {
    document.querySelector('svg[data-name="arrowLeft"]')?.parentNode.click()
  }

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.MyProfiles))
  }, [])

  if (isLoading) {
    return <LoadingSpinier />
  }

  return (
    <Stack
      width="100%"
      sx={{
        '@media (max-width: 480px)': {
          gap: '1.7em'
        }
      }}
      spacing="1em"
      direction="column"
    >
      <ProfilesSection
        icon={<FriendsIcon />}
        array={Friends}
        title="Personal"
        handleSelectUser={handleSelectUser}
      />
      <ProfilesSection
        icon={<DatingIcon />}
        array={Dating}
        title="Dating"
        handleSelectUser={handleSelectUser}
        isPending
      />
      <ProfilesSection
        icon={<ProfessionalIcon />}
        array={Professional}
        title="Professional"
        handleSelectUser={handleSelectUser}
        isPending
      />
      <EventSection isPending />
      <Box position="absolute" h="100%" w="100%" sx={{ marginLeft: '-65px !important' }} zIndex={-3} top={0} bgColor="#c2c3c5" />
      <Stack position="relative">
        <SingleButton name="log-out" click={handleLogOut} isLoading={isLoading} text="Log Out" />
      </Stack>
    </Stack>
  )
}
