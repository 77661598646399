import tplSpinner from "templates/spinner.js";
import { _converse, api, converse } from "@converse/headless/core.js";
import { html } from 'lit';

export default (el) => {
    const o = el.model.toJSON();

    // NOTE: according to 'src\headless\shared\connection\api.js: L49' if reconnect fails Converse will
    // attempt to reconnect every two seconds, alternating between BOSH and
    // Websocket if URLs for both were provided. That is why removing login screen is sufficient
    return html`<div class="box-flyout" style="position:absolute;top:0;right:0;height:auto"><div id="controlbox-panes" class="controlbox-panes"><div class="controlbox-pane">${o.connected
                        ? html`<converse-user-profile></converse-user-profile><converse-headlines-feeds-list class="controlbox-section"></converse-headlines-feeds-list><div id="chatrooms" class="controlbox-section"><converse-rooms-list></converse-rooms-list></div>${ api.settings.get("authentication") === _converse.ANONYMOUS ? '' :
                                html`<div id="converse-roster" class="controlbox-section"><converse-roster></converse-roster></div>`
                            }`
                        : (api.utils.internal_data.ui.connection.status === Strophe.Status.AUTHFAIL) ? 
                        html`<div style="text-align:center;line-height:70vh"><p>${api.utils.internal_data.ui.connection.message}</p></div>` :
                        tplSpinner()
                    }</div></div></div>`
};
