import { useState } from 'react'
import {
  Center,
  Divider,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  Textarea,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPingTest, getFileTest, usersSelectors, getUserProfile, clearTestData
} from 'store'
import { setDataTest } from 'store/users/axios'

export const SecretDebugger = ({ isOpen, onClose, onOpen }) => {
  const dispatch = useDispatch()
  const [dataQuote, setDataQuote] = useState('')
  const [profileName, setProfileName] = useState('')
  const [isDataTestClicked, setIsDataTestClicked] = useState(false)
  const {
    activeProfile,
    pingStatus,
    fileStatus,
    dataStatus,
    quoteStatus,
    testImage,
    serverName,
    pingTestRes,
    fileTestRes,
    dataTestRes,
    quoteTestRes
  } = useSelector(usersSelectors)
  const handleClose = () => {
    dispatch(clearTestData())
    setProfileName('')
    setDataQuote('')
    setIsDataTestClicked(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Debugger</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Tests */}
          <Flex
            gap="67%"
            sx={{
              '@media (max-width: 600px)': {
                gap: '62%'
              }
            }}
          >
            <Text fontSize="25px" fontWeight={400}>
              Test 1
            </Text>
            <Center>
              <Text fontSize="16px">
                {pingTestRes === null ? null : pingTestRes + 'ms'}
              </Text>
            </Center>
          </Flex>
          <Stack padding={3}>
            <Flex
              gap="45%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '35%'
                }
              }}
            >
              <Text>Server Name</Text>
              <Text>{serverName || ''}</Text>
            </Flex>
            <Flex
              gap="62%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '56%'
                }
              }}
            >
              <Text>Status</Text>
              {pingStatus === 'OK'
                ? <Text color="green">{pingStatus}</Text>
                : <Text color="red">{pingStatus}</Text> }
            </Flex>
            <Button colorScheme="yellow" onClick={() => dispatch(getPingTest())}>
              Start Test
            </Button>
          </Stack>
          <Divider
            borderColor="black"
          />

          <Flex
            gap="67%"
            sx={{
              '@media (max-width: 600px)': {
                gap: '62%'
              }
            }}
          >
            <Text fontSize="25px" fontWeight={400}>
              Test 2
            </Text>
            <Center>
              <Text fontSize="16px">
                {fileTestRes === null ? null : fileTestRes + 'ms' }
              </Text>
            </Center>
          </Flex>
          <Stack padding={3}>
            <Flex
              gap="51%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '43%'
                }
              }}
            >
              <Text>Message</Text>
              <Text>{testImage ? testImage?.msg : 'none'}</Text>
            </Flex>
            <Flex
              gap="59%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '53%'
                }
              }}
            >
              <Text>Image</Text>
              <Image src={testImage?.img} w="50px" h="50px" />
            </Flex>
            <Flex
              gap="62%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '56%'
                }
              }}
            >
              <Text>Status</Text>
              {fileStatus === 'OK'
                ? <Text color="green">{fileStatus}</Text>
                : <Text color="red">{fileStatus}</Text> }
            </Flex>
            <Button
              colorScheme="yellow"
              onClick={() => {
                dispatch(getFileTest())
              }}
            >
              Start Test
            </Button>
          </Stack>
          <Divider
            borderColor="black"
          />

          <Flex
            gap="67%"
            sx={{
              '@media (max-width: 600px)': {
                gap: '62%'
              }
            }}
          >
            <Text fontSize="25px" fontWeight={400}>
              Test 3
            </Text>
            <Center>
              <Text fontSize="16px">
                {dataTestRes === null && isDataTestClicked === false ? null : dataTestRes + 'ms'}
              </Text>
            </Center>
          </Flex>
          <Stack padding={3}>
            <Flex
              gap="60%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '55%'
                }
              }}
            >
              <Text>Data</Text>
              <Text isTruncated>{profileName || ''}</Text>
            </Flex>
            <Flex
              gap="62%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '56%'
                }
              }}
            >
              <Text>Status</Text>
              {dataStatus === 'OK'
                ? <Text color="green">{dataStatus && isDataTestClicked === true ? dataStatus : null}</Text>
                : <Text color="red">{dataStatus && isDataTestClicked === true ? dataStatus : null}</Text> }
            </Flex>
            <Button
              colorScheme="yellow"
              onClick={() => {
                dispatch(getUserProfile())
                setProfileName(activeProfile?.full_name)
                setIsDataTestClicked(true)
              }}
            >
              Start Test
            </Button>
          </Stack>
          <Divider
            borderColor="black"
          />

          <Flex
            gap="67%"
            sx={{
              '@media (max-width: 600px)': {
                gap: '62%'
              }
            }}
          >
            <Text fontSize="25px" fontWeight={400}>
              Test 4
            </Text>
            <Center>
              <Text fontSize="16px">
                {quoteTestRes === null ? null : quoteTestRes + 'ms'}
              </Text>
            </Center>
          </Flex>
          <Stack padding={3}>
            <Flex
              gap="32%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '29%'
                }
              }}
            >
              <Text>Previous Data</Text>
              <Text isTruncated>{activeProfile?.personal_quote}</Text>
            </Flex>
            <Flex gap="100px">
              <Text>Input</Text>
              <Textarea onChange={(e) => setDataQuote(e.target.value)} />
            </Flex>
            <Flex
              gap="62%"
              sx={{
                '@media (max-width: 600px)': {
                  gap: '56%'
                }
              }}
            >
              <Text>Status</Text>
              {quoteStatus === 'OK'
                ? <Text color="green">{quoteStatus}</Text>
                : <Text color="red">{quoteStatus}</Text> }
            </Flex>
            <Button colorScheme="yellow" onClick={() => dispatch(setDataTest(dataQuote))}>
              Start Test
            </Button>
          </Stack>
        </ModalBody>

        {/* <ModalFooter>
          <Button colorScheme="yellow" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  )
}
