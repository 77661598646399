import React from 'react'

export const DatingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0_217_3698)">
      <path
        fill="gold"
        d="M12.689 10.244c-2.28 0-3.922 1.956-3.922 4.257 0 5.032 6.048 8.689 7.363 9.425.159.088.35.09.511.005.394-.21 1.193-.668 2.124-1.349.041-.03.09-.046.14-.046h4.015a.538.538 0 00.426-.862l-1.38-1.832a.238.238 0 01.001-.29c1.158-1.477 2.033-3.28 2.033-5.35a3.95 3.95 0 00-3.943-3.957c-1.341 0-2.525.671-3.238 1.697a.534.534 0 01-.882 0 3.935 3.935 0 00-3.237-1.697h-.011zm6.415 8.89H13.81a.705.705 0 01-.703-.706c0-.39.315-.706.703-.706h5.294c.388 0 .703.316.703.706 0 .39-.315.706-.703.706zm1.136-4.03c.388 0 .703.316.703.707 0 .39-.315.706-.703.706h-7.566a.705.705 0 01-.703-.707c0-.39.315-.706.703-.706h7.566z"
      />
      <path
        fill="gold"
        d="M8.827 10.565a5.125 5.125 0 013.862-1.733h.011c.536 0 1.061.08 1.563.234.953-1.41 1.644-3.084 1.644-4.928A4.125 4.125 0 0011.789.006 4.11 4.11 0 008.387 1.81a.532.532 0 01-.879 0A4.11 4.11 0 004.107.006C1.72 0 0 2.044 0 4.451H0c0 2.323 1.258 4.382 2.78 6.023a.24.24 0 01.014.307l-1.302 1.727a.538.538 0 00.427.862H6.12c.048 0 .094.014.134.041.434.295.817.535 1.118.714.08-1.341.59-2.594 1.454-3.56z"
      />
      <rect
        width="9.345"
        height="1.487"
        x="11.682"
        y="15.08"
        fill="#060606"
        rx="0.743"
      />
      <rect
        width="7.009"
        height="1.487"
        x="12.956"
        y="17.628"
        fill="#060606"
        rx="0.743"
      />
    </g>
    <defs>
      <clipPath id="clip0_217_3698">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
)
