import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes
} from 'react-router-dom'
import { initAxiosInterceptors } from 'configs/axios'
import { store } from 'store'

import { App } from './App'

import './index.css'
import './style/index.scss'

initAxiosInterceptors()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

if (process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION') {
  root.render(
    <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </HashRouter>
    </Provider>
  )
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}
