import styled from 'styled-components/macro'

export const StyledNotification = styled.div`
  min-width: 200px;
  width: 300px;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 9999;

  @media (max-width: 480px) {
    width: 100%
  }
`

export const StyledNotificationEditProfile = styled.div`
  min-width: 200px;
  width: 300px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9999;

  @media (max-width: 480px) {
    width: 100%
  }
`
