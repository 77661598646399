import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import {
  setIsPictureUploaded,
  setCroppedPicture
} from 'store'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export const CropPicture = ({ isPictureUploaded, setCloseCropModal, cropFor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const imageRef = useRef()
  const [crop, setCrop] = useState('')

  const [completeCrop, setCompleteCrop] = useState()
  const [imgMaxWidth, setImgMaxWidth] = useState('80vw')
  const [imgMaxHeight, setImgMaxHeight] = useState('80vh')

  const handleCloseButton = () => {
    dispatch(setIsPictureUploaded(''))
    setCloseCropModal(true)
    onClose()
  }

  const imageCrop = (e) => {
    const canvas = document.createElement('canvas')

    let image = imageRef.current
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const originWidth = e.width * scaleX
    const originHeight = e.height * scaleY
    let targetWidth = originWidth
    let targetHeight = originHeight

    if (cropFor === 'cp') {
      const maxWidth = 480 * 4
      const maxHeight = 110 * 4

      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
          targetWidth = maxWidth
          targetHeight = Math.round(maxWidth * (originHeight / originWidth))
        } else {
          targetHeight = maxHeight
          targetWidth = Math.round(maxHeight * (originWidth / originHeight))
        }
      }

      canvas.width = targetWidth
      canvas.height = targetHeight
    } else {
      const maxWidth = 500
      const maxHeight = 500 / (16 / 10)

      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
          targetWidth = maxWidth
          targetHeight = Math.round(maxWidth * (originHeight / originWidth))
        } else {
          targetHeight = maxHeight
          targetWidth = Math.round(maxHeight * (originWidth / originHeight))
        }
      }

      const minSize = Math.min(targetWidth, targetHeight)

      canvas.width = minSize
      canvas.height = minSize
    }

    const ctx = canvas.getContext('2d')
    ctx.drawImage(
      image,
      e.x * scaleX,
      e.y * scaleY,
      e.width * scaleX,
      e.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    )

    canvas.toBlob((blob) => {
      if (blob) {
        setCompleteCrop(blob)
      } else {
        console.error('Failed to create Blob from the canvas')
      }
    }, 'image/png', 1)
  }

  const handleUpload = () => {
    dispatch(setCroppedPicture(completeCrop))
    handleCloseButton()
  }

  useEffect(() => {
    if (isPictureUploaded !== '') {
      setCloseCropModal(false)

      if (crop !== '') {
        setCrop('')
      }

      onOpen()
    }
  }, [isPictureUploaded])

  useEffect(() => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const calculatedWidth = (screenWidth * 0.6) + 'px'
    const calculatedHeight = (screenHeight * 0.6) + 'px'
    setImgMaxWidth(calculatedWidth)
    setImgMaxHeight(calculatedHeight)
  }, [window.innerWidth, window.innerHeight])

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => handleCloseButton()}
    >
      <ModalOverlay />
      <ModalContent
        css={{
          maxWidth: '80vw',
          width: 'auto'
        }}
      >
        <ModalCloseButton />
        <ModalHeader>
          {cropFor === 'cp' ? 'Rectangular ' : 'Square '}
          images are preferred
        </ModalHeader>

        <ModalBody>
          <center>
            <ReactCrop
              crop={crop}
              onChange={(e) => setCrop(e)}
              onComplete={(e) => imageCrop(e)}
              aspect={cropFor === 'cp' ? 4 : 1}
            >
              <img
                onLoad={() => {
                  const { naturalWidth, naturalHeight } = imageRef.current
                  let initialWidth = 0
                  let initialHeight = 0
                  let initialX = 0
                  let initialY = 0

                  if (cropFor === 'cp') {
                    const aspectRatio = 16 / 4

                    initialWidth = naturalWidth
                    initialHeight = initialWidth / aspectRatio

                    initialX = (naturalWidth - initialWidth) / 2
                    initialY = (naturalHeight - initialHeight) / 2
                  } else {
                    const maxCropSize = Math.min(naturalWidth, naturalHeight)

                    // Calculate initial crop dimensions with a square aspect ratio
                    initialWidth = maxCropSize
                    initialHeight = maxCropSize
                    initialX = (naturalWidth - maxCropSize) / 2
                    initialY = (naturalHeight - maxCropSize) / 2
                  }

                  setCrop({
                    unit: '%',
                    aspect: cropFor === 'cp' ? 4 : 1,
                    width: (initialWidth / naturalWidth) * 100,
                    height: (initialHeight / naturalHeight) * 100,
                    x: (initialX / naturalWidth) * 100,
                    y: (initialY / naturalHeight) * 100
                  })
                }}
                ref={imageRef}
                alt="Crop"
                src={isPictureUploaded}
                style={{
                  maxWidth: imgMaxWidth,
                  maxHeight: imgMaxHeight,
                  display: 'block',
                  margin: '0 auto'
                }}
              />
            </ReactCrop>
          </center>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="yellow"
            name="crop"
            mr={3}
            onClick={() => handleUpload()}
          >
            Crop
          </Button>

          <Button
            mr={3}
            onClick={() => handleCloseButton()}
            name="cancel-crop"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
