import { useEffect, useRef, useMemo } from 'react'
import { Marker, useMap } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import L from 'leaflet'
import {
  authSelectors,
  deleteProfileItem,
  setIsSignIn,
  setMarkerUpdate,
  setProfileItemId,
  usersSelectors,
  chatCredentialsSelectors
} from 'store'
import { MAX_ZOOM_LEVEL, SUCCEEDED, LOCATION_TYPE } from 'utils/constants'
import {
  createMarkerColor, statusMarkerColor, hideCounter, showCounter
} from 'utils/helpers'
import { v4 as uuid } from 'uuid'

import { MarkerPopup } from '../MarkerPopup'

export const MarkerItem = ({
  item, setShowMarkerCount, storedId, setStoredId, geoLoc, isMyProfile
}) => {
  const { getProfileItemStatus, activeProfile } = useSelector(usersSelectors)
  const { isSignIn } = useSelector(authSelectors)
  const { signInStatus } = useSelector(authSelectors)
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)
  // bug is present if using storeID selectors to store id param value
  // const { storeId } = useSelector(markersSelectors)
  const map = useMap()

  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()

  const markerRef = useRef(null)

  const liveLocCoord = [xmppLiveLocation?.lat, xmppLiveLocation?.lng]
  const validDate = dayjs(item?.last_accessed)
  const iconMarker = createMarkerColor(validDate)
  const statusColor = statusMarkerColor(validDate)
  const [lng, lat] = item?.coordinates
  const id = item?.id
  const idParam = searchParams.get('id')

  // const coordinate = {
  //   lat: searchParams.get('lat'),
  //   lng: searchParams.get('lng')
  // }

  const handleClosePopup = () => {
    const mapCenter = map.getCenter()
    const clickedZoom = map.getZoom()

    localStorage.setItem('lat', mapCenter.lat)
    localStorage.setItem('lng', mapCenter.lng)
    localStorage.setItem('zoom', clickedZoom)

    setSearchParams({
      lat: mapCenter.lat.toFixed(6),
      lng: mapCenter.lng.toFixed(6),
      zoom: clickedZoom
    })

    setStoredId(null)
    localStorage.removeItem('id')

    // setTimeout(() => {
    //   const markerCountBox = document.querySelector('.marker-count')
    //   // setShowMarkerCount('inline-block')
    // }, 1000)
  }

  const handleMarkerCloseOnDrag = () => {
    if (localStorage.getItem('id') !== null) {
      map.off('dragend', handleMarkerCloseOnDrag)
      showCounter()

      handleClosePopup()
    }
  }

  // Popup opens runs after closing
  useEffect(() => {
    // console.log('test94')
    // if (+coordinate.lat === lat && +coordinate.lng === lng) {
    //   const marker = markerRef.current
    //   if (marker) {
    //     dispatch(setProfileItemId(item.id))
    //     marker.openPopup()
    //   }
    // } else
    if (+idParam === id) {
      const marker = markerRef.current
      if (marker) {
        dispatch(setProfileItemId(item.id))
        marker.openPopup()
      }
    }
  }, [searchParams])
  // Same as above
  useEffect(() => {
    // console.log('test95')
    // if (+coordinate.lat === lat && +coordinate.lng === lng) {
    //   console.log('1')
    //   const marker = markerRef.current
    //   if (marker) {
    //     dispatch(setProfileItemId(item.id))
    //     marker.openPopup()
    //   }
    // } else
    if (+idParam === id) {
      const marker = markerRef.current
      if (marker) {
        dispatch(setProfileItemId(item.id))
        marker.openPopup()
        setTimeout(() => dispatch(setIsSignIn(false)), 1000)
      }
    } else if (+storedId === id) {
      const marker = markerRef.current
      if (marker) {
        dispatch(setProfileItemId(item.id))
        // eslint-disable-next-line no-underscore-dangle
        map.setView(marker._latlng, MAX_ZOOM_LEVEL ?? 16, { animate: true })
        setTimeout(() => {
          setSearchParams({ id })
          marker.openPopup()
        }, 410)
      }
    } else if (isSignIn === true && activeProfile?.id === id && +idParam !== id && localStorage.getItem('id') === null) {
      const marker = markerRef.current
      if (marker) {
        // eslint-disable-next-line no-underscore-dangle
        map.setView(marker._latlng, MAX_ZOOM_LEVEL ?? 16)
        setTimeout(() => dispatch(setIsSignIn(false)), 1500)
      }
    }
  }, [])

  return (
    <Marker
      zIndexOffset={-99999}
      eventHandlers={{
        click: (e) => {
          dispatch(setProfileItemId(null))

          map.setView(e.latlng, map.getZoom(), { animate: true })

          setTimeout(() => {
            setSearchParams({ id })
          }, 410)
        },
        popupclose: () => {
          // zoom control, filter container and marker counter
          showCounter()
          // So the marker counter will update
          setTimeout(() => {
            dispatch(setMarkerUpdate(true))
          }, 500)

          handleClosePopup()
        },
        popupopen: () => {
          // zoom control, filter container and marker counter
          hideCounter()

          map.on('dragend', handleMarkerCloseOnDrag)
          // setShowMarkerCount('none')
        }
      }}
      key={uuid()}
      position={
        geoLoc
        && Number.isNaN(xmppLiveLocation.lat) === false
        && item.location_type[LOCATION_TYPE.LiveLocation] === 0
          ? liveLocCoord
          : item?.coordinates
      }
      icon={iconMarker}
      ref={markerRef}
      title={item?.id}
    >
      <MarkerPopup
        getProfileItemStatus={getProfileItemStatus}
        iconMarker={statusColor}
        isMyProfile={geoLoc || isMyProfile ? isMyProfile : item.is_my_profile}
      />
    </Marker>
  )
}
