export const MinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#060606"
      d="M3 12.75h18a.75.75 0 100-1.5H3a.75.75 0 100 1.5z"
    />
  </svg>
)
