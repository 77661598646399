import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  clearNotifs, clearUser, getMarkers, getNotificationMessage
} from 'store'
import { authApi } from 'store/auth/api'
import { REJECTED, SUCCEEDED } from 'utils/constants'
import { removeStorage, setStorage } from 'utils/helpers'
import { LogoutFromChat } from 'store/chat/services'

export const signIn = createAsyncThunk(
  'auth/token',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await authApi.sigIn(data)
      setStorage('credentials', response.data)
      return response
    } catch (error) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Email Address or Password is incorrect',
          isEditProfile: true
        })
      )
      return rejectWithValue(error.message)
    }
  }
)

export const signUp = createAsyncThunk(
  'users/register',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await authApi.sigUp(data)
      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'If that ID is registered, you should receive an email soon',
          isEditProfile: true
        })
      )
      return response
    } catch (error) {
      if (error.status === 422) {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: error.data.message,
            isEditProfile: true
          })
        )
      } else if (error.status === 403) {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: error.data.message,
            isEditProfile: true
          })
        )
      } else {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'Oops, something went wrong with the server. Please try again later',
            isEditProfile: true
          })
        )
      }

      return rejectWithValue(error.message)
    }
  }
)

export const emailVerify = createAsyncThunk(
  'email/verify',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await authApi.emailVerify(data)
      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Email Verified',
          isEditProfile: true
        })
      )
      return response
    } catch (error) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Email verification error',
          isEditProfile: true
        })
      )
      return rejectWithValue(error.message)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'users/forgot-password',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await authApi.forgotPassword(data)
      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'If that ID is registered, you should receive an email soon',
          isEditProfile: true
        })
      )
      return response
    } catch (error) {
      if (error.status === 422) {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'Forgot password error',
            isEditProfile: true
          })
        )
      }
      return rejectWithValue(error.message)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'users/reset-password',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await authApi.resetPassword(data)
      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Saved',
          isEditProfile: true
        })
      )
      return response
    } catch (error) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Reset password error',
          isEditProfile: true
        })
      )
      return rejectWithValue(error.message)
    }
  }
)

export const logOut = createAsyncThunk(
  'log-out',
  async (showNotifMsg, { rejectWithValue, dispatch }) => {
    try {
      removeStorage('credentials')
      removeStorage('lat')
      removeStorage('lng')
      dispatch(clearUser())
      dispatch(clearNotifs())
      dispatch(LogoutFromChat())
      dispatch(getMarkers({ isAuth: false }))

      if (showNotifMsg) {
        return dispatch(
          getNotificationMessage({
            type: SUCCEEDED,
            message: 'Signed out',
            isEditProfile: true
          })
        )
      }

      return ''
    } catch (error) {
      if (error.status === 401) {
        await dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'Sign out error',
            isEditProfile: true
          })
        )
        removeStorage('access-token')
      }
      return rejectWithValue(error.message)
    }
  }
)

export const getGuestNotification = createAsyncThunk(
  'guest-notification',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authApi.guestNotification()

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
