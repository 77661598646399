import 'shared/avatar/avatar.js';
import 'shared/chat/unfurl.js';
import { __ } from 'i18n';
import { html } from "lit";
import { shouldRenderMediaFromURL } from '@converse/headless/utils/url.js';


export default (el, o) => {
    const i18n_new_messages = __('New messages');
    // const is_followup = el.model.isFollowup();
    const showFriendMarker = (jid) => {
        const event = new CustomEvent('custom-chat-show_on_map', {
           detail: {
              jid: jid,
           }});
        window.dispatchEvent(event);
    }
    const avatar_data = (o.sender === 'me') ? el.model.vcard?.attributes : el.model.contact?.attributes;
    const formatDatetime = (isoDate) => {
        const date = new Date(isoDate);
        const dateOptions = { day: 'numeric', month: 'short' };
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
        
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        let formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
        formattedTime = formattedTime.replace('AM', 'am').replace('PM', 'pm');

        return `${formattedDate}, ${formattedTime}`;
    }
    const formattedDatetime = formatDatetime(o.time)
    const alwaysShowAvatar = 'chat-msg__body--' + el.getExtraMessageClasses() ? 'with-avatar' : 'with-avatar';
    const is_groupchat_message = (el.model.get('type') === 'groupchat');

    return html`${ o.is_first_unread ? html`<div class="message separator"><hr class="separator"><span class="separator-text">${ i18n_new_messages }</span></div>` : '' }<div class="message chat-msg ${ alwaysShowAvatar }" data-isodate="${o.time}" data-msgid="${o.msgid}" data-from="${o.from}" data-encrypted="${o.is_encrypted}" style="flex-direction:${o.sender === 'me' ? 'row-reverse' : 'row'/* o.sender = 'me';'them' */}"><a id="${o.msgid}"></a> <a class="show-msg-author-modal" @click="${(ev) => {
                    ev.stopPropagation()
                    showFriendMarker(el.model.attributes.from)
                }
            }"><converse-avatar .data="${avatar_data}" nonce="${el.model.vcard?.get('vcard_updated')}" height="55" width="55"></converse-avatar></a><div class="${o.sender === 'me' ? 'textBubbleArrowMe' : 'textBubbleArrowThem'}"></div><div class="chat-msg__content chat-msg__content--${o.sender} ${o.is_me_message ? 'chat-msg__content--action' : ''}" style="margin:${o.sender === 'me' ? '0 20px 0 0' : '0 0 0 20px'}"><div class="chat-msg__body chat-msg__body--${o.message_type} ${o.received ? 'chat-msg__body--received' : '' } ${o.is_delayed ? 'chat-msg__body--delayed' : '' }"><div class="chat-msg__message" style="align-content:${o.sender === 'me' ? 'flex-end' : 'flex-start' /* 
                                                                        messages from "them" are right-aligned 
                                                                        messages from "me" are left-aligned
                                                                        */};flex-wrap:wrap">${ o.is_retracted ? el.renderRetraction() : el.renderMessageText() }</div></div><span class="chat-msg__heading">${ o.hats.map(h => html`<span class="badge badge-secondary">${h.title}</span>`) } ${ o.is_encrypted ? html`<converse-icon class="fa fa-lock" size="1.1em"></converse-icon>` : '' } ${ (el.model.get('received') && !el.model.isMeCommand() && !is_groupchat_message) 
                        ? html`<converse-icon style="float:${o.sender === 'me' ? 'right' : 'left'};width:11px;height:11px;margin-top:2px;fill:var(--message-receipt-color)" class="fa fa-check chat-msg__receipt"></converse-icon>`
                        : ''
                    } <time timestamp="${o.edited || o.time}" class="chat-msg__time" style="float:${o.sender === 'me' ? 'right' : 'left'}">${formattedDatetime}</time> </span>${ el.model.get('ogp_metadata')?.map(m => {
                    if (el.model.get('hide_url_previews') === true) {
                        return '';
                    }
                    if (!shouldRenderMediaFromURL(m['og:image'], 'image')) {
                        return '';
                    }
                    return html`<converse-message-unfurl @animationend="${el.onUnfurlAnimationEnd}" class="${el.model.get('url_preview_transition')}" jid="${el.chatbox?.get('jid')}" description="${m['og:description'] || ''}" title="${m['og:title'] || ''}" image="${m['og:image'] || ''}" url="${m['og:url'] || ''}"></converse-message-unfurl>` }) }</div></div>`;
}
