import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { getNotificationMessage, setMarkerUpdate, forceLogout } from 'store'
import { getParsedData } from 'utils/helpers'
import { REJECTED } from 'utils/constants'
import { markersApi } from './api'

export const getMarkers = createAsyncThunk(
  'markers/getMarkers',
  async (body, { rejectWithValue, dispatch }) => {
    const { filter = [], isAuth } = body

    try {
      const response = await markersApi.getMarkers({ filter, isAuth })

      if (isAuth) {
        try {
          response.data.data = getParsedData(response.data.data)
        } catch (err) {
          //
        }
      }

      let stringCoordCount = 0
      response.data.data.forEach((item) => {
        if (typeof item.coordinates[0] === 'string' || typeof item.coordinates[1] === 'string') {
          stringCoordCount += 1
        }
      })

      if (stringCoordCount > 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'One of the marker coordinates value has wrong format.',
          isEditProfile: false
        }))
      }

      await dispatch(setMarkerUpdate(true))

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

// For getting friends markers
export const getFriendMarkers = createAsyncThunk(
  'markers/getFriendMarkers',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth } = body
    try {
      const response = await markersApi.getFriendMarkers({ isAuth })
      await dispatch(setMarkerUpdate(true))
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

// Live Marker Update
export const liveMarkerUpdate = createAsyncThunk(
  'markers/liveMarkerUpdate',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth, northeast, southwest } = body

    try {
      const response = await markersApi.liveMarkerUpdate({ isAuth, northeast, southwest })

      if (isAuth) {
        try {
          response.data.data = getParsedData(response.data.data)
        } catch (err) {
          //
        }
      }

      await dispatch(setMarkerUpdate(true))
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

// Static Marker Update
export const staticMarkerUpdate = createAsyncThunk(
  'markers/staticMarkerUpdate',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth, northeast, southwest } = body
    try {
      const response = await markersApi.staticMarkerUpdate({ isAuth, northeast, southwest })

      if (isAuth) {
        try {
          response.data.data = getParsedData(response.data.data)
        } catch (err) {
          //
        }
      }

      await dispatch(setMarkerUpdate(true))
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)
