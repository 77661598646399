import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { deleteUserProfile, usersSelectors, setCloseResetProfileModal } from 'store'
import { PENDING, SUCCEEDED } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { clearXmppPushNotification } from 'store/chat/slice'

export const ResetProfile = ({ id, deleteProfileStatus }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    userProfile
  } = useSelector(usersSelectors)

  const finalRef = useRef(null)

  const dispatch = useDispatch()

  const resetProfile = () => {
    const item = userProfile.find((e) => id === e.id)
    if (item?.last_accessed === null) {
      onClose()
    } else {
      dispatch(clearXmppPushNotification())
      dispatch(deleteUserProfile(id))
    }
  }

  useEffect(() => {
    if (deleteProfileStatus === SUCCEEDED) {
      onClose()
    }
  }, [deleteProfileStatus])

  return (
    <Box width="100%">
      <Text
        margin="auto"
        width="max-content"
        cursor="pointer"
        mt="40px"
        color={COLORS.gray}
        onClick={() => {
          const item = userProfile.find((e) => id === e.id)
          if (item?.last_accessed !== null) {
            // allow reset a profile that has been in use
            // and ignore resetting new created profile
            dispatch(setCloseResetProfileModal(false))
            onOpen()
          }
        }}
        data-name="resetProfile"
      >
        Reset Profile
      </Text>

      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          dispatch(setCloseResetProfileModal(true))
          onClose()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Delete Profile</ModalHeader>

          <ModalBody>Are you sure you want to delete this profile?</ModalBody>

          <ModalFooter>
            <Button
              isLoading={deleteProfileStatus === PENDING}
              colorScheme="yellow"
              name="reset-yes"
              mr={3}
              onClick={resetProfile}
            >
              Yes
            </Button>
            <Button
              mr={3}
              onClick={() => {
                dispatch(setCloseResetProfileModal(true))
                onClose()
              }}
              name="reset-no"
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
