/* eslint-disable max-len */
const debugLogAllEventsPlugin = {
  initialize() {
    const { _converse } = this
    const { api, log } = _converse
    _converse.log.log('The log-all-events-plugin plugin is being initialized')
    // HOOKS-conversejs.org/10.1.4
    _converse.api.listen.on('afterFileUploaded', (arg) => { console.log(''); console.log(arg) })
    _converse.api.listen.on('beforeFetchLoginCredentials', (arg) => { console.log('beforeFetchLoginCredentials'); console.log(arg) })
    _converse.api.listen.on('beforeFileUpload', (arg) => { console.log('beforeFileUpload'); console.log(arg) })
    _converse.api.listen.on('confirmDirectMUCInvitation', (arg) => { console.log('confirmDirectMUCInvitation'); console.log(arg) })
    _converse.api.listen.on('constructedMUCPresence', (arg) => { console.log('constructedMUCPresence'); console.log(arg) })
    _converse.api.listen.on('constructedPresence', (arg) => { console.log('constructedPresence'); console.log(arg) })
    _converse.api.listen.on('createMessageStanza', (arg) => { console.log('createMessageStanza'); console.log(arg) })
    _converse.api.listen.on('getHeadingButtons', (arg) => { console.log('getHeadingButtons'); console.log(arg) })
    _converse.api.listen.on('getMessageActionButtons', (arg) => { console.log('getMessageActionButtons'); console.log(arg) })
    _converse.api.listen.on('getOutgoingMessageAttributes', (arg) => { console.log('getOutgoingMessageAttributes'); console.log(arg) })
    _converse.api.listen.on('getToolbarButtons', (arg) => { console.log('getToolbarButtons'); console.log(arg) })
    // _converse.api.listen.on('parseMessage', (arg) => { console.log('parseMessage'); console.log(arg) })
    _converse.api.listen.on('parseMessageForCommands', (arg) => { console.log('parseMessageForCommands'); console.log(arg) })
    _converse.api.listen.on('parseMUCMessage', (arg) => { console.log('parseMUCMessage'); console.log(arg) })
    _converse.api.listen.on('shouldNotifyOfGroupMessage', (arg) => { console.log('shouldNotifyOfGroupMessage'); console.log(arg) })
    //------
    _converse.api.listen.on('addClientFeatures', (arg) => { console.log('addClientFeatures'); console.log(arg) })
    _converse.api.listen.on('afterMessageBodyTransformed', (arg) => { console.log('afterMessageBodyTransformed'); console.log(arg) })
    _converse.api.listen.on('afterMessagesFetched', (arg) => { console.log('afterMessagesFetched'); console.log(arg) })
    _converse.api.listen.on('afterResourceBinding', (arg) => { console.log('afterResourceBinding'); console.log(arg) })
    _converse.api.listen.on('afterTearDown', (arg) => { console.log('afterTearDown'); console.log(arg) })
    _converse.api.listen.on('beforeLogout', (arg) => { console.log('beforeLogout'); console.log(arg) })
    _converse.api.listen.on('beforeMessageBodyTransformed', (arg) => { console.log('beforeMessageBodyTransformed'); console.log(arg) })
    _converse.api.listen.on('beforeResourceBinding', (arg) => { console.log('beforeResourceBinding'); console.log(arg) })
    _converse.api.listen.on('beforeTearDown', (arg) => { console.log('beforeTearDown'); console.log(arg) })
    // _converse.api.listen.on('bookmarksInitialized', (arg) => { console.log('bookmarksInitialized'); console.log(arg) })
    _converse.api.listen.on('cachedRoster', (arg) => { console.log('cachedRoster'); console.log(arg) })
    _converse.api.listen.on('callButtonClicked', (arg) => { console.log('callButtonClicked'); console.log(arg) })
    _converse.api.listen.on('chatBoxBlurred', (arg) => { console.log('chatBoxBlurred'); console.log(arg) })
    _converse.api.listen.on('chatBoxClosed', (arg) => { console.log('chatBoxClosed'); console.log(arg) })
    _converse.api.listen.on('chatBoxesFetched', (arg) => { console.log('chatBoxesFetched'); console.log(arg) })
    _converse.api.listen.on('chatBoxesInitialized', (arg) => { console.log('chatBoxesInitialized'); console.log(arg) })
    _converse.api.listen.on('chatBoxFocused', (arg) => { console.log('chatBoxFocused'); console.log(arg) })
    _converse.api.listen.on('chatBoxInitialized', (arg) => { console.log('chatBoxInitialized'); console.log(arg) })
    _converse.api.listen.on('chatBoxMaximized', (arg) => { console.log('chatBoxMaximized'); console.log(arg) })
    _converse.api.listen.on('chatBoxMinimized', (arg) => { console.log('chatBoxMinimized'); console.log(arg) })
    _converse.api.listen.on('chatBoxScrolledDown', (arg) => { console.log('chatBoxScrolledDown'); console.log(arg) })
    _converse.api.listen.on('chatBoxScrolledUp', (arg) => { console.log('chatBoxScrolledUp'); console.log(arg) })
    _converse.api.listen.on('chatBoxViewInitialized', (arg) => { console.log('chatBoxViewInitialized'); console.log(arg) })
    _converse.api.listen.on('chatBoxViewsInitialized', (arg) => { console.log('chatBoxViewsInitialized'); console.log(arg) })
    _converse.api.listen.on('chatReconnected', (arg) => { console.log('chatReconnected'); console.log(arg) })
    _converse.api.listen.on('chatRoomInitialized', (arg) => { console.log('chatRoomInitialized'); console.log(arg) })
    _converse.api.listen.on('chatRoomMessageInitialized', (arg) => { console.log('chatRoomMessageInitialized'); console.log(arg) })
    _converse.api.listen.on('chatRoomViewInitialized', (arg) => { console.log('chatRoomViewInitialized'); console.log(arg) })
    _converse.api.listen.on('cleanup', (arg) => { console.log('cleanup'); console.log(arg) })
    _converse.api.listen.on('clearSession', (arg) => { console.log('clearSession'); console.log(arg) })
    _converse.api.listen.on('clientConfigInitialized', (arg) => { console.log('clientConfigInitialized'); console.log(arg) })
    _converse.api.listen.on('connected', (arg) => { console.log('connected'); console.log(arg) })
    _converse.api.listen.on('connectionInitialized', (arg) => { console.log('connectionInitialized'); console.log(arg) })
    _converse.api.listen.on('connfeedback', (arg) => { console.log('connfeedback'); console.log(arg) })
    _converse.api.listen.on('contactPresenceChanged', (arg) => { console.log('contactPresenceChanged'); console.log(arg) })
    _converse.api.listen.on('contactRequest', (arg) => { console.log('contactRequest'); console.log(arg) })
    _converse.api.listen.on('controlBoxClosed', (arg) => { console.log('controlBoxClosed'); console.log(arg) })
    _converse.api.listen.on('controlBoxInitialized', (arg) => { console.log('controlBoxInitialized'); console.log(arg) })
    _converse.api.listen.on('controlBoxOpened', (arg) => { console.log('controlBoxOpened'); console.log(arg) })
    // _converse.api.listen.on('discoExtensionFieldDiscovered', (arg) => { console.log('discoExtensionFieldDiscovered'); console.log(arg) })
    _converse.api.listen.on('discoInitialized', (arg) => { console.log('discoInitialized'); console.log(arg) })
    _converse.api.listen.on('disconnected', (arg) => { console.log('disconnected'); console.log(arg) })
    _converse.api.listen.on('enteredNewRoom', (arg) => { console.log('enteredNewRoom'); console.log(arg) })
    _converse.api.listen.on('headlinesBoxViewInitialized', (arg) => { console.log('headlinesBoxViewInitialized'); console.log(arg) })
    _converse.api.listen.on('headlinesFeedInitialized', (arg) => { console.log('headlinesFeedInitialized'); console.log(arg) })
    _converse.api.listen.on('historyPruned', (arg) => { console.log('historyPruned'); console.log(arg) })
    _converse.api.listen.on('initialized', (arg) => { console.log('initialized'); console.log(arg) })
    _converse.api.listen.on('logout', (arg) => { console.log('logout'); console.log(arg) })
    _converse.api.listen.on('MAMResult', (arg) => { console.log('MAMResult'); console.log(arg) })
    _converse.api.listen.on('membersFetched', (arg) => { console.log('membersFetched'); console.log(arg) })
    _converse.api.listen.on('message', (arg) => { console.log('message'); console.log(arg) })
    // _converse.api.listen.on('messageInitialized', (arg) => { console.log('messageInitialized'); console.log(arg) })
    _converse.api.listen.on('messageNotification', (arg) => { console.log('messageNotification'); console.log(arg) })
    _converse.api.listen.on('noResumeableBOSHSession', (arg) => { console.log('noResumeableBOSHSession'); console.log(arg) })
    _converse.api.listen.on('occupantModalInitialized', (arg) => { console.log('occupantModalInitialized'); console.log(arg) })
    _converse.api.listen.on('OMEMOInitialized', (arg) => { console.log('OMEMOInitialized'); console.log(arg) })
    _converse.api.listen.on('pluginsInitialized', (arg) => { console.log('pluginsInitialized'); console.log(arg) })
    _converse.api.listen.on('presencesInitialized', (arg) => { console.log('presencesInitialized'); console.log(arg) })
    _converse.api.listen.on('privateChatsAutoJoined', (arg) => { console.log('privateChatsAutoJoined'); console.log(arg) })
    _converse.api.listen.on('profileModalInitialized', (arg) => { console.log('profileModalInitialized'); console.log(arg) })
    _converse.api.listen.on('reconnected', (arg) => { console.log('reconnected'); console.log(arg) })
    _converse.api.listen.on('registeredGlobalEventHandlers', (arg) => { console.log('registeredGlobalEventHandlers'); console.log(arg) })
    _converse.api.listen.on('renderToolbar', (arg) => { console.log('renderToolbar'); console.log(arg) })
    _converse.api.listen.on('roomInviteSent', (arg) => { console.log('roomInviteSent'); console.log(arg) })
    _converse.api.listen.on('roomsAutoJoined', (arg) => { console.log('roomsAutoJoined'); console.log(arg) })
    _converse.api.listen.on('roster', (arg) => { console.log('roster'); console.log(arg) })
    _converse.api.listen.on('rosterContactInitialized', (arg) => { console.log('rosterContactInitialized'); console.log(arg) })
    _converse.api.listen.on('rosterContactsFetched', (arg) => { console.log('rosterContactsFetched'); console.log(arg) })
    _converse.api.listen.on('rosterInitialized', (arg) => { console.log('rosterInitialized'); console.log(arg) })
    _converse.api.listen.on('rosterPush', (arg) => { console.log('rosterPush'); console.log(arg) })
    _converse.api.listen.on('rosterReadyAfterReconnection', (arg) => { console.log('rosterReadyAfterReconnection'); console.log(arg) })
    _converse.api.listen.on('rosterViewInitialized', (arg) => { console.log('rosterViewInitialized'); console.log(arg) })
    _converse.api.listen.on('send', (arg) => { console.log('send'); console.log(arg) })
    _converse.api.listen.on('sendMessage', (arg) => { console.log('sendMessage'); console.log(arg) })
    // _converse.api.listen.on('serviceDiscovered', (arg) => { console.log('serviceDiscovered'); console.log(arg) })
    _converse.api.listen.on('setUserJID', (arg) => { console.log('setUserJID'); console.log(arg) })
    _converse.api.listen.on('startDiagonalResize', (arg) => { console.log('startDiagonalResize'); console.log(arg) })
    _converse.api.listen.on('startHorizontalResize', (arg) => { console.log('startHorizontalResize'); console.log(arg) })
    _converse.api.listen.on('startVerticalResize', (arg) => { console.log('startVerticalResize'); console.log(arg) })
    _converse.api.listen.on('statusInitialized', (arg) => { console.log('statusInitialized'); console.log(arg) })
    _converse.api.listen.on('streamFeaturesAdded', (arg) => { console.log('streamFeaturesAdded'); console.log(arg) })
    _converse.api.listen.on('streamResumptionFailed', (arg) => { console.log('streamResumptionFailed'); console.log(arg) })
    _converse.api.listen.on('unregisteredGlobalEventHandlers', (arg) => { console.log('unregisteredGlobalEventHandlers'); console.log(arg) })
    _converse.api.listen.on('userDetailsModalInitialized', (arg) => { console.log('userDetailsModalInitialized'); console.log(arg) })
    _converse.api.listen.on('userSessionInitialized', (arg) => { console.log('userSessionInitialized'); console.log(arg) })
    _converse.api.listen.on('VCardsInitialized', (arg) => { console.log('VCardsInitialized'); console.log(arg) })
  }
}

export default debugLogAllEventsPlugin
