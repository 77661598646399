import styled from 'styled-components/macro'

export const StyledMapContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px);

  /* Webkit Browsers (Chromium, Safari) */
  @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    height: calc(100dvh - 100px);
  }
  
  /* Firefox */
  @-moz-document url-prefix() {
    height: calc(100dvh - 100px);
  }
`
