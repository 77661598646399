import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import {
  deleteUser,
  handleModalTitle,
  setIsProfileDeleted,
  updateUserSettings,
  usersSelectors
} from 'store'
import { PENDING, SETTINGS_TYPE, VERIFY_PAGE } from 'utils/constants'
import { COLORS } from 'utils/styles'

import { Email } from 'components/UserSection/Settings/Email'
import { Password } from 'components/UserSection/Settings/Password'
import { Notifications } from './Notifications'

export const Settings = ({
  user,
  isLoading,
  deleteUserStatus
}) => {
  const [settingsSection, setSettingsSection] = useState(SETTINGS_TYPE.Email)
  const { activeProfile } = useSelector(usersSelectors)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const finalRef = useRef(null)

  const dispatch = useDispatch()

  const saveHandler = (data, type) => {
    dispatch(updateUserSettings({
      body: data,
      type
    }))
  }

  const selectPage = () => {
    switch (settingsSection) {
    case SETTINGS_TYPE.Email:
      return (
        <Email isLoading={isLoading} saveHandler={saveHandler} user={user} />
      )
    case SETTINGS_TYPE.Notif:
      return (
        <Notifications isLoading={isLoading} saveHandler={saveHandler} />
      )
    default:
      return <Password isLoading={isLoading} saveHandler={saveHandler} />
    }
  }

  const deleteProfile = () => {
    dispatch(deleteUser(activeProfile))
    dispatch(setIsProfileDeleted(false))
  }

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.Settings))
  }, [])

  return (
    <Stack spacing="32px" direction="column">
      <Stack
        margin="auto"
        sx={{
          gridGap: '50px',
          '@media (max-width: 450px)': {
            gridGap: '10px'
          }
        }}
        direction="row"
      >
        <Box
          cursor="pointer"
          color={settingsSection === SETTINGS_TYPE.Email && COLORS.gold}
          onClick={() => setSettingsSection(SETTINGS_TYPE.Email)}
          data-name="goToEmailSection"
        >
          Email
        </Box>
        <Box
          cursor="pointer"
          color={settingsSection === SETTINGS_TYPE.Notif && COLORS.gold}
          onClick={() => setSettingsSection(SETTINGS_TYPE.Notif)}
          data-name="goToEmailSection"
        >
          Notification
        </Box>
        <Box
          cursor="pointer"
          color={settingsSection === SETTINGS_TYPE.Password && COLORS.gold}
          onClick={() => setSettingsSection(SETTINGS_TYPE.Password)}
          data-name="goToPasswordSection"
        >
          Password
        </Box>
      </Stack>

      <Box>{selectPage()}</Box>

      <center>
        <Box
          position="absolute"
          bottom="33px"
          left="50%"
          transform="translateX(-50%)"
          cursor="pointer"
          sx={{
            '@media (max-height: 550px)': {
              position: 'static',
              width: '100%',
              transform: 'translateX(0)'
            }
          }}
          color={COLORS.gray}
          onClick={() => {
            dispatch(setIsProfileDeleted(true))
            onOpen()
          }}
          data-name="deleteAccount"
        >
          Delete Account
        </Box>
      </center>

      <Modal isCentered finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Delete Account</ModalHeader>

          <ModalBody>Do you want to delete your account and delete all your profiles?</ModalBody>

          <ModalFooter>
            <Button
              isLoading={deleteUserStatus === PENDING}
              colorScheme="yellow"
              mr={3}
              onClick={deleteProfile}
              name="confirmDelete"
            >
              Yes
            </Button>
            <Button
              mr={3}
              onClick={() => {
                dispatch(setIsProfileDeleted(false))
                onClose()
              }}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}
