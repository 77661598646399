import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Text } from '@chakra-ui/react'
import { SingleButton } from 'common/Buttons'
import { emailVerify } from 'store'
import { getUrlParams } from 'utils/helpers'

export const EmailVerifyPage = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      getUrlParams('email', 'token')[0]
      && getUrlParams('email', 'token')[1]
    ) {
      const body = {
        email: getUrlParams('email', 'token')[0],
        token: getUrlParams('email', 'token')[1]
      }
      dispatch(emailVerify(body))
    }
  }, [])

  return (
    <Box h="100vh" w="100%">
      <Stack
        spacing="32px"
        sx={{
          position: 'absolute',
          top: '35%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}
      >
        <Text fontSize="24px" fontWeight={600}>
          Your Email address has been successfully verified!
        </Text>
        <Text fontSize="16px" fontWeight={400}>
          You can now sign in with your new account.
        </Text>
        <SingleButton
          customStyled={{
            width: '320px',
            margin: '100px auto 0',
            fontSize: '20px',
            fontWeight: 400
          }}
          click={() => navigate('/')}
          isLoading={false}
          text="Go to sign in"
        />
      </Stack>
    </Box>
  )
}
