import styled from 'styled-components/macro'

export const StyleMessengerBlock = styled.div`
  position: relative;
`

export const StyleNumUnread = styled.span`
  position: absolute;
  right: -15%;
  top: -35%;
  color: red;
`
