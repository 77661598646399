import {
  store,
  setIsPictureUploaded,
  setIsProfileChanged,
  setCropFor
} from 'store'

export const callImageSourceSelectionDialog = ({ opt }) => {
  // eslint-disable-next-line no-undef
  const isAndroid = (process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION' && cordova?.platformId === 'android')
  if (!isAndroid) {
    return
  }
  // to keep userSectionPage === 'EDIT_PROFILE'
  store.dispatch(setIsProfileChanged(true))
  const imgSuccess = (imageData) => {
    // got an image back from the camera
    // NOTE: JPEG and PNG are working equally
    const imageBase64 = 'data:image/png;base64,' + imageData
    store.dispatch(setCropFor(opt))
    store.dispatch(setIsPictureUploaded(imageBase64))
    // clear memory in app
    navigator.camera.cleanup()
  }

  const imgFail = (msg) => {
    console.log('Failed to get image: ' + msg)
  }

  /* eslint-disable no-undef */
  const cameraOptions = {
    quality: 75,
    destinationType: Camera.DestinationType.DATA_URL,
    sourceType: Camera.PictureSourceType.CAMERA,
    allowEdit: false,
    encodingType: Camera.EncodingType.PNG,
    mediaType: Camera.MediaType.PICTURE,
    targetWidth: 200,
    cameraDirection: Camera.Direction.FRONT,
    saveToPhotoAlbum: false
  }

  const photoLibraryOptions = {
    quality: 50,
    destinationType: Camera.DestinationType.DATA_URL,
    sourceType: Camera.PictureSourceType.PHOTOLIBRARY
  }
  /* eslint-enable no-undef */

  navigator.notification.confirm(
    'Please choose the source',
    (btnIndexPressed) => {
      if (btnIndexPressed === 1) {
        navigator.camera.getPicture(imgSuccess, imgFail, cameraOptions)
      } else {
        navigator.camera.getPicture(imgSuccess, imgFail, photoLibraryOptions)
      }
    },
    'Image selection',
    ['Camera', 'Image library']
  )
}
