import {
  Box, Link, Stack, Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

export const SocialLinks = ({ socialLinks }) => (socialLinks ? (
  <Stack direction="column" spacing="14px">
    <Text fontSize="20px" fontWeight={700}>
      Social Profiles
    </Text>
    {socialLinks?.length > 0 ? (
      <Stack direction="row" spacing="14px">
        <Stack gap="12px" spacing="unset" flexWrap="wrap" direction="row">
          {socialLinks.map((item) => (
            <Box
              key={uuid()}
              borderRadius="full"
              // bg={COLORS.lightGray}
              bg={COLORS.gold}
              p="6px 10px"
            >
              <Link href={item.link} name={item.name} data-name="socialLinks" target="_blank" _cursor="pointer" style={{ textDecoration: 'none' }}>
                {item.name}
              </Link>
            </Box>
          ))}
        </Stack>
      </Stack>
    ) : (
      <Text color={COLORS.gray}>No data available</Text>
    )}
  </Stack>
) : null)
