import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@chakra-ui/react'
import { authSelectors, setAuthPage, usersSelectors } from 'store'
import {
  FORGOT_PASSWORD,
  IS_VERIFY_PAGE,
  PENDING,
  SIGN_IN,
  SIGN_UP,
  VERIFY_LINK_PAGE
} from 'utils/constants'

import { ForgotPassword } from 'components/Auth/ForgotPassword'
import { SignIn } from 'components/Auth/SignIn'
import { SignUp } from 'components/Auth/SignUp'
import { VerifyLinkPage } from 'components/Auth/VerifyLinkPage'

import { Links } from './Links'

export const Auth = () => {
  const dispatch = useDispatch()

  const { getUserStatus } = useSelector(usersSelectors)

  const {
    authPage, signInStatus, signUpStatus, forgotPasswordStatus, pageName, verifyPageText
  } = useSelector(authSelectors)

  const selectPage = () => {
    switch (authPage) {
    case SIGN_IN:
      return (
        <SignIn
          isLoading={signInStatus === PENDING || getUserStatus === PENDING}
          setAuthPage={setAuthPage}
        />
      )
    case SIGN_UP:
      return <SignUp isLoading={signUpStatus === PENDING} setAuthPage={setAuthPage} />
    case VERIFY_LINK_PAGE:
      return (
        <VerifyLinkPage
          verifyPageText={verifyPageText}
          pageName={pageName}
          setAuthPage={setAuthPage}
        />
      )
    case FORGOT_PASSWORD:
      return (
        <ForgotPassword isLoading={forgotPasswordStatus === PENDING} setAuthPage={setAuthPage} />
      )
    default:
      return null
    }
  }

  useEffect(() => {
    if (pageName === IS_VERIFY_PAGE) {
      dispatch(setAuthPage(VERIFY_LINK_PAGE))
    }
  }, [pageName])

  useEffect(() => {
    dispatch(setAuthPage(SIGN_IN))
  }, [])

  return (
    <Stack height="100%" justifyContent="space-between">
      {selectPage()}
      <Links />
    </Stack>
  )
}
