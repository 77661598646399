import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box, ListItem, Stack, Text, UnorderedList
} from '@chakra-ui/react'
import { SingleButton } from 'common/Buttons'
import { handleCloseModal } from 'store'
import { handleClickScroll } from 'utils/helpers'

export const FAQ = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCloseModal())
  }, [])

  return (
    <Stack spacing="16px" padding="30px 65px" position="relative" width="100%">
      <Text fontWeight="700" fontSize="32px" textAlign="center">
        Connector FAQ
      </Text>

      <Box>
        <Text fontWeight="500" fontSize="24px">
          Table of Contents
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('1.')}
              width="max-content"
            >
              1. How Do I Put Myself on the Map?
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.')}
              width="max-content"
            >
              2. How Do I Add Affinities (Interests) so that Other People Can Find Me?
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('3.')}
              width="max-content"
            >
              3. How Do I Find People on the Map Who Share My Interests?
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('4.')}
              width="max-content"
            >
              4. How Can My Friends Find Each Other on the Map?
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('5.')}
              width="max-content"
            >
              5. How Can I Text My Friends on Connector?
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('6.')}
              width="max-content"
            >
              6. Why Do I see the Message "Message Not Delivered" When I Text My Friend?
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box>
        <Text id="1." fontWeight="500" fontSize="24px">
          1. How Do I Put Myself on the Map?
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem>Create an account if you do not already have one.</ListItem>
            <ListItem>Log in.</ListItem>
            <ListItem>
              Click the person icon on the top bar, to the left of the search bar.
            </ListItem>
            <ListItem>
              Create or edit a "profile" (start with one under "Friends" category near the top)
            </ListItem>
            <ListItem>
              You might have to tap or click the "edit" icon near the top of the profile tab on the
              left side of your screen.
            </ListItem>
            <ListItem>
              On the lower part of the "profile edit" screen is an option to choose your marker
              type. Try "static," then move the marker somewhere near your home. Maybe put the
              marker in the street in a nearby corner if you don't wasn't it on your house. If the
              marker is for your "baseball" profile, maybe put the marker on the field where you
              play.
            </ListItem>
            <ListItem>Click "save" to save your profile settings.</ListItem>
            <ListItem>When you have that profile active, the marker will be on the map.</ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box>
        <Text id="2." fontWeight="500" fontSize="24px">
          2. How Do I Add Affinities (Interests) so that Other People Can Find Me?
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem>Create an account if you do not already have one.</ListItem>
            <ListItem>Log in.</ListItem>
            <ListItem>
              Click the person icon on the top bar, to the left of the search bar.
            </ListItem>
            <ListItem>
              Create or edit a "profile" (start with one under "Friends" category near the top)
            </ListItem>
            <ListItem>
              You might have to tap or click the "edit" icon near the top of the profile tab on the
              left side of your screen.
            </ListItem>
            <ListItem>
              On the "profile edit" screen, you should see an edit box where you can enter your
              interests (maybe a particular sport or type of music or a hobby).
            </ListItem>
            <ListItem>Click "Save" to save your profile changes.</ListItem>
            <ListItem>
              It is best to select from the existing list of affinities (interests), but you can try
              entering a new affinity, and we will occasionally add those suggested items to the
              list for other people to find.
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box>
        <Text id="3." fontWeight="500" fontSize="24px">
          3. How Do I Find People on the Map Who Share My Interests?
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem>Create an account if you do not already have one.</ListItem>
            <ListItem>Log in.</ListItem>
            <ListItem>
              Add some "affinities" (interests) to your profile (see the instructions above).
            </ListItem>
            <ListItem>Save any changes that you make to your profile.</ListItem>
            <ListItem>How Can I Text My Friends on Connector?</ListItem>
            <ListItem>From the main map, search for one or more affinities.</ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box>
        <Text id="4." fontWeight="500" fontSize="24px">
          4. How Can My Friends Find Each Other on the Map?
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem>
              If there is a group of people who want to be able to find each other on the map, try
              sharing a secret code word among your friends.
            </ListItem>
            <ListItem>
              How Do I Add Affinities (Interests) so that Other People Can Find Me?
            </ListItem>
            <ListItem>
              Enter that codeword into the profile that you use for that group of friends.
            </ListItem>
            <ListItem>
              When anyone in the group searches the main map for people with that code word, all the
              people in your group appear.
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box>
        <Text id="5." fontWeight="500" fontSize="24px">
          5. How Can I Text My Friends on Connector?
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem>
              When you find someone on Connector that shares your affinities (interests), click on
              that person's marker on the map, then click on the "connect" button on the top-right
              part of that person's profile card.
            </ListItem>
            <ListItem>
              After that person approves, you will be able to communicate with them using the
              communication pane on the right side of the screen.
            </ListItem>
            <ListItem>
              To open the communications pane, click on the Connector icon on the top-right part of
              the screen, then select your friend from your list of contacts.
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box>
        <Text id="6." fontWeight="500" fontSize="24px">
          6. Why Do I see the Message "Message Not Delivered" When I Text My Friend?
        </Text>

        <Box pl="5px">
          <Text>
            Connector communications were designed to be end-to-end encrypted, but there might be
            situations in which users send messages over our system without OMEMO encryption. To
            prevent our servers from housing unencrypted communications, we may remove the feature
            that would otherwise buffer messages (e.g., hold the messages on the server until the
            recipient logs in). This prevents people from leaking unencrypted communications that
            could be the subject of a warrant to Connector.
          </Text>
        </Box>
      </Box>
      <SingleButton
        click={() => navigate('/')}
        customStyled={{ width: 'max-content', padding: '8px 20px', margin: '30px auto 0px' }}
        text="Go Home"
      />
    </Stack>
  )
}
