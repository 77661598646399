import { createAsyncThunk } from '@reduxjs/toolkit'
import { getNotificationMessage } from 'store'
import { setMeXmppInitialState, changeNumUnread, clearXmppPushNotification } from 'store/chat/slice'
import { usersApi } from 'store/users/api'
import { REJECTED } from 'utils/constants'
import { getParsedPassword } from 'utils/helpers'
import InitChat from 'components/Messenger/chatui/chat_init'

const isLocalEnv = (process.env.REACT_APP_USE_LOCAL_XMP !== undefined && process.env.REACT_APP_USE_LOCAL_XMP === 'true')

const switchTestUser = () => {
  let res = ''
  switch (window.testFlip) {
  case 'test01@localhost':
    res = 'test04@localhost'
    break
  case 'test04@localhost':
    res = 'test01@localhost'
    break
  default:
    res = 'test04@localhost'
  }
  window.testFlip = res
  return res
}

export const getChatCredentials = createAsyncThunk(
  'chat/getChatCredentials',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getChatCredentials()

      return response.data.data
    } catch (error) {
      await dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Get Chat Credentials Error'
        })
      )
      return rejectWithValue(error.message)
    }
  }
)

const doConnectChat = async (chat) => {
  // if invalid data then exit
  if (chat.chatCredentials === null || !chat.chatCredentials.username
    || !chat.chatCredentials.password) {
    return Promise.resolve()
  }
  const password = getParsedPassword(chat.chatCredentials.password)
  const discover_connection_methods = false
  const ws_url = isLocalEnv ? 'ws://localhost:5280/ws/' : `wss://${process.env.REACT_APP_XMPP_CHAT_URL}:5443/ws`
  const jid = isLocalEnv ? switchTestUser() : `${chat.chatCredentials.username}@${process.env.REACT_APP_XMPP_CHAT_URL}`
  const pass = isLocalEnv ? 'pass' : password
  if (isLocalEnv) {
    console.log(`connecting to chat with jid: "${jid}"`)
  }
  return InitChat(jid, pass, discover_connection_methods, undefined, ws_url)
}

export const LogoutFromChat = createAsyncThunk(
  'chat/LogoutFromChat',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { chat } = getState()
      await dispatch(changeNumUnread(0))
      await dispatch(clearXmppPushNotification())
      if (!chat.meXmppIsOnline) {
        return dispatch(setMeXmppInitialState())
      }
      const notifyUiAfterLogoutDone = () => new Promise((resolve) => {
        window.addEventListener('custom-chat-logout-complete', resolve, { once: true })
      })

      const event = new CustomEvent('custom-chat-logout')
      window.dispatchEvent(event)
      await notifyUiAfterLogoutDone()
      return dispatch(setMeXmppInitialState())
    } catch (error) {
      await dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Login chat user error'
        })
      )
      return rejectWithValue(error.message)
    }
  }
)

export const ConnectToChat = createAsyncThunk(
  'chat/ConnectToChat',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { chat } = getState()
      if (chat.meXmppIsOnline) {
        await dispatch(LogoutFromChat())
      }

      await dispatch(setMeXmppInitialState())
      return doConnectChat(chat)
    } catch (error) {
      await dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Login chat user error'
        })
      )
      return rejectWithValue(error.message)
    }
  }
)
