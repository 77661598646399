export const PasswordIconUnlock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#8B8B8B"
      d="M19.18 9.813c-.032-.063-.68-1.508-2.133-2.954C15.594 5.414 13.39 3.906 10 3.906c-3.39 0-5.703 1.602-7.047 2.953C1.61 8.211.852 9.75.82 9.813a.445.445 0 000 .374c.032.063.68 1.508 2.133 2.954C4.406 14.586 6.61 16.094 10 16.094c3.39 0 5.703-1.602 7.047-2.953 1.344-1.352 2.101-2.891 2.133-2.954a.445.445 0 000-.374zM10 15.155c-2.453 0-4.594-.89-6.36-2.648A10.46 10.46 0 011.774 10a10.461 10.461 0 011.868-2.508C5.406 5.734 7.547 4.844 10 4.844s4.594.89 6.36 2.648A10.463 10.463 0 0118.226 10c-.5.953-2.993 5.156-8.227 5.156zm0-8.75a3.594 3.594 0 100 7.188 3.594 3.594 0 000-7.188zm0 6.25A2.656 2.656 0 1112.656 10 2.664 2.664 0 0110 12.656z"
    />
  </svg>
)
