import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box, Image, Link, Stack, Text
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { handleCloseModal } from 'store'

export const AboutUs = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCloseModal())
  }, [])

  // NOTE: we cannot allow reference links outside of the application in Electron
  // because Electron does not have Back button and browsing history. We cannot return back
  // to the application
  const isDisabled = (process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION')
  return (
    <Box position="relative" width="100%" height="calc(100vh - 100px)">
      <Stack
        justifyContent="center"
        position="absolute"
        alignItems="center"
        spacing="28px"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Image
          boxSize="308px"
          objectFit="cover"
          src={ImagesPng.AboutUs}
          alt="Logo"
          borderRadius="full"
        />
        <Box fontWeight="700" fontSize="32px">
          About Us
        </Box>
        {/* <Box fontWeight="500" fontSize="65px">
          Coming soon!
        </Box> */}
        <Box fontWeight="500" fontSize="16px" width="90%" align="center">
          <Text fontWeight="700" align="center" fontSize="18px">
            Attribution
          </Text>
          This product includes GeoLite2 Data created by MaxMind, available from &nbsp;
          <span>
            {isDisabled ? (
              <Text color="blue">https://www.maxmind.com/</Text>
            ) : (
              <Link href="https://www.maxmind.com/" color="blue">https://www.maxmind.com/</Link>
            )}
          </span>
          .
        </Box>
        <SingleButton
          click={() => navigate('/')}
          customStyled={{ width: '100%', paddingBlock: '8px' }}
          text="Go Home"
        />
      </Stack>
    </Box>
  )
}
