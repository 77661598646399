import { Stack, Text } from '@chakra-ui/react'
// import { StatusIconGreen } from 'assets/icons'
import { connectionCount } from 'utils/helpers'
import { COLORS } from 'utils/styles'

export const Connections = ({
  pastConnections, activeConnections, isShowConnections, statusColor
}) => (

  <Stack textAlign="center" direction="column" justifyContent="space-between" padding="0 30px">
    <Stack justifyContent="space-between" direction="row" spacing="12px">
      <Text color={COLORS.darkGray}>Connections</Text>
      <Text>
        {connectionCount({ count: activeConnections, isVisible: isShowConnections })}
      </Text>
    </Stack>
    <Stack justifyContent="space-between" direction="row" spacing="12px">
      <Text color={COLORS.darkGray}>Disconnections</Text>
      <Text>
        {connectionCount({ count: pastConnections, isVisible: isShowConnections })}
        %
      </Text>
    </Stack>
    <Stack justifyContent="space-between" direction="row" spacing="12px">
      <Text color={COLORS.darkGray}>Status</Text>
      <Text>
        {statusColor}
      </Text>
    </Stack>
  </Stack>
)
