import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Image, Stack, Text } from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { handleChangePage, handleModalTitle } from 'store'
import {
  IS_VERIFY_PAGE,
  PASSWORD_VERIFY_TEXT,
  SIGN_IN,
  VERIFY_PAGE
} from 'utils/constants'

export const VerifyLinkPage = ({ setAuthPage, pageName, verifyPageText }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (verifyPageText === PASSWORD_VERIFY_TEXT) {
      dispatch(handleModalTitle(VERIFY_PAGE.ForgotPassword))
    } else {
      dispatch(handleModalTitle(VERIFY_PAGE.SignUn))
    }
    if (pageName === IS_VERIFY_PAGE) {
      dispatch(handleChangePage())
    }
  }, [])

  return (
    <Stack direction="column" spacing="32px">
      <Image
        boxSize="72px"
        objectFit="cover"
        src={ImagesPng.Logo}
        alt="Logo"
        borderRadius="full"
        margin="auto"
      />

      <Text textAlign="center" fontSize="16px">
        {verifyPageText}
      </Text>

      <SingleButton click={() => dispatch(setAuthPage(SIGN_IN))} text="Back to Sign in" />
    </Stack>
  )
}
