import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box, Image, Stack, Text
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { PasswordInput } from 'common/Inputs/PasswordInput'
import { authSelectors, resetPassword } from 'store'
import {
  CONFIRM_PASSWORD, PASSWORD, PENDING, SUCCEEDED
} from 'utils/constants'
import { getUrlParams } from 'utils/helpers'
import { ChangePasswordValidationScheme } from 'validation'

import { NotificationMessage } from 'components/NotificationMessage'

export const ResetPasswordPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { resetPasswordStatus } = useSelector(authSelectors)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(ChangePasswordValidationScheme)
  })

  const onSubmit = (data) => {
    const body = {
      token: getUrlParams('token', 'email')[0],
      email: getUrlParams('token', 'email')[1],
      password: data.password,
      repeat_password: data.confirmPassword
    }
    dispatch(resetPassword(body))
  }

  useEffect(() => {
    if (resetPasswordStatus === SUCCEEDED) {
      navigate('/')
    }
  }, [resetPasswordStatus])

  return (
    <Box width="100%" height="100vh" position="relative">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          position="absolute"
          left="50%"
          top="35%"
          maxWidth="530px"
          direction="column"
          spacing="32px"
          transform="translate(-50%, -50%)"
        >
          <Image
            boxSize="72px"
            objectFit="cover"
            src={ImagesPng.Logo}
            alt="Logo"
            borderRadius="full"
            margin="auto"
          />

          <Text textAlign="center" fontSize="16px">
            Strong password is required! Enter at least eight characters, and
            combine uppercase, lowercase letters, numbers, and symbols.
          </Text>

          <Stack margin="auto" spacing="42px" direction="column">
            <PasswordInput
              placeholder="New Password"
              error={errors.password}
              register={register(PASSWORD)}
            />
            <PasswordInput
              placeholder="Confirm New Password"
              error={errors.confirmPassword}
              register={register(CONFIRM_PASSWORD)}
            />
          </Stack>

          <SingleButton
            isLoading={resetPasswordStatus === PENDING}
            text="Create Password"
          />
        </Stack>
      </form>
      <NotificationMessage />
    </Box>
  )
}
