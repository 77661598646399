import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  CardHeader,
  Image,
  Stack
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { getNotificationMessage } from 'store'
import { NOTIFICATION_TYPE, PENDING, REJECTED } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

export const GuestProfile = ({
  isBlocked
}) => {
  const item = null
  const dispatch = useDispatch()
  return (
    <Card key={uuid()} boxShadow="none" marginTop="0px !important" background="#c4c3c7">
      <CardHeader display="flex" alignItems="center" padding="0 25px" background="#feeb88">
        <Box textAlign="left" paddingRight="10px">
          {isBlocked ? 'Blocked Request' : 'Connection Request'}
        </Box>
        {/* {isBlocked ? null
          : (
            <Stack direction="row" alignItems="center">
              <Box
                textAlign="center"
                background={COLORS.gold}
                width="17px"
                height="17px"
                borderRadius="full"
                position="relative"
              >

                <Box
                  fontSize="12px"
                  fontWeight={500}
                  transform="translate(-50%, -50%)"
                  top="50%"
                  left="50%"
                  position="absolute"
                >
                  1
                </Box>
              </Box>
            </Stack>
          )} */}
      </CardHeader>

      <CardBody padding="5px" minHeight="140px">
        <Box
          sx={{
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'row',
            gap: '0px'
          }}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '10px',
              display: 'block'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '24px',
              background: 'rgba(255, 215, 0, 1)'
            }
          }}
        >
          <Stack
            spacing="55px"
            id="connection-request-list"
            // New Attributes for new design
            sx={{
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'row',
              gap: '0px'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
                height: '10px',
                display: 'block'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '24px',
                background: 'rgba(255, 215, 0, 1)'
              }
            }}
          >
            <Stack key={uuid()} id={item?.sender_id}>
              <Box
                key={uuid()}
                cursor="pointer"
                onClick={() => {
                  dispatch(getNotificationMessage({
                    type: REJECTED,
                    message: 'You must sign in or sign up to view requests',
                    isEditProfile: true
                  }))
                }}
                sx={{
                  minWidth: '120px',
                  marginTop: '5px'
                }}
                data-name="notificationItem"
                position="relative"
              >
                {/* {isBlocked ? <div>No notifications to display</div>
                  : (
                    <Image
                      key={uuid()}
                      width="108px"
                      height="108px"
                      objectFit="cover"
                      src={ImagesPng.DefaultImage}
                      alt={item?.title}
                      borderRadius="5px"
                    />
                  )} */}
                <div>No notifications to display</div>
              </Box>
            </Stack>
          </Stack>

          {/* <ContentItem items={item?.value} title={item?.id} /> */}
        </Box>
      </CardBody>
    </Card>
  )
}
