import * as yup from 'yup'

export const ChangePasswordValidationScheme = yup.object().shape({
  password: yup
    .string()
    .required('The field is required')
    .min(8, 'Password requires minimum 8 characters')
    .max(16, 'Password requires maximum 16 characters'),
  confirmPassword: yup
    .string()
    .when('password', (password, field) => (password
      ? field.required('The field is required').oneOf([yup.ref('password')], 'Passwords do not match')
      : field))
})
