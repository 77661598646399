import * as yup from 'yup'

const notAllowed = /[&'"<>]/

/* eslint-disable quotes */
export const EditProfileValidationScheme = yup.object().shape({
  userName: yup
    .string()
    .required('The field is required')
    .min(2, 'Profile name should be at least 2 characters')
    .max(50, 'Profile name should be at less 50 characters')
    .matches(/[^&'"<>]/, { message: `Symbols &'"<> not allowed`, excludeEmptyString: true }),
  userUrl: yup.string().url('Invalid URL')
})
