import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Flex, Input, Stack, Text
} from '@chakra-ui/react'
import { CloseIcon } from 'assets/icons'
import { getNotificationMessage, notificationMessageSelector } from 'store'
import { REJECTED } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

export const CodeWords = ({ codeWordsArray, setCodeWordsArray }) => {
  const [codeWordsInput, setCodeWordsInput] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const { editProfileCodeWord } = useSelector(notificationMessageSelector)

  const handleDeleteCodeWord = (title) => {
    const newArray = codeWordsArray.filter((item) => item.title !== title)
    setCodeWordsArray(newArray)
    setErrorMessage('')
  }

  const setChangeKeyCode = (e) => {
    const repetitive = codeWordsArray.filter((item) => item.title === codeWordsInput
    || item.title === codeWordsInput.substring(1))
    if (
      e.keyCode === 13
      && codeWordsArray?.length <= 4
      && codeWordsInput?.length > 0
      && codeWordsInput?.length < 17
    ) {
      const deleteHashtag = () => {
        if (codeWordsInput[0] === '#') {
          return codeWordsInput.substring(1)
        }
        return codeWordsInput
      }

      if (repetitive?.length === 0) {
        setCodeWordsArray([
          ...codeWordsArray,
          { title: deleteHashtag() }
        ])
        setCodeWordsInput('')
      } else if (repetitive?.length !== 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Code Word already exist!',
          isEditProfile: true
        }))
        setCodeWordsInput('')
      }
    }
  }

  const handleMaxCodewords = (e) => {
    setCodeWordsInput(e)
    if (codeWordsArray.length > 4) {
      setErrorMessage('Only 5 code words are allowed')
    } else if (e.length > 16) {
      setErrorMessage('Max of 16 characters per code word')
    } else {
      setErrorMessage('')
    }
  }

  return (
    <Stack direction="column" spacing="10px">
      <Flex gap={2}>
        <Text fontSize="20px" fontWeight={700}>
          Code Words
        </Text>
      </Flex>

      <Stack
        rowGap="10px"
        spacing="unset"
        wrap="wrap"
        direction="row"
        display={codeWordsArray.length > 0 ? 'flex' : 'none'}
      >
        {codeWordsArray.map((item) => (
          <Stack
            key={uuid()}
            direction="row"
            spacing="10px"
            mr="5px !important"
            p="6px 18px"
            borderRadius="full"
            bg={COLORS.gold}
            width="max-content"
            alignItems="center"
          >
            <Text>
              #
              {item.title}
            </Text>
            <Box onClick={() => handleDeleteCodeWord(item.title)} name="delete-code-word" cursor="pointer">
              <CloseIcon />
            </Box>
          </Stack>
        ))}
      </Stack>

      <Box>
        <Input
          placeholder="Enter Code Word and Press Enter"
          enterKeyHint="go"
          value={codeWordsInput}
          onChange={(e) => handleMaxCodewords(e.target.value)}
          onKeyDown={(e) => setChangeKeyCode(e)}
          name="profile_code_words"
        />
      </Box>

      {codeWordsInput.length > 0 ? (
        <Text fontSize="14px" color={COLORS.red}>
          {errorMessage}
        </Text>
      ) : null}
      {editProfileCodeWord?.length > 0 ? (
        <Text fontSize="14px" color={COLORS.red}>
          The following code words are not allowed&#58;
          <br />
          {editProfileCodeWord?.map((item, i) => item + (i === editProfileCodeWord?.length - 1 ? '' : ',\n'))}
        </Text>
      ) : null}
    </Stack>
  )
}
