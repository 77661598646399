import styled from 'styled-components/macro'
import { COLORS, FLEX_CONTAINER } from 'utils/styles'

export const StyledFlexContainer = styled.div`
  ${FLEX_CONTAINER.FlexRow}
  gap:16px;
  flex-wrap: wrap;
  margin: 0 20px;
`

export const StyledWordsContent = styled.div`
  padding: 6px 24px;
  background: ${COLORS.semiLightGray};
  border-radius: 50px;
`
