import React from 'react'

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19"
  >
    <path
      fill="#000"
      d="M13.725 4.783a.747.747 0 00-1.058 0L9 8.443 5.332 4.775a.748.748 0 10-1.057 1.058L7.942 9.5l-3.667 3.668a.747.747 0 101.057 1.057L9 10.558l3.667 3.667a.748.748 0 101.058-1.057L10.057 9.5l3.668-3.667a.752.752 0 000-1.05z"
    />
  </svg>
)
