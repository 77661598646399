import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box, ListItem, Stack, Text, UnorderedList
} from '@chakra-ui/react'
import { SingleButton } from 'common/Buttons'
import { handleCloseModal } from 'store'
import { handleClickScroll } from 'utils/helpers'

export const TermsOfService = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleCloseModal())
  }, [])

  return (
    <Stack spacing="16px" padding="30px 65px" position="relative" width="100%">
      <Box fontWeight="400" fontSize="16px">
        <Text fontWeight="700" fontSize="32px" textAlign="center">
          Connector Terms of Service
        </Text>

        <Text fontWeight="500" fontSize="24px">
          Table of Contents
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('1.')}
              width="max-content"
            >
              1. Applicability and Limits of Service
            </ListItem>
            <Box pl="38px">
              <ListItem
                _hover={{ borderBottom: '1px solid black' }}
                cursor="pointer"
                onClick={() => handleClickScroll('1.1.')}
                width="max-content"
              >
                1.1. Privacy policy
              </ListItem>
              <ListItem
                _hover={{ borderBottom: '1px solid black' }}
                cursor="pointer"
                onClick={() => handleClickScroll('1.2.')}
                width="max-content"
              >
                1.2. Security
              </ListItem>
              <ListItem
                _hover={{ borderBottom: '1px solid black' }}
                cursor="pointer"
                onClick={() => handleClickScroll('1.3.')}
                width="max-content"
              >
                1.3. Prohibited Activities
              </ListItem>
            </Box>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('2.')}
              width="max-content"
            >
              2. Third Party Content
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('3.')}
              width="max-content"
            >
              3. Rewards
            </ListItem>
            <ListItem
              _hover={{ borderBottom: '1px solid black' }}
              cursor="pointer"
              onClick={() => handleClickScroll('4.')}
              width="max-content"
            >
              4. Paid Service
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Stack>
        <Text id="1." fontWeight="500" fontSize="24px">
          1. Applicability and Limits of Service
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          This User Agreement ("Terms of Service") applies to your use of Connector ("we," "us," or
          "our") websites and services (collectively referred to as "Services"). To use Connector
          Services, you must accept the Terms of Service including all components of the Terms of
          Service that are referenced here.
          <br />
          Websites and services provided by Connector are available only if you agree to these Terms
          of Service. If you do not agree with these Terms of Service, you are not authorized to use
          these services.
          <br />
          We may update or otherwise modify this agreement at any time, and you will receive
          notification of such updates to your email address. You agree to accept notifications
          about terms of service via email.
          <br />
          Connector services are provided AS IS without any guarantees or warrantees of any kind.
          There is no guarantee that service will be available, and service may be interrupted
          without notice for upgrades, maintenance, or for other reasons, including addressing
          malicious attacks. Connector may discontinue service at any time without notice. Services
          may be upgraded or modified without notice, and that might affect functionality of or
          access to the service or content.
          <br />
          In addition to the other terms described in the Terms of Service, to use Connector
          websites or services you must:
        </Text>

        <Box id="test" pl="5px">
          <UnorderedList>
            <ListItem>be at least 13 years of age to access the "Friends" functionality,</ListItem>
            <ListItem>
              be at least 18 years of age to access the "Dating" functionality or "Professional"
              functionality,
            </ListItem>
            <ListItem>
              be old enough to both legally consent to these terms of service and use these services
              in your country,
            </ListItem>
            <ListItem>
              be capable of engaging in binding contract under the laws of your country or have
              obtained permission (in a way that is legal in your country) from your legal guardian
              who agrees with these Terms of Service,
            </ListItem>
            <ListItem>not be banned or suspended from using Connector services, and</ListItem>
            <ListItem>
              be authorized to represent your company if you are accessing Connector services on
              behalf of a company.
            </ListItem>
            <ListItem>
              register with Connector and stay registered with an email address that is not from a
              provider of either disposable email addresses or abuse email addresses. Connector has
              sole discretion of determining which providers constitute providers of disposable or
              abuse email addresses.
            </ListItem>
          </UnorderedList>
        </Box>

        <Text fontSize="16px" lineHeight="26px">
          To ensure that communications among users are kept private, Connector may disable the
          feature that temporarily stores communications among users on our servers. This means that
          you may not be able to communicate with a user until that user has at least one device
          that is online.
          <br />
          We may remove prohibited content and either delete or freeze accounts that engage in
          prohibited activities.
          <br />
          We may delete accounts that have not been accessed for a substantial period of time
          relative to the observed trends (e.g., the distribution of account-dormancy periods for
          accounts that were temporarily dormant but returned to active status). We may place
          long-time inactive accounts into an "archive" mode that causes the account to stop
          displaying on the map but that can return to active status when the user logs in.
          Connector may set the time limits for these conditions based on business necessity,
          including consideration of any denial of service or flooding attacks.
          <br />
          You agree to access Connector services at your own risk. To the greatest extent allowable
          by the laws of your country, you agree that Connector is not and will not be liable for
          any damages that you suffer as a direct or indirect result of the use of Connector
          services. Your use of Connector services also implies that you agree to indemnify and hold
          harmless employees, contractors, owners, directors, officers, employees, contractors, or
          users of Connector from any damages that might stem from your use of Connector services
          other than the possibility of up to 6 (six) months of service fees if the claim is related
          to a specific paid service.
          <br />
        </Text>
      </Stack>

      <Stack>
        <Text id="1.1." fontWeight="500" fontSize="20px">
          1.1. Privacy policy
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          ZZZXXXZZZ link to privacy policy under the TOS
          <br />
          Your use of Connector services requires that you first understand and accept our Privacy
          Policy (add a link here), which explains the data that we collect and how it is used.
        </Text>
      </Stack>

      <Stack>
        <Text id="1.2." fontWeight="500" fontSize="20px">
          1.2. Security
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          Do not share your password with anyone. If you forget your password, you can use the
          password reset feature that is located on the login page. If you loose access to the email
          account that you used to register your Connector account, you will be unable to respond to
          the password reset email, and your account will be inaccessible, but you could open a new
          account.
        </Text>
      </Stack>

      <Stack>
        <Text id="1.3." fontWeight="500" fontSize="20px">
          1.3. Prohibited Activities
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          Your use of this service implies your agreement to refrain from any activities that are in
          violation of applicable laws or in violation of the Connector Terms of Service. Failure to
          abide by the Terms of Service might result in termination of your account, deletion of
          prohibited material, temporary suspension of your account, temporary banning from the
          platform or other reasonable sanctions allowed by law. The list of prohibited activities
          includes, but is not limited to:
        </Text>

        <Box pl="5px">
          <UnorderedList>
            <ListItem>violating any of the terms of service</ListItem>
            <ListItem>
              sending automated requests or communications to any of our servers or to any of our
              users over any protocol
            </ListItem>
            <ListItem>
              attempting to gain unauthorized access to our servers or to the accounts of our users
            </ListItem>
            <ListItem>
              transmitting any type of computer virus or malicious code to any of our servers or to
              any of our users
            </ListItem>
            <ListItem>
              sending excessive requests to our servers or to the accounts of our users
            </ListItem>
            <ListItem>engaging in any illegal activities on our platform</ListItem>
            <ListItem>
              selling, reselling, or making other commercial use of Connector content
            </ListItem>
            <ListItem>decompiling or otherwise reverse-engineering Connector content</ListItem>
            <ListItem>
              Submitting, posting, or transmitting content that is either illegal or prohibited by
              the Terms of Service or for which you lack ownership rights.
            </ListItem>
            <ListItem>
              Activities that pose a legal risk to Connector, its officers, directors, managers,
              employees, contractors, or users.
            </ListItem>
          </UnorderedList>
        </Box>
      </Stack>

      <Stack>
        <Text id="2." fontWeight="500" fontSize="24px">
          2. Third Party Content
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          Connector might display content that links to advertisers, partners, affiliates, or other
          websites linked by users. Your use of any third-party content is at your own risk, and you
          agree to indemnify and hold Connector (and all officers, directors, managers, employees,
          and contractors) harmless for any damages incurred by viewing or visiting any third party
          content.
        </Text>
      </Stack>

      <Stack>
        <Text id="3." fontWeight="500" fontSize="24px">
          3. Rewards
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          Connector will be offering rewards to early adopters. When that Service is available, we
          will store information about the accounts who receive payments and their usage information
          that qualifies them for the reward.
        </Text>
      </Stack>

      <Stack>
        <Text id="4." fontWeight="500" fontSize="24px">
          4. Paid Service
        </Text>

        <Text fontSize="16px" lineHeight="26px">
          Connector will eventually offer enhanced Services in exchange for crypto currency.
          Connector will store information about the date and nature of the services that were
          ordered and associate that information with your account. Connector will also have access
          to information from the block chain about the transaction.
        </Text>
      </Stack>

      <SingleButton
        click={() => navigate('/')}
        customStyled={{ width: 'max-content', padding: '8px 20px', margin: '30px auto 0px' }}
        text="Go Home"
      />
    </Stack>
  )
}
