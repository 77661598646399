import axios from 'axios'

export const initAxiosInterceptors = () => {
  axios.interceptors.request.use((config) => {
    config.url = process.env.REACT_APP_API_URL + config.url

    config.headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true',
      Accept: 'application/json'
    }

    if (config.formData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json'
      }
    }

    if (config.token) {
      const token = JSON.parse(localStorage.getItem('credentials'))
      if (!token) {
        throw new Error('token not found')
      }
      config.headers = {
        ...config.headers,
        'access-token': `Bearer ${token.access_token}`,
        Authorization: `Bearer ${token.access_token}`
      }
    }
    return config
  })

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('access-token')
        localStorage.removeItem('lat')
        localStorage.removeItem('lng')
      }
      if (error.response.status === 403) {
        localStorage.removeItem('access-token')
        localStorage.removeItem('lat')
        localStorage.removeItem('lng')
      }
      // eslint-disable-next-line no-new-object
      throw new Object({
        data: error.response.data,
        status: error.response.status
      })
    }
  )
}
