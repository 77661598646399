import { useSelector } from 'react-redux'
import { usersSelectors } from 'store'
import { isAuth } from 'utils/helpers'

import { Auth } from 'components/Auth'

export const withAuthentication = (UserSection) => {
  const { user } = useSelector(usersSelectors)

  if (isAuth() && user) {
    return <UserSection />
  }

  return <Auth />
}
