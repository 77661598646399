import { createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  acceptFriend,
  addFriend,
  deleteFriend,
  deleteUser,
  deleteUserProfile,
  denyFriend,
  getAffinities,
  getFrequency,
  getFriendsNotifications,
  getProfileItem,
  getSearchParams,
  getSocialLinks,
  getSocialLinksCategories,
  getUser,
  getUserProfile,
  selectProfile,
  updateUserProfile,
  updateUserSettings,
  setLiveLocData
} from 'store'
import {
  MODAL_SECTION, PENDING, REJECTED, SUCCEEDED
} from 'utils/constants'

import {
  getAdminNotifications, getFileTest, getPingTest,
  postMakeNotificationSeen, setDataTest, archiveRequest, getBlockedRequest
} from './axios'

const initialState = {
  user: null,
  userProfile: null,
  selectedUserProfile: null,
  activeProfile: null,
  userSectionPage: '',
  blockedRequest: [],
  isSocialMediaSelectOpen: false,
  socMedDisplay: 'none',
  isProfileChanged: false,
  isProfileActive: true,
  isProfileIncomplete: false,
  isChangesSaved: false,
  isSettingPage: false,
  isLocationOpen: false,
  isPictureUploaded: '',
  croppedPicture: '',
  cropFor: '',
  closeResetProfileModal: true,
  userEdited: [],
  friendsNotifications: [],
  searchAffinities: [],
  filters: [],
  userPosition: [],
  searchBar: {},
  notificationRequestProfiles: [],
  isOpenRightModal: false,
  isOpenLeftModal: false,
  isCloseModal: false,
  isProfileDeleted: false,
  isNotifViewing: false,
  isProfileSwitched: false,
  adminNotifRequest: null,
  profileItemId: '',
  profileItem: null,
  searchSocialLinks: [],
  searchSocialLinksCategories: [],
  adminNotifications: null,
  pingStatus: '',
  fileStatus: '',
  dataStatus: '',
  quoteStatus: '',
  serverName: '',
  testImage: [],
  dataQuote: '',
  pingTestRes: null,
  fileTestRes: null,
  dataTestRes: null,
  quoteTestRes: null,
  randomPos: null,
  askForLocationServices: true,
  frequency: [],
  liveLocationCoordinates: null,
  getUserStatus: PENDING | SUCCEEDED | REJECTED,
  getUserProfileStatus: PENDING | SUCCEEDED | REJECTED,
  selectProfileStatus: PENDING | SUCCEEDED | REJECTED,
  getAffinitiesStatus: PENDING | SUCCEEDED | REJECTED,
  getSearchParamsStatus: PENDING | SUCCEEDED | REJECTED,
  updateUserSettingsStatus: PENDING | SUCCEEDED | REJECTED,
  deleteProfileStatus: PENDING | SUCCEEDED | REJECTED,
  updateUserProfileStatus: PENDING | SUCCEEDED | REJECTED,
  deleteUserStatus: PENDING | SUCCEEDED | REJECTED,
  deleteFriendStatus: PENDING | SUCCEEDED | REJECTED,
  addFriendStatus: PENDING | SUCCEEDED | REJECTED,
  getFriendsNotificationsStatus: PENDING | SUCCEEDED | REJECTED,
  acceptFriendStatus: PENDING | SUCCEEDED | REJECTED,
  denyFriendStatus: PENDING | SUCCEEDED | REJECTED,
  getAdminNotificationsStatus: PENDING | SUCCEEDED | REJECTED,
  getProfileItemStatus: PENDING | SUCCEEDED | REJECTED,
  postMakeNotificationSeenStatus: PENDING | SUCCEEDED | REJECTED,
  getSocialLinksStatus: PENDING | SUCCEEDED | REJECTED,
  getSocialLinksCategoriesStatus: PENDING | SUCCEEDED | REJECTED,
  getFrequencyStatus: PENDING | SUCCEEDED | REJECTED,
  setLiveLocDataStatus: PENDING | SUCCEEDED | REJECTED,
  getPingTestStatus: PENDING | SUCCEEDED | REJECTED,
  getFileTestStatus: PENDING | SUCCEEDED | REJECTED,
  setDataTestStatus: PENDING | SUCCEEDED | REJECTED,
  archiveRequestStatus: PENDING | SUCCEEDED | REJECTED,
  getBlockedRequestStatus: PENDING | SUCCEEDED | REJECTED
}

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserSectionPage: (state, action) => {
      state.userSectionPage = action.payload
    },
    clearUser: (state) => {
      // TODO remove XMPP account and logout
      state.user = null
      state.userProfile = null
      state.activeProfile = null
      state.userSectionPage = ''
      state.searchAffinities = []
      state.searchSocialLinks = []
      state.searchSocialLinksCategories = []
      state.searchBar = {}
      state.filters = []
      state.userPosition = []
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    deleteFilter: (state, action) => {
      state.filters = state.filters.filter((item) => item.id !== action.payload)
    },
    setUserPosition: (state, action) => {
      state.userPosition = action.payload
    },
    handleOpenModal: (state, action) => {
      const { section, isOpen } = action.payload
      state.isOpenRightModal = section === MODAL_SECTION?.RightSection && isOpen
      state.isOpenLeftModal = section === MODAL_SECTION?.LeftSection && isOpen
    },
    setProfileItemId: (state, { payload }) => {
      state.profileItemId = payload
    },
    deleteProfileItem: (state) => {
      state.profileItem = null
    },
    handleCloseModal: (state) => {
      state.isCloseModal = !state.isCloseModal
    },
    setSocMedDisplay: (state, action) => {
      state.isSocialMediaSelectOpen = action.payload
    },
    setSocMedDisplayStatus: (state, action) => {
      state.socMedDisplay = action.payload
    },
    setIsProfileChanged: (state, action) => {
      state.isProfileChanged = action.payload
    },
    setIsPictureUploaded: (state, action) => {
      state.isPictureUploaded = action.payload
    },
    setCroppedPicture: (state, action) => {
      state.croppedPicture = action.payload
    },
    setCropFor: (state, action) => {
      state.cropFor = action.payload
    },
    setCloseResetProfileModal: (state, action) => {
      state.closeResetProfileModal = action.payload
    },
    setIsProfileActive: (state, action) => {
      state.isProfileActive = action.payload
    },
    setIsProfileIncomplete: (state, action) => {
      state.isProfileIncomplete = action.payload
    },
    setIsChangesSaved: (state, action) => {
      state.isChangesSaved = action.payload
    },
    setIsSettingPage: (state, action) => {
      state.isSettingPage = action.payload
    },
    setIsLocationOpen: (state, action) => {
      state.isLocationOpen = action.payload
    },
    setUserEdited: (state, action) => {
      state.userEdited = action.payload
    },
    setIsProfileDeleted: (state, action) => {
      state.isProfileDeleted = action.payload
    },
    clearNotifs: (state) => {
      state.adminNotifications = []
      state.friendsNotifications = []
    },
    setIsNotifViewing: (state, action) => {
      state.isNotifViewing = action.payload
    },
    setAdminNotifRequest: (state, action) => {
      state.adminNotifRequest = action.payload
    },
    setIsProfileSwitched: (state, action) => {
      state.isProfileSwitched = action.payload
    },
    setPingStatus: (state, action) => {
      state.pingStatus = action.payload
    },
    clearTestData: (state) => {
      state.pingStatus = ''
      state.fileStatus = ''
      state.dataStatus = ''
      state.quoteStatus = ''
      state.serverName = ''
      state.testImage = []
      state.dataQuote = ''
      state.pingTestRes = null
      state.fileTestRes = null
      state.dataTestRes = null
      state.quoteTestRes = null
    },
    // Response Time for tests
    setResTime1: (state, action) => {
      state.pingTestRes = action.payload?.duration
      state.serverName = action.payload?.serverName
    },
    setResTime2: (state, action) => {
      state.fileTestRes = action.payload?.duration
      state.fileStatus = action.payload?.status
    },
    setResTime3: (state, action) => {
      state.dataTestRes = action.payload?.duration
      state.dataStatus = action.payload?.status
    },
    setResTime4: (state, action) => {
      state.quoteTestRes = action.payload?.duration
      state.quoteStatus = action.payload?.status
    },
    setSelectedUserProfile: (state, action) => {
      state.selectedUserProfile = action.payload
    },
    setRandomPos: (state, action) => {
      state.randomPos = action.payload
    },
    setAskForLocationServices: (state, action) => {
      state.askForLocationServices = action.payload
    }
  },
  extraReducers: {
    // GET USER
    [getUser.pending]: (state) => {
      state.getUserStatus = PENDING
    },
    [getUser.fulfilled]: (state, action) => {
      state.getUserStatus = SUCCEEDED
      state.user = action.payload.data
    },
    [getUser.rejected]: (state) => {
      state.getUserStatus = REJECTED
    },
    // DELETE USER
    [deleteUser.pending]: (state) => {
      state.deleteUserStatus = PENDING
    },
    [deleteUser.fulfilled]: (state) => {
      state.deleteUserStatus = SUCCEEDED
    },
    [deleteUser.rejected]: (state) => {
      state.deleteUserStatus = REJECTED
    },
    // GET USER PROFILE
    [getUserProfile.pending]: (state) => {
      state.getUserProfileStatus = PENDING
    },
    [getUserProfile.fulfilled]: (state, action) => {
      const activeProfile = action.payload.find((item) => item.is_active)

      if (activeProfile === undefined) {
        state.filters = []
      }

      state.getUserProfileStatus = SUCCEEDED
      state.userProfile = action.payload
      state.activeProfile = activeProfile
    },
    [getUserProfile.rejected]: (state) => {
      state.getUserProfileStatus = REJECTED
    },
    // SELECT USER PROFILE
    [selectProfile.pending]: (state) => {
      state.selectProfileStatus = PENDING
    },
    [selectProfile.fulfilled]: (state) => {
      state.selectProfileStatus = SUCCEEDED
    },
    [selectProfile.rejected]: (state) => {
      state.selectProfileStatus = REJECTED
    },
    // GET AFFINITIES
    [getAffinities.pending]: (state) => {
      state.getAffinitiesStatus = PENDING
    },
    [getAffinities.fulfilled]: (state, action) => {
      state.getAffinitiesStatus = SUCCEEDED
      state.searchAffinities = action.payload
    },
    [getAffinities.rejected]: (state) => {
      state.getAffinitiesStatus = REJECTED
    },
    // UPDATE PROFILE
    [updateUserProfile.pending]: (state) => {
      state.updateUserProfileStatus = PENDING
    },
    [updateUserProfile.fulfilled]: (state) => {
      state.updateUserProfileStatus = SUCCEEDED
    },
    [updateUserProfile.rejected]: (state) => {
      state.updateUserProfileStatus = REJECTED
    },
    // DELETE PROFILE
    [deleteUserProfile.pending]: (state) => {
      state.deleteProfileStatus = PENDING
    },
    [deleteUserProfile.fulfilled]: (state) => {
      state.deleteProfileStatus = SUCCEEDED
    },
    [deleteUserProfile.rejected]: (state) => {
      state.deleteProfileStatus = REJECTED
    },
    // GET SEARCH BAR PARAMS
    [getSearchParams.pending]: (state) => {
      state.getSearchParamsStatus = PENDING
    },
    [getSearchParams.fulfilled]: (state, action) => {
      state.getSearchParamsStatus = SUCCEEDED
      state.searchBar = action.payload
    },
    [getSearchParams.rejected]: (state) => {
      state.getSearchParamsStatus = REJECTED
    },
    // UPDATE USER SETTINGS
    [updateUserSettings.pending]: (state) => {
      state.updateUserSettingsStatus = PENDING
    },
    [updateUserSettings.fulfilled]: (state) => {
      state.updateUserSettingsStatus = SUCCEEDED
    },
    [updateUserSettings.rejected]: (state) => {
      state.updateUserSettingsStatus = REJECTED
    },
    // ADD FRIEND
    [addFriend.pending]: (state) => {
      state.addFriendStatus = PENDING
    },
    [addFriend.fulfilled]: (state) => {
      state.addFriendStatus = SUCCEEDED
      state.profileItemId = ''
    },
    [addFriend.rejected]: (state) => {
      state.addFriendStatus = REJECTED
    },
    // DELETE FRIEND
    [deleteFriend.pending]: (state) => {
      state.deleteFriendStatus = PENDING
    },
    [deleteFriend.fulfilled]: (state) => {
      state.deleteFriendStatus = SUCCEEDED
      state.profileItemId = ''
    },
    [deleteFriend.rejected]: (state) => {
      state.deleteFriendStatus = REJECTED
    },
    // GET NOTIFICATION
    [getFriendsNotifications.pending]: (state) => {
      state.getFriendsNotificationsStatus = PENDING
    },
    [getFriendsNotifications.fulfilled]: (state, action) => {
      state.getFriendsNotificationsStatus = SUCCEEDED
      state.friendsNotifications = action.payload
    },
    [getFriendsNotifications.rejected]: (state) => {
      state.getFriendsNotificationsStatus = REJECTED
    },
    // ACCEPT FRIEND
    [acceptFriend.pending]: (state) => {
      state.acceptFriendStatus = PENDING
    },
    [acceptFriend.fulfilled]: (state) => {
      state.acceptFriendStatus = SUCCEEDED
    },
    [acceptFriend.rejected]: (state) => {
      state.acceptFriendStatus = REJECTED
    },
    // DENY FRIEND
    [denyFriend.pending]: (state) => {
      state.denyFriendStatus = PENDING
    },
    [denyFriend.fulfilled]: (state) => {
      state.denyFriendStatus = SUCCEEDED
    },
    [denyFriend.rejected]: (state) => {
      state.denyFriendStatus = REJECTED
    },
    [getProfileItem.pending]: (state) => {
      state.profileItem = null
      state.getProfileItemStatus = PENDING
    },
    [getProfileItem.fulfilled]: (state, { payload }) => {
      if (String(payload.id) === localStorage.getItem('id')) {
        state.profileItem = payload
        state.getProfileItemStatus = SUCCEEDED
      }
    },
    [getProfileItem.rejected]: (state) => {
      state.getProfileItemStatus = REJECTED
    },
    // ADMIN NOTIFICATIONS
    [getAdminNotifications.pending]: (state) => {
      state.profileItem = null
      state.getAdminNotificationsStatus = PENDING
    },
    [getAdminNotifications.fulfilled]: (state, { payload }) => {
      state.getAdminNotificationsStatus = SUCCEEDED
      state.adminNotifications = payload
    },
    [getAdminNotifications.rejected]: (state) => {
      state.getAdminNotificationsStatus = REJECTED
    },
    // MAKE NOTIFICATION SEEN
    [postMakeNotificationSeen.pending]: (state) => {
      state.postMakeNotificationSeenStatus = PENDING
    },
    [postMakeNotificationSeen.fulfilled]: (state) => {
      state.postMakeNotificationSeenStatus = SUCCEEDED
    },
    [postMakeNotificationSeen.rejected]: (state) => {
      state.postMakeNotificationSeenStatus = REJECTED
    },
    // GET SOCIAL Links
    [getSocialLinks.pending]: (state) => {
      state.getSocialLinksStatus = PENDING
    },
    [getSocialLinks.fulfilled]: (state, action) => {
      state.getSocialLinksStatus = SUCCEEDED
      state.searchSocialLinks = action.payload
    },
    [getSocialLinks.rejected]: (state) => {
      state.getSocialLinksStatus = REJECTED
    },
    // GET SOCIAL Links Categories
    [getSocialLinksCategories.pending]: (state) => {
      state.getSocialLinksCategoriesStatus = PENDING
    },
    [getSocialLinksCategories.fulfilled]: (state, action) => {
      state.getSocialLinksCategoriesStatus = SUCCEEDED
      state.searchSocialLinksCategories = action.payload
    },
    [getSocialLinksCategories.rejected]: (state) => {
      state.getSocialLinksCategoriesStatus = REJECTED
    },
    // GET FREQUENCY
    [getFrequency.pending]: (state) => {
      state.getFrequencyStatus = PENDING
    },
    [getFrequency.fulfilled]: (state, action) => {
      state.getFrequencyStatus = SUCCEEDED
      state.frequency = action.payload
    },
    [getFrequency.rejected]: (state) => {
      state.getFrequencyStatus = REJECTED
    },
    // SET LIVE LOCATION DATA
    [setLiveLocData.pending]: (state) => {
      state.setLiveLocDataStatus = PENDING
    },
    [setLiveLocData.fulfilled]: (state, action) => {
      state.setLiveLocDataStatus = SUCCEEDED
      state.liveLocationCoordinates = action.payload
    },
    [setLiveLocData.rejected]: (state) => {
      state.setLiveLocDataStatus = REJECTED
    },
    // GET PING TEST
    [getPingTest.pending]: (state) => {
      state.getPingTestStatus = PENDING
    },
    [getPingTest.fulfilled]: (state) => {
      state.getPingTestStatus = SUCCEEDED
    },
    [getPingTest.rejected]: (state) => {
      state.getPingTestStatus = REJECTED
    },
    // GET IMAGE TEST
    [getFileTest.pending]: (state) => {
      state.getFileTestStatus = PENDING
    },
    [getFileTest.fulfilled]: (state, action) => {
      state.getFileTestStatus = SUCCEEDED
      state.testImage = action.payload
    },
    [getFileTest.rejected]: (state) => {
      state.getFileTestStatus = REJECTED
    },
    // SET DATA TEST
    [setDataTest.pending]: (state) => {
      state.setDataTestStatus = PENDING
    },
    [setDataTest.fulfilled]: (state, action) => {
      state.setDataTestStatus = SUCCEEDED
      state.dataQuote = action.payload
    },
    [setDataTest.rejected]: (state) => {
      state.setDataTestStatus = REJECTED
    },
    // ARCHIVE REQUEST
    [archiveRequest.pending]: (state) => {
      state.archiveRequestStatus = PENDING
    },
    [archiveRequest.fulfilled]: (state) => {
      state.archiveRequestStatus = SUCCEEDED
    },
    [archiveRequest.rejected]: (state) => {
      state.archiveRequestStatus = REJECTED
    },
    // BLOCKED REQUESTS
    [getBlockedRequest.pending]: (state) => {
      state.getBlockedRequestStatus = PENDING
    },
    [getBlockedRequest.fulfilled]: (state, action) => {
      state.getBlockedRequestStatus = SUCCEEDED
      state.blockedRequest = action.payload
    },
    [getBlockedRequest.rejected]: (state) => {
      state.getBlockedRequestStatus = REJECTED
    }
  }
})

export const {
  clearUser,
  setFilters,
  deleteFilter,
  setUserPosition,
  handleOpenModal,
  setProfileItemId,
  deleteProfileItem,
  setUserSectionPage,
  isCloseModal,
  handleCloseModal,
  setSocMedDisplay,
  setSocMedDisplayStatus,
  setIsProfileChanged,
  setIsPictureUploaded,
  setCroppedPicture,
  setCropFor,
  setCloseResetProfileModal,
  setIsProfileActive,
  setIsProfileIncomplete,
  setIsChangesSaved,
  setIsSettingPage,
  setIsLocationOpen,
  setUserEdited,
  setIsProfileDeleted,
  clearNotifs,
  setIsNotifViewing,
  setAdminNotifRequest,
  setIsProfileSwitched,
  setPingStatus,
  clearTestData,
  setResTime1,
  setResTime2,
  setResTime3,
  setResTime4,
  setSelectedUserProfile,
  setRandomPos,
  setAskForLocationServices
} = users.actions

export default users.reducer
